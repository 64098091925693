import { nextTick } from 'vue'

let turnstilePromise = null
let isVisible = null
let isTurnstileEnabled = false
let turnstile = null

export function initTurnstileService (visibilityRef, turnstileEnabledValue) {
  isVisible = visibilityRef
  isTurnstileEnabled = turnstileEnabledValue
  return initTurnstile()
}

function initTurnstile () {
  if (!turnstilePromise) {
    turnstilePromise = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'
      script.async = true
      script.defer = true
      script.onload = () => resolve(window.turnstile)
      script.onerror = reject
      document.head.appendChild(script)
    })
  }
  return turnstilePromise
}

/**
 * Handles the Cloudflare Turnstile challenge verification process
 *
 * Original idea came from the following blog:
 * https://blog.cloudflare.com/integrating-turnstile-with-the-cloudflare-waf-to-challenge-fetch-requests/
 *
 * @returns {Promise<void>} Resolves when the challenge is passed, rejects if there's an error
 * or if pre-clearance cannot be obtained.
 *
 * The function:
 * 1. Waits for the Turnstile library to load (via turnstilePromise)
 * 2. Looks for a container element with ID 'turnstile_widget'
 * 3. Renders the Turnstile widget in that container
 * 4. Handles success/failure of the challenge verification
 */
export async function handleTurnstileChallenge () {
  if (!isTurnstileEnabled) {
    return
  }
  if (!isVisible) {
    throw new Error('Turnstile service not properly initialized')
  }

  // We need to manually call nextTick to ensure that the turnstile_widget
  // is rendered before proceeding. v-if does not render the component until
  // it is set to true.
  isVisible.value = true
  await nextTick()

  const turnstileContainer = document.getElementById('turnstile_widget')
  if (!turnstileContainer) {
    throw new Error('Turnstile container not found')
  }

  try {
    turnstile = await turnstilePromise
  }
  catch (error) {
    isVisible.value = false
    throw error
  }

  return new Promise((resolve, reject) => {
    turnstile.render('#turnstile_widget', {
      'sitekey': '0x4AAAAAAA1a3h53iS1IVZ1J',
      'error-callback': function (error) {
        isVisible.value = false
        reject(error)
      },
      'callback': function (token, preClearanceObtained) {
        isVisible.value = false
        if (preClearanceObtained) {
          resolve()
        }
        else {
          reject(new Error('Unable to obtain pre-clearance'))
        }
      },
    })
  })
}
