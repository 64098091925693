/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'road-lines': {
    width: 17.681,
    height: 24.681,
    viewBox: '0 0 17.681 24.681',
    data: '<g _fill="none" _stroke="#666" stroke-linejoin="round" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Line 5324" stroke-linecap="round" d="M.841 23.841l3-23"/><path pid="1" data-name="Line 5325" stroke-linecap="round" d="M13.841.841l3 23"/><path pid="2" data-name="Line 5326" d="M8.841 3.341v5"/><path pid="3" data-name="Line 5327" d="M8.841 10.341v5"/><path pid="4" data-name="Line 5328" d="M8.841 18.341v5"/></g>'
  }
})
