/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'headphones': {
    width: 16,
    height: 16,
    viewBox: '1794 1220 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1797.5 1236.5s-3-.9-3-2v-3c0-2 3-2 3-2zM1814.5 1236.5s3-.9 3-2v-3c0-2-3-2-3-2zM1815.5 1236.5c0 4-3.483 5.965-7 6M1807.5 1243.5h-3a1 1 0 01-1-1h0a1 1 0 011-1h3a1 1 0 011 1h0a1 1 0 01-1 1zM1814.5 1229.5v-.5a8.5 8.5 0 00-8.5-8.5h0a8.5 8.5 0 00-8.5 8.5v.5"/></g>'
  }
})
