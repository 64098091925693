/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-return-full': {
    width: 16,
    height: 16,
    viewBox: '1294 1023.793 24 16.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M1301.5 1024.5l-3 3M1301.5 1030.5l-3-3M1298.5 1027.5h13a6 6 0 016 6h0a6 6 0 01-6 6h-17"/></g>'
  }
})
