/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reports': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1476_4962)" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M10.912.667H2.018c-.378 0-.684.279-.684.623v10.594c0 .345.306.623.684.623h8.894c.378 0 .685-.278.685-.623V1.29c0-.344-.307-.623-.685-.623z"/><path pid="1" d="M12.965 3.16h.684a.72.72 0 01.484.182c.128.117.2.275.2.44v10.595a.596.596 0 01-.2.44.72.72 0 01-.484.183H4.755a.72.72 0 01-.484-.183.596.596 0 01-.2-.44v-.624M4.07 9.097l4.79-.034M4.07 6.605l4.79-.035M4.07 4.111l4.79-.034"/></g><defs><clipPath id="clip0_1476_4962"><path pid="2" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
