/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M2.042 9.96c-.28.29-.28.761.01 1.06l6.817 6.768c.3.28.77.28 1.07 0 .29-.3.29-.77 0-1.06l-5.477-5.425h15.367a.75.75 0 100-1.5H4.342l5.581-5.487c.29-.3.29-.77 0-1.06a.766.766 0 00-1.07 0L2.043 9.962z" _fill="#707070"/>'
  }
})
