<!-- This is a PayHub-specific wrapper for the PayHub-agnostic
TypeformSurveyModal in @grantstreet/psc-vue. This injects some helper data and is aware of
PayHub environments and configs. -->

<template>
  <TypeformSurveyModal
    :typeform-url="surveyUrl"
    :query-data="queryData"
    @hide="$emit('hide')"
  />
</template>

<script>
import TypeformSurveyModal from '@grantstreet/psc-vue/components/TypeformSurveyModal.vue'
import { isProd } from '@grantstreet/psc-environment'
import { mapConfigState } from '@grantstreet/psc-config'

export default {
  emits: ['hide'],
  components: {
    TypeformSurveyModal,
  },

  props: {
    confirmationNumber: {
      type: String,
      required: false,
      default: '',
    },
    scheduleId: {
      type: [String, Number],
      required: false,
      default: '',
    },
    surveyUrls: {
      type: Object,
      required: true,
    },
  },

  computed: {
    surveyUrl () {
      return this.surveyUrls[isProd() ? 'prod' : 'nonProd'][this.$i18n.locale]
    },

    queryData () {
      return {
        /* eslint-disable camelcase */
        flags: JSON.stringify(this.flags),
        confirmation_number: this.confirmationNumber,
        schedule_id: this.scheduleId,
        client: this.config.client,
        site: this.config.site,
      }
    },

    ...mapConfigState([
      'config',
      'flags',
    ]),
  },
}
</script>
