/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fullscreen-exit': {
    width: 16,
    height: 16,
    viewBox: '1594 1120 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1608.5 1122.5h7v7M1603.5 1141.5h-7v-7M1603.5 1120.5v9h-9M1603.5 1129.5l-8-8"/><g><path pid="1" d="M1608.5 1143.5v-9h9M1616.5 1142.5l-8-8"/></g></g>'
  }
})
