/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'identification': {
    width: 16,
    height: 16,
    viewBox: '1744 1273 24 18',
    data: '<g transform="translate(1744.5 1273.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M16 0H8M19 0h3a1 1 0 011 1v15a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h4"/><circle pid="1" cx="2" cy="2" r="2" transform="translate(5 5)"/><path pid="2" d="M11 14H3l.772-2.316A1 1 0 014.721 11h4.558a1 1 0 01.949.684zM15 5h5M15 9h5M15 13h5M8 0v.5a1.5 1.5 0 11-3 0V0M19 0v.5a1.5 1.5 0 01-3 0V0"/></g>'
  }
})
