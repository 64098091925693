<template>
  <div>
    <NavSteps
      current-step="cart"
    />
    <div class="gh-container">
      <h1
        v-if="!hideTitle"
        data-test="page-title"
        class="gh-page-margin gh-title-margin"
      >{{ $t('checkout.title') }}</h1>
      <Checkout
        :is-redirect="isRedirect"
        :hide-cancel-url-button="hideCancelUrlButton"
        :show-cancel-emit-button="showCancelEmitButton"
        :tender-validation-error="tenderValidationError"
        :tender-type-for-validation-error="tenderTypeForValidationError"
        @cancel-cart="$emit('cancel-cart')"
        @payment-complete="$emit('payment-complete')"
      />
    </div>
  </div>
</template>

<script>
import Checkout from '../../components/Checkout.vue'
import NavSteps from '../../components/NavSteps.vue'

export default {
  emits: ['cancel-cart', 'payment-complete'],
  components: {
    Checkout,
    NavSteps,
  },

  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideCancelUrlButton: {
      type: Boolean,
      default: false,
    },
    showCancelEmitButton: {
      type: Boolean,
      default: false,
    },
    isRedirect: {
      type: Boolean,
      default: false,
    },
    tenderValidationError: {
      type: String,
      default: '',
    },
    tenderTypeForValidationError: {
      type: String,
      default: '',
    },
  },
}
</script>
