import store, { InstallPublicParams } from './store.ts'

// --- Installer

export const installEBillingPublic = (params: InstallPublicParams) =>
  store.commit('initialize', params)

// --- Dynamic Component Loader

export { default as EBillingLoader } from './EBillingLoader.vue'

// --- Methods

export const hasEBillingSubscription = (accountPayableSavePath, returnSubscription) =>
  store.getters.hasEBillingSubscription(accountPayableSavePath, returnSubscription)

export const EBillingPayables = () => store.state.subscriptions
export const HasEbillingLoaded = () => store.state.loaded
export const LoadEbillingData = () => store.dispatch('loadData')

export const subscribePayable = (payload) => store.dispatch('subscribePayable', payload)

export const modifySubscription = (payload) => store.dispatch('modifySubscription', payload)

export const unsubscribePayable = (payload) => store.dispatch('unsubscribePayable', payload)
