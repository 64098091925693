/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'power': {
    width: 16,
    height: 16,
    viewBox: '1945.488 1070.501 21.024 22.998',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1956 1071.001v7M1959.5 1073.631a10 10 0 11-7 0"/></g>'
  }
})
