/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wrench': {
    width: 16,
    height: 16,
    viewBox: '1594.284 1220.194 23.52 23.737',
    data: '<path pid="0" d="M1613.8 1221.367a5.992 5.992 0 00-8.37 6.96l-2.59 2.59-7.179 7.18a3.002 3.002 0 104.25 4.24l5.05-5.05 4.24-4.25.47-.47a5.992 5.992 0 006.96-8.37l-3.292 3.292a1 1 0 01-1.416 0l-1.408-1.415a1 1 0 010-1.412z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
