/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'device-tablet': {
    width: 16,
    height: 16,
    viewBox: '1648 1320 16 24',
    data: '<g transform="translate(1648.5 1320.5)"><rect pid="0" width="15" height="23" rx="1" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" d="M0 18h15M0 3h15"/><circle pid="2" cx="1" cy="1" r="1" transform="translate(6.5 19.5)" _fill="#666"/></g>'
  }
})
