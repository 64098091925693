<template>
  <div
    class="mt-1 form-inline"
  >
    {{ $t("quantity.shorthand") }}
    <template v-if="readOnly">
      {{ cartItem.quantity || 1 }}
    </template>
    <select
      v-else-if="cartItem.quantity < 10"
      :class="{'quantity-small': small, 'quantity-large': !small}"
      :value="cartItem.quantity"
      :aria-label="$t('quantity.longhand')"
      class="quantity form-control form-control-inline mx-2"
      @change="quantityChangeDropdown"
    >

      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10+</option>
    </select>

    <div v-else>
      <ErrorPopper :error="error">
        <div>
          <b-input-group
            :class="{'quantity-small': small, 'quantity-large': !small}"
            class="mx-2 py-0 my-0"
          >
            <b-form-input
              v-model="newQuantity"
              :aria-label="$t('quantity.longhand')"
              :readonly="$wait.waiting(`modifying.${cartItem.id}`)"
              type="number"
              min="0"
              step="1"
              class="quantity"
              @keyup.enter="confirmChange"
            />
          </b-input-group>
          <div
            v-if="showUpdate"
            :class="{'quantity-small': small, 'quantity-large': !small}"
            class="mx-2 position-absolute text-center"
          >
            <b-link @click="confirmChange">
              <small>{{ $t("quantity.update") }}</small>
            </b-link>
          </div>
        </div>
      </ErrorPopper>
    </div>

  </div>
</template>

<script>
import ErrorPopper from '@grantstreet/psc-vue/components/ErrorPopper.vue'
import { useVuelidate } from '@vuelidate/core'

export default {
  emits: ['payableRemoved', 'quantityUpdate', 'update-error'],

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  components: {
    ErrorPopper,
  },

  props: {
    cartItem: {
      type: Object,
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      newQuantity: this.cartItem.quantity,
      error: '',
      backupQuantity: this.cartItem.quantity,
    }
  },

  computed: {
    showUpdate () {
      return this.newQuantity !== this.cartItem.quantity
    },
  },

  methods: {
    quantityChangeDropdown (e) {
      this.newQuantity = e.target.value
      this.dispatchChange()
    },

    async confirmChange () {
      if (this.newQuantity === '0') {
        this.$wait.start(`modifying.${this.cartItem.id}`)
        try {
          await this.$store.dispatch('Cart/removeFromCart', this.cartItem)
          this.$emit('payableRemoved')
        }
        catch (error) {
          this.error = error
        }
        this.$wait.end(`modifying.${this.cartItem.id}`)
      }
      else {
        this.dispatchChange()
      }
    },

    async dispatchChange () {
      this.$wait.start(`modifying.${this.cartItem.id}`)
      try {
        await this.$store.dispatch('Cart/modifyItemQuantity', {
          item: this.cartItem,
          quantity: this.newQuantity,
        })
        this.$emit('quantityUpdate')
        this.$emit('update-error', false)
        this.backupQuantity = this.newQuantity
      }
      catch (error) {
        this.$emit('update-error', true)
        this.newQuantity = this.backupQuantity
      }
      this.$wait.end(`modifying.${this.cartItem.id}`)
    },

    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },
  },
}
</script>

<style lang="scss" scoped>

.quantity {
  padding: .3rem .5rem;
  line-height: 1;
}

.quantity-large{
  width: 5rem !important;
}

.quantity-small {
  width: 4rem !important;
}

</style>
