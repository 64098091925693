/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'device-desktop': {
    width: 16,
    height: 16,
    viewBox: '1544 1321 24 22',
    data: '<g transform="translate(1544.5 1321.5)" _fill="none" _stroke="#666" stroke-linecap="round"><rect pid="0" width="23" height="16" rx="1" stroke-linejoin="round"/><path pid="1" d="M15 21H8l1-5h5zM5 21h13" stroke-miterlimit="10"/></g>'
  }
})
