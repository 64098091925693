/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'strike-through': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M14.31 3.691a7.509 7.509 0 11-10.619 10.62 7.509 7.509 0 0110.62-10.62z" _stroke="#666" stroke-width="1.5" stroke-linejoin="round"/><path pid="1" d="M3.92 14.408L14.082 4.246" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
