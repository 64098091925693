/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-circle-right': {
    width: 16,
    height: 16,
    viewBox: '1294.5 1070.5 23 23',
    data: '<g transform="translate(1295 1071)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="11" cy="11" r="11"/><path pid="1" d="M4 11h14M12 5l6 6M12 17l6-6"/></g>'
  }
})
