/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'history': {
    width: 16,
    height: 16,
    viewBox: '1394 1020 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M1396.5 1020.5v5M1401.5 1025.5h-5M1406 1020.5a11.5 11.5 0 11-11.5 11.5M1396.52 1025.5a11.467 11.467 0 019.48-5M1406.5 1025.5v7M1412.5 1032.5h-6"/></g>'
  }
})
