import store, { InstallParams } from './store/index.ts'
import { listenForJotformMessage, attach } from './utils.ts'

export const installFormsPublic = (params: InstallParams) => {
  store.commit('initialize', params)
  listenForJotformMessage()
}

// --- Dynamic Component Loader

export { default as FormsPublicLegacyLoader } from './FormsPublicLegacyLoader.vue'

// --- Methods

export { attach }

// --- Models

export { default as Submission } from './models/Submission.ts'
