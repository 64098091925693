/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pin': {
    width: 16,
    height: 16,
    viewBox: '1600 1170 12 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1606 1193.5l1.5-9h-3zM1608.5 1180.641v-6.141h1a2 2 0 000-4h-7a2 2 0 000 4h1v6.141a3.991 3.991 0 00-3 3.859h11a3.991 3.991 0 00-3-3.859z"/></g>'
  }
})
