/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'organization': {
    width: 16,
    height: 16,
    viewBox: '1294.082 1172.056 23.836 19.889',
    data: '<g transform="translate(1294.584 1172.555)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M17.902 4.778a3 3 0 11-6 0V3a3 3 0 116 0z"/><circle pid="1" cx="2.5" cy="2.5" r="2.5" transform="translate(4.402 .389)"/><path pid="2" d="M9.419 11.03a6 6 0 00-9.406 3.709.99.99 0 00.99 1.15h4.9M14.902 10.889h0a8 8 0 00-7.92 6.864 1.006 1.006 0 001 1.136h13.841a1.006 1.006 0 001-1.137 8 8 0 00-7.921-6.863z"/></g>'
  }
})
