/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_96_523)" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M12 23.234c6.204 0 11.234-5.03 11.234-11.234S18.204.766 12 .766.766 5.796.766 12 5.796 23.234 12 23.234zM12 4.851V12M17.106 17.106L12 12"/></g><defs><clipPath id="clip0_96_523"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
