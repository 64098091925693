<!--
This is a wrapper component that displays its error prop as a red tooltip over
whatever element is passed to the slot.
-->

<template>
  <Dropdown
    ref="popper"
    class="d-inline"
    :placement="placement"
    :disabled="!computedError"
    popper-class="popper-danger"
  >
    <slot />
    <template #popper>
      <div class="popper">{{ computedError }}</div>
    </template>
  </Dropdown>
</template>

<script>
import { Dropdown } from 'floating-vue'
import errorMixin from '../utils/errorMixin.js'

export default {
  components: {
    Dropdown,
  },

  mixins: [ errorMixin ],

  props: {
    error: {
      type: [String, Error],
      required: true,
    },
    placement: {
      type: String,
      default: 'top',
    },
  },

  computed: {
    computedError () {
      if (this.error instanceof Error) {
        return this.userFriendlyError(this.error)
      }
      return this.error
    },
  },

  watch: {
    error (error) {
      if (error) {
        this.$nextTick(this.$refs.popper.show)
      }
    },
  },
}
</script>
