/**
 * Begins watching every 5 minutes for JWT expiration within the next 10. When
 * expiration is imminent, calls supplied refresh function.
 *
 * Returns an async "checkJwtExpiration" function, which can be used to check
 * and refresh the token immediately, without waiting 5 minutes.
 *
 * @function watchForExpiration
 * @param {Object} params
 * @param {Function} params.refreshJwt - User supplied function to refresh the
 * jwt. Must accept a callback.
 * @param {Function} params.updateJwt - Callback to pass to refreshJwt. Should
 * perform necessary updates.
 * @param {Function} params.getExpiry - Returns current jwt expiration.
 * @param {Function} params.notifyOfExpiration - Updates the consumer that the
 * jwt has expired/refreshing has failed.
 * @param {Function} [params.sentryException] - User supplied function to report
 * errors to sentry.
 * @return {Function} Checks the current jwt, via getExpiry, for impending token
 * expiration. If expiration is eminent, refreshes via refreshJwt(updateJwt).
 * Returns a promise that will wait for the attempt to refresh, and then resolve
 * true if the token is valid and false if it is expired.
 */
export const watchForExpiration = ({
  refreshJwt,
  updateJwt,
  getExpiry,
  notifyOfExpiration,
  sentryException,
}) => {
  // Keep track of how often we refresh the token in a row.
  // Any more than 2 is sign for alarm.
  let failedConsecutiveAttempts = 0

  const checkJwtExpiration = () => {
    const now = Date.now()
    const expiry = getExpiry() * 1000

    // Subtract 10 minutes for the refresh attempt window
    const refreshIfAfter = expiry - (10 * 60 * 1000)

    if (now <= refreshIfAfter) {
      // Probably not a bad idea to reset the failure count if it's not yet time
      // to refresh... Just in case?
      failedConsecutiveAttempts = 0
      return expiry >= now
    }

    // The token will expire in the next 10 minutes, so refresh
    failedConsecutiveAttempts++
    if (!refreshJwt || failedConsecutiveAttempts >= 3) {
      if (failedConsecutiveAttempts >= 3) {
        // We've failed at least 3 times, stop trying
        window.clearInterval(intervalId)
        sentryException?.(new Error('Attempting to refresh JWT 3+ times in a row'))
      }

      notifyOfExpiration()
      return expiry >= now
    }

    // Refresh
    // The promise is provided so the caller can "await" an initial
    // check. We assume that the refreshJwt callback will often need
    // to make an HTTP API request, so we also include a 10s timeout.
    return Promise.any([
      new Promise(resolve => setTimeout(() => resolve(false), 10 * 1000)),
      new Promise(resolve => {
        refreshJwt(jwt => {
          updateJwt(jwt)
          // If we've refreshed successfully reset the count
          failedConsecutiveAttempts = 0
          resolve(true)
        })
      }),
    ])
  }

  // Check for expiration every five minutes
  const intervalId = window.setInterval(checkJwtExpiration, 1000 * 60 * 5)

  return checkJwtExpiration
}
