import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import Tender, { tenderFromDBFormat } from './tender.js'
import Session from '@grantstreet/psc-vue/utils/session.js'
import { sentryException } from '../../sentry.js'
import Client from '../api-client.js'
import { eWalletServiceEnv } from '../utils.js'
import { logActionFactory } from '@grantstreet/psc-vue/utils/logging.js'
import { redactProps } from '@grantstreet/psc-js/utils/objects.js'
import * as gtag from 'vue-gtag'
let logDiagnosticsAction

export const state = {
  bus: null,
  supportsLogin: false,
  userId: '',
  userProfile: null,
  allowedMethodsOverride: null,
  tenders: [],
  jwt: {},
  jwtFailure: false,
  jwtRaw: '',
  loadPromise: undefined,
  initializeModule: undefined,
  environment: 'unknown',
  topLevelDomain: '',
  networkError: '',
  // Remove after A/B test
  useAltDebitFilterAdvisory: false,
  unconfirmedPayables: 0,
  storeApplePayTokenInDv: null,
  storeGooglePayTokenInDv: null,

  // Google analytics for the international fee modal
  openedInternationalFeeModalFlag: false,
}

export const getters = {
  $api: (state, getters, rootState, rootGetters) => rootGetters['API/ewallet'],

  loggedIn: state => state.userId ? !state.userId.match(/^anon\|/) : false,

  jwt: state => state.jwt,

  lastUsedTender: state => state.tenders.find(t => t.isLastUsed),

  findTenderByToken: state => token => state.tenders.find(t => t.warehouseToken === token),

  findTenderById: state => id => state.tenders.find(t => t.tenderId === id || t.ewalletToken === id),

  savedTenders: state => state.tenders || [],

  hasSavedTenders: (state, getters) => getters.savedTenders.length > 0,

  environment: state => state.environment,

  topLevelDomain: state => state.topLevelDomain,

  isAllowedType: () => ({ allowedMethods, type }) => {
    return allowedMethods.includes(type)
  },

  oneTenderMethod: () => allowed => allowed.length === 1,

  onlyOnePaymentOption: (state, getters) => methods => getters.oneTenderMethod(methods) && !getters.savedTenders.length,

  loadPromise: state => {
    state.loadPromise ||= state.initializeModule()

    return state.loadPromise
  },

  networkError: state => state.networkError,
  userId: state => state.userId,
  userProfile: state => state.userProfile,
  sessionId: () => Session.id,
}

export const mutations = {
  setEventBus (state, eventBus) {
    state.bus = eventBus
  },

  // Private; don't use this externally
  setInitializationFunction (state, initializeModule) {
    state.initializeModule = initializeModule
  },

  // Private
  setLoadPromise (state, loadPromise) {
    state.loadPromise = loadPromise
  },

  setNetworkError (state, err) {
    Vue.set(state, 'networkError', err)
  },

  setProfile (state, profile) {
    Vue.set(state, 'userProfile', profile)
  },

  // Accepts the decoded JWT. Sets state.environment to the E-Wallet
  // microservice environment (as deduced from jwt.origin).
  setJwt (state, decodedJwt) {
    state.jwt = decodedJwt
    if (decodedJwt.origin) {
      state.environment = eWalletServiceEnv(decodedJwt.origin)
    }
  },

  setJwtFailure (state, failed) {
    state.jwtFailure = failed
  },

  setUserId (state, userId) {
    state.userId = userId

    gtag.set({ userId })
  },

  setSupportsLogin (state, supportsLogin) {
    state.supportsLogin = supportsLogin
  },

  setTenders (state, tenders) {
    state.tenders = tenders || []
  },

  setTopLevelDomain (state, domain) {
    state.topLevelDomain = domain || ''
  },

  // This only adds the tender to the local state (does not call the API)
  addTenderLocal (state, tender) {
    if (!state.tenders) {
      state.tenders = []
    }
    state.tenders.push(tender)
  },

  updateTenderLocal (state, newTender) {
    if (!state.tenders) {
      state.tenders = []
    }

    for (const tender of state.tenders) {
      if (tender.tenderId === newTender.tenderId) {
        for (const key of Object.keys(tender)) {
          // TODO: Is this Vue.set necessary?
          Vue.set(tender, key, newTender[key])
        }
        return
      }
    }

    // XXX: What to do here?
    console.warn('Couldn\'t find tender to update', newTender)
  },

  removeTenderLocal (state, tender) {
    const index = state.tenders.indexOf(tender)
    if (index !== -1) {
      state.tenders.splice(index, 1)
    }
  },

  setLastUsedTenderLocal (state, newTender) {
    if (!newTender || !newTender?.tenderId) {
      return
    }
    // Both sets the new flag and unsets the old one
    state.tenders.forEach(tender => {
      tender.isLastUsed = tender.tenderId === newTender.tenderId
    })
  },

  setDebitFilterAdvisoryFlag (state, flag) {
    state.useAltDebitFilterAdvisory = flag
  },

  injectApplePayTokenHandler (state, storeApplePayTokenInDv) {
    state.storeApplePayTokenInDv = storeApplePayTokenInDv
  },

  injectGooglePayTokenHandler (state, storeGooglePayTokenInDv) {
    state.storeGooglePayTokenInDv = storeGooglePayTokenInDv
  },
}

export const actions = {
  async setApi ({ commit }, api) {
    commit('API/setEWalletApi', api, { root: true })
  },

  async probeApi ({ getters }, handler) {
    await getters.$api.authProbe(handler)
  },

  // Manually initialize or force reinitialization with the defined
  // initialization function
  initialize ({ dispatch }) {
    return dispatch('reinitialize')
  },

  // Manually reinitialize and reset loadPromise and loaded flag. Optionally
  // pass a single use loadPromise without changing the initialization function
  reinitialize ({ state, commit }, { loadPromise = state.initializeModule() } = {}) {
    commit('setLoadPromise', loadPromise)
    return loadPromise
  },

  async updateJwt ({ state, commit, dispatch }, jwt) {
    const lastJwt = state.jwtRaw
    if (lastJwt && lastJwt === jwt) {
      sentryException(new Error('Attempting to refresh JWT with itself'))
    }
    let decodedJwt
    try {
      decodedJwt = jwtDecode(jwt)
    }
    catch (error) {
      sentryException('Error decoding jwt')
    }

    commit('setUserId', decodedJwt.user_id)
    commit('setJwt', decodedJwt)
    await dispatch('setApi', new Client({ jwt, exceptionLogger: sentryException }))
    await dispatch('probeApi', () => {
      state.bus?.$emit('ewallet.authProbeFailed')
      commit('setNetworkError', 'error.networkError.desc')
    })
  },

  async updateTender ({ dispatch }, { input, needsBillingAddress, isManagementView }) {
    dispatch('wait/start', 'updating tender', { root: true })
    return dispatch('saveTender', { input, needsBillingAddress, isManagementView })
      .finally(() => {
        dispatch('wait/end', 'updating tender', { root: true })
      })
  },

  async saveNewTender ({ dispatch }, { input, needsBillingAddress, isManagementView, useExpiringTenders, forceMultiUseTender }) {
    dispatch('wait/start', 'saving tender', { root: true })
    return dispatch('saveTender', { input, needsBillingAddress, isManagementView, useExpiringTenders, forceMultiUseTender })
      .finally(() => {
        dispatch('wait/end', 'saving tender', { root: true })
      })
  },

  async saveNewUnauthorizedTender ({ dispatch }, { input, needsBillingAddress, useExpiringTenders }) {
    dispatch('wait/start', 'saving unauthorized tender', { root: true })
    return dispatch('saveUnauthorizedTender', { input, needsBillingAddress, useExpiringTenders })
      .finally(() => {
        dispatch('wait/end', 'saving unauthorized tender', { root: true })
      })
  },

  async saveTender ({ getters, state, commit, dispatch }, { input, needsBillingAddress, isManagementView, useExpiringTenders, forceMultiUseTender }) {
    input.userId = state.userId

    // If displayInWallet is undefined,
    // that means we didn't give the user a checkbox
    // so we're probably saving from the e-wallet's management view
    // on the user profile.
    // Note that we are still going to save
    // it, regardless. We'll just attach displayInWallet=false.
    input.displayInWallet = input.displayInWallet !== false

    // The back end requires this to be a nullable string, not a bool
    input.savedPaymentMethodDisclosure = input.savedPaymentMethodDisclosure || null

    input.isExpiring = useExpiringTenders && !input.displayInWallet

    // Field to allow schep admin to force tenders to be reuseable while display_in_wallet
    // is false. We don't want to set this at all for other e-wallet uses
    if (forceMultiUseTender) {
      input.forceMultiUseTender = true
    }

    const inputTender = new Tender(input, needsBillingAddress)

    try {
      const { data } = await getters.$api.savePaymentInfo(inputTender.toDBFormat())

      // This is a better way to remove props than the delete operator.
      // See psc-js/utils/objects.js for more.
      // TODO: We should add some standard props to always redact inside
      // logDiagnostics
      dispatch('logDiagnostics', { savedPaymentInfo: redactProps(data, 'warehouse_token', '***redacted***') })

      // The FE model separates from the BE model here in that it keeps track of the tender source
      // Tender source bypasses the BE, so it won't work with saved tenders
      // This is to handle logic for Apple Pay and potentially other alternative tenders
      const tender = tenderFromDBFormat(data, inputTender.source)
      if (!getters.loggedIn) {
        return tender
      }

      // If the input had an Id already, do an update, otherwise do an insert
      commit(`${input.tenderId ? 'update' : 'add'}TenderLocal`, tender)

      // Mark as "last used" if we'll be showing it to the user and we're
      // not on the management screen
      if (tender.displayInWallet !== false && !isManagementView) {
        try {
          // I wonder if this really needs to be awaited
          await dispatch('setLastUsedTender', tender)
        }
        catch (error) {
          console.error('Error setting last saved tender [ignoring]: ', error.response)
        }
      }
      return tender
    }
    catch (error) {
      sentryException(new Error('Error saving payment info: ' + error))
      throw error
    }
  },

  async saveUnauthorizedTender ({ getters }, { input, needsBillingAddress, useExpiringTenders }) {
    // If displayInWallet is undefined,
    // that means we didn't give the user a checkbox
    // so we're probably saving from the e-wallet's management view
    // on the user profile.
    // Note that we are still going to save
    // it, regardless. We'll just attach displayInWallet=false
    input.displayInWallet = input.displayInWallet !== false

    // The back end requires this to be a nullable string, not a bool
    input.savedPaymentMethodDisclosure = input.savedPaymentMethodDisclosure || null

    input.isExpiring = useExpiringTenders && !input.displayInWallet

    const inputTender = new Tender(input, needsBillingAddress)

    try {
      const { data } = await getters.$api.saveTender(inputTender.toDBFormat())
      const tender = tenderFromDBFormat(data, inputTender.source)
      return tender
    }
    catch (error) {
      console.error('Error saving unauthorized payment info: ', error.response)
      throw error
    }
  },

  async createNewPayPalSetupToken ({ getters }, { clientDisplayName }) {
    const payload = {
      'client_display_name': clientDisplayName,
    }

    try {
      const { data } = await getters.$api.createPayPalSetupToken(payload)
      return data.setup_token
    }
    catch (error) {
      console.error('Error retrieving PayPal Setup Token: ', error.response)
      throw error
    }
  },

  async saveNewPayPalTender ({ getters }, { setupToken }) {
    const payload = {
      'setup_token': setupToken,
    }

    try {
      const { data } = await getters.$api.savePayPalTender(payload)
      const tender = tenderFromDBFormat(data.tender, 'paypal')

      // Since the paypal email isn't a part of the tender DB, we need to manually inject it
      // into the FE model of a tender
      tender.paypalEmail = data.paypal_email || ''
      return tender
    }
    catch (error) {
      console.error('Error saving PayPal tender: ', error.response)
      throw error
    }
  },

  async retrieveTenders ({ state, getters, commit }) {
    try {
      const { data } = await getters.$api.getTenders(state.userId)
      // These tenders will all have a source of "unknown"
      // because e-wallet does not track tender source
      commit('setTenders', data.map(element => tenderFromDBFormat(element)))
    }
    catch (error) {
      // Probably just have to log and ignore in the UI
      console.error('Error retrieving tenders:', error.response)
      throw error
    }
  },

  async removeTender ({ getters, state, commit, dispatch }, tender) {
    // If it's not stored, return
    if (!state.tenders.find(({ tenderId }) => tenderId === tender.tenderId)) {
      return
    }

    try {
      await getters.$api.removeTender(state.userId, tender.tenderId)
      commit('removeTenderLocal', tender)
    }
    catch (error) {
      console.error('Error removing tender:', error)
      throw error
    }
  },

  async validateTender ({ getters, state }, tender) {
    // If it's not stored, return
    // Currently only stored tenders (tenders with a warehouse token)
    // Are supported in the BE.
    if (!state.tenders.find(({ tenderId }) => tenderId === tender.tenderId)) {
      return
    }

    try {
      await getters.$api.validateTender(tender.tenderId)
    }
    catch (error) {
      console.error('Error validating tender:', error)
      throw error
    }
  },

  async setLastUsedTender ({ state, getters, commit }, tender) {
    // If it's not stored, return
    if (!state.tenders.find(({ tenderId }) => tenderId === tender.tenderId)) {
      return
    }

    try {
      await getters.$api.setLastUsedTender(tender.tenderId)
      commit('setLastUsedTenderLocal', tender)
    }
    catch (error) {
      console.error('Error setting tender as last used:', error.response)
      throw error
    }
  },

  async storeApplePayToken ({ state }, token) {
    if (!state.storeApplePayTokenInDv) {
      throw new Error('No Apple Pay token handler set.')
    }
    return await state.storeApplePayTokenInDv(token)
  },

  async storeGooglePayToken ({ state }, token) {
    if (!state.storeGooglePayTokenInDv) {
      throw new Error('No Google Pay token handler set.')
    }
    return await state.storeGooglePayTokenInDv(token)
  },

  // Logs diagnostic information to Kibana.
  // Depends on VueCookies and API/requestService being installed
  logDiagnostics: ({
    getters,
    rootGetters,
  }, data) => {
    if (!logDiagnosticsAction) {
      const api = rootGetters['API/requestService']
      if (!api) {
        console.error('Error: No request service API set')
        return
      }

      logDiagnosticsAction = logActionFactory({
        logData: api.diag.bind(api),
        isDiagnostic: true,
      })
    }

    return logDiagnosticsAction({
      data,
      defaultClient: 'unknown',
      defaultSite: 'unknown',
      // Append the email, since the id is opaque
      appUser: (getters.userProfile && getters.userProfile?.email) ? `${getters.userId} - ${getters.userProfile.email}` : getters.userId,
    })
  },

  // Sets the international fee modal flag for Google Analytics, allowing us to
  // track whether the modal was opened for a given check out
  setInternationalFeeModalHasOpenedFlag ({ state }, flag) {
    state.openedInternationalFeeModalFlag = flag
  },

}

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,

  strict: process.env.NODE_ENV !== 'production',
}
