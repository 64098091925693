/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'log-out': {
    width: 16,
    height: 16,
    viewBox: '0 0 24.207 17.901',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"><path pid="0" data-name="Path 1442" d="M17.4 15.343v.588a1.474 1.474 0 01-1.47 1.47H1.97a1.474 1.474 0 01-1.47-1.47V1.97A1.474 1.474 0 011.97.5h13.961A1.474 1.474 0 0117.4 1.97v.441"/><g data-name="Group 2265"><g data-name="Group 2264"><path pid="1" data-name="Line 237" d="M19.826 5.203L23.5 8.877"/><path pid="2" data-name="Line 238" d="M19.826 12.551L23.5 8.877"/></g><path pid="3" data-name="Line 239" d="M9.538 8.877H23.5"/></g></g>'
  }
})
