/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 16,
    height: 16,
    viewBox: '1394.5 1170.5 23 23',
    data: '<g transform="translate(1395 1171)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="11" cy="11" r="11"/><path pid="1" d="M11 18V9"/><circle pid="2" cx="1" cy="1" r="1" transform="translate(10 4)"/></g>'
  }
})
