/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hunting-fishing-license': {
    width: 16,
    height: 16,
    viewBox: '0 0 21.5 11.5',
    data: '<g transform="translate(.25 .25)"><path pid="0" data-name="Path 1310" d="M.5 5.5C2.065 2 4.848.5 7.457.5c3.478 0 6.087 4 9.565 4v2c-3.478 1-6.087 4-9.565 4S1.37 7.5.5 5.5" stroke-linejoin="round" _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/><path pid="1" data-name="Path 1311" d="M20.5 1.5l-3.478 3v2l3.478 3a19.084 19.084 0 010-8z" stroke-linejoin="round" _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/><ellipse pid="2" data-name="Ellipse 396" cx=".435" cy=".5" rx=".435" ry=".5" transform="translate(2.674 3.9)" _fill="#666"/><path pid="3" data-name="Path 1312" d="M4.848 2.5a5.477 5.477 0 01.87 3 5.477 5.477 0 01-.87 3" _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"/></g>'
  }
})
