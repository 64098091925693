import createPaymentsStore from './store.js'
import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'
import { useGsgUser } from '@grantstreet/user'

export default function install (app, {
  store,
  api,
  sentryException,
}) {
  store.commit('API/setMyPaymentsApi', api)
  if (!store.state.MyPayments) {
    const { user } = useGsgUser(app)
    store.registerModule('MyPayments', createPaymentsStore({ user }))
  }

  loadTranslations(sentryException)
}
