/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fullscreen-outline-exit': {
    width: 16,
    height: 16,
    viewBox: '1994 1020 24 24',
    data: '<g transform="translate(1994.5 1020.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M18 9h-4M14 5v4M14 18v-4M18 14h-4M5 14h4M9 18v-4M9 5v4M5 9h4M14 14l6 6M3 3l6 6M3 20l6-6M14 9l6-6"/><rect pid="1" width="23" height="23" rx="2"/></g>'
  }
})
