export default {
  state: {
    topicOptions: [],
  },

  getters: {
    topicOptions: state => state.topicOptions,
  },

  mutations: {
    // Sets the options for the contact form questions. Each element in the
    // `topicSpecs` array is an object of this form:
    //   {
    //     text: {
    //       en: 'English Topic',
    //       es: 'Spanish Topic',
    //     },
    //     customFields: [
    //        {
    //           customLabel: {
    //              en: 'English Label',
    //              es: 'Spanish Label',
    //           },
    //           customHint: {
    //              en: 'English Hint',
    //              es: 'Spanish Hint',
    //           },
    //           required: false,
    //        },
    //     ],
    //     defaultOnPage: 'search',
    //     unselectable: false
    //   }
    setTopics (state, topicSpecs) {
      const options = topicSpecs.map(spec => ({
        value: spec.unselectable ? '' : spec.text.en, // default to English initially. Unselectable options have no value
        text: spec.text,
        // Protect against saved empty customFields
        customFields: spec.customFields ? spec.customFields.filter(field => field.customLabel.en) : [],
        defaultOnPage: spec.defaultOnPage,
        // Filter after mapping all values to protect against saved empty question topics
        // (which will, as of writing, otherwise show up as empty options)
      })).filter(spec => spec.text.en)
      state.topicOptions = options
    },
  },

  namespaced: true,
}
