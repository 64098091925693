<template>
  <!-- It might seem counterintuitive that this isn't inside of GovHubNavBar.
        That's done so that the menu can be z-indexed under the nav without
        using z-index: -1; which causes its own issues. -->
  <div
    ref="$nav"
    tabindex="-1"
    @keyup.esc="active = false"
  >
    <div
      v-if="active"
      class="overlay fixed-top vw-100 vh-100"
      @click="active = false"
    />

    <div
      class="nav-aware-top nav-aware-max-h menu position-fixed vw-100 bg-white border"
      :class="{active}"
    >
      <nav
        class="nav flex-column d-lg-inline-flex"
        data-test="top-nav-menu"
      >

        <div
          v-for="{id, group} of routeGroups"
          :key="id"
          class="route-group"
        >
          <NavLink
            v-for="route in group"
            :key="route.key"
            :route="route"
            class="nav-link px-6 px-lg-7 py-4 border-bottom align-items-center border-right"
            :should-redirect="shouldAllowRedirect"
            @redirect-click="$emit('redirect-click', $event)"
            @close-menu="closeMenu"
          />
        </div>

        <LanguageLink
          :is-multilingual="isMultilingual"
          class="px-6 py-4 font-weight-light d-inline d-lg-none"
          @set-locale="$emit('set-locale', $event)"
        />
      </nav>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import LanguageLink from '@grantstreet/psc-vue/components/LanguageLink.vue'
import NavLink from './NavLink.vue'
import { v4 as uuid } from 'uuid'

export default {
  emits: ['set-locale', 'redirect-click', 'input'],
  components: {
    LanguageLink,
    NavLink,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    routes: {
      type: Array,
      required: true,
    },
    shouldAllowRedirect: {
      type: Function,
      default: () => true,
    },
    isMultilingual: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    closeMenu () {
      this.active = false
    },
  },

  computed: {
    active: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    routeGroups () {
      const groups = Array.isArray(this.routes[0]) ? this.routes : [this.routes]
      return groups
        .reduce((effectiveGroups, group) => {
          if (group.length) {
            effectiveGroups.push(group)
          }
          return effectiveGroups
        }, [])
        .map(group => ({ id: uuid(), group }))
    },
  },

  watch: {
    active (active) {
      if (active) {
        this.$refs.nav?.focus({ preventScroll: true })
        return disableBodyScroll()
      }

      enableBodyScroll()
    },
    $route () {
      this.active = false
      clearAllBodyScrollLocks()
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  top: 0;
  left: 0;
  z-index: $zindex-fixed - 2;
}

.menu {
  left: 0;
  box-shadow: 0px 10px 10px rgba(black, 0.078);
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.2s, opacity 0.2s, visibility 0.2s;
  z-index: $zindex-fixed - 1;
  overflow-y: auto;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.nav-link {
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    // This will ignore theming
    background-color: rgba($primary, 0.078)
  }
}

.route-group:not(:first-of-type) {
  border-top: 1px solid $input-disabled-border-color;
}
</style>
