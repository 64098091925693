<template>
  <component
    v-bind="$attrs"
    :is="vueComponent"
    ref="delivery"
  />
</template>

<script>
import { sentryException } from './sentry.ts'
import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin.ts'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },

  mixins: [
    createModuleLoaderMixin({
      moduleName: 'DeliveryMethod',
      componentRefName: 'delivery',

      supportedComponents: {
        'DeliveryMethod': () => import('./components/DeliveryMethod.vue'),
        'DeliverySummary': () => import('./components/DeliverySummary.vue'),
        'AddressOnFile': () => import('./components/AddressOnFile.vue'),
        'AddressChange': () => import('./components/AddressChange.vue'),
      },
      exceptionLogger: sentryException,

      proxyMethods: [
        'validate',
        'getSelectedDeliveryOption',
        'setLastSelected',
        'verifySelectedAddress',
        'setSelectedDeliveryTab',
        'getPickUpInfo',
      ],
    }),
  ],
}
</script>
