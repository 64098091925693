<template>
  <b-modal
    ref="signupModal"
    hide-footer
    :title="''"
    size="md"
    class="text-body text-left"
    hide-header
    body-class="signup"
    no-close-on-esc
    static
    @hide="hide"
  >

    <span class="d-flex flex-row justify-content-end align-items-end pt-3 pr-3">
      <a
        id="close-modal-button"
        ref="closeModalButton"
        class="cursor-pointer"
        tabindex="0"
        href="#"
        @click="hide"
      >
        <svgicon
          :fill="false"
          icon="x"
        />
      </a>
    </span>

    <div class="px-5 pt-3 pb-4">
      <b-row>
        <b-col
          v-dompurify-html="signupText"
          cols="12"
          sm="10"
          md="9"
          lg="8"
        />
        <b-col
          v-if="illustration.src"
          class="d-none d-md-flex align-items-center justify-content-end"
          cols="3"
        >
          <b-img
            :src="illustration.src"
            :alt="illustration.alt"
            fluid
          />
        </b-col>
      </b-row>
      <b-row
        no-gutters
        align-h="end"
      >
        <!-- XXX: Add test to check that user is returned to this modal
        (after that ticket) -->
        <b-col
          class="mt-2 order-sm-last"
          cols="12"
          sm="auto"
        >
          <LoginButton
            class="w-100"
            size="md"
            :before-login="beforeLogin"
            @show-login-disabled-modal="showLoginDisabledPrompt"
            @log-in="$emit('log-in', $event)"
          >
            {{ $t('topnav.login') }}
          </LoginButton>
        </b-col>
        <b-col
          class="mt-2 mr-0 mr-sm-2"
          cols="12"
          sm="auto"
        >
          <b-button
            variant="outline-primary"
            class="w-100"
            @click="hide"
          >
            {{ $t('common.cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="loginDisabled"
      >
        <Alert
          class="mt-5"
          variant="danger"
        >
          {{ $t('login_modal.disabled.description_short') }}
        </Alert>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import Alert from './Alert.vue'
import LoginButton from './LoginButton.vue'

export default {
  emits: ['hide', 'log-in'],
  components: {
    Alert,
    LoginButton,
  },

  props: {
    signupText: {
      type: String,
      required: true,
    },
    beforeLogin: {
      type: Function,
      default: undefined,
    },
    illustration: {
      type: Object,
      default: () => ({}),
      validate: (illustration) => typeof illustration === 'object' &&
        illustration.src &&
        illustration.alt,
    },
  },

  data () {
    return {
      loginDisabled: false,
    }
  },

  methods: {
    async show () {
      this.$refs.signupModal.show()
      await this.$nextTick()
      ;(this.$refs.closeModalButton.$el || this.$refs.closeModalButton).focus()
    },

    hide () {
      this.$refs.signupModal.hide()
      // Make sure @hide on *this* component is respected
      this.$emit('hide')
    },

    showLoginDisabledPrompt () {
      this.loginDisabled = true
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .signup.modal-body {
  padding: 0;
}

#close-modal-button {
  &:focus {
    box-shadow: $box-shadow-lg-primary;
    outline: 0;
 }
}
</style>
