<template>
  <div v-if="loaded && cancelUrl">
    <b-button
      class="checkout-button"
      data-test="cancel-cart-button"
      :variant="variant"
      :href="cancelUrl"
    >
      {{
        hostNameCancelUrl
          ? $t('checkout.cancel.to_location', { location: hostNameCancelUrl })
          : $t('checkout.cancel.default')
      }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapConfigState } from '@grantstreet/psc-config'

export default {
  props: {
    variant: {
      type: String,
      default: 'outline-primary',
    },
    fallbackHomepage: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      loaded: false,
    }
  },

  computed: {
    cancelUrl () {
      // Prefer the passed-in cancel url
      // If that isn't provided, use the config cancel url
      const configUrl = this.config.cart.cancelButtonUrl
      const cancelOption = (this.urls && this.urls.cancel) ||
        (typeof configUrl === 'string' && configUrl)
      if (!this.fallbackHomepage || cancelOption) {
        return cancelOption
      }

      // If we're allowing homepage links, then if the above two aren't provided
      // Prefer the passed-in homepage url
      // Otherwise use the config return url
      // Otherwise use the config client url
      return this.returnUrl
    },
    hostNameCancelUrl () {
      let url
      // Just in case it's not a valid URL, throw it in a try/catch
      try {
        url = new URL(this.cancelUrl).hostname
      }
      catch {}
      return url
    },

    ...mapConfigState(['config']),

    ...mapGetters('PayHub', [
      'returnUrl',
    ]),

    ...mapGetters('Cart', [
      'urls',
      'cartLoadPromise',
    ]),
  },

  async mounted () {
    await this.cartLoadPromise
    this.loaded = true
  },
}
</script>
