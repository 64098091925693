/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'court-collections': {
    width: 16,
    height: 16,
    viewBox: '0 0 21.522 21.516',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 1317" d="M20.772 7.708H.75V6.664a.865.865 0 01.609-.871L10.413.831a.744.744 0 01.609 0l9.053 4.962a.865.865 0 01.609.871v1.044z"/><path pid="1" data-name="Line 109" d="M5.103 7.708v10.011"/><path pid="2" data-name="Line 110" d="M2.491 7.708v10.011"/><path pid="3" data-name="Line 111" d="M19.031 7.708v10.011"/><path pid="4" data-name="Line 112" d="M16.419 7.708v10.011"/><path pid="5" data-name="Line 113" d="M12.067 7.708v10.011"/><path pid="6" data-name="Line 114" d="M9.455 7.708v10.011"/><path pid="7" data-name="Path 1318" d="M19.901 20.766H1.621a.822.822 0 01-.871-.871v-1.741h20.022v1.741a.822.822 0 01-.871.871z"/></g>'
  }
})
