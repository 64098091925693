/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tools': {
    width: 24.736,
    height: 23.468,
    viewBox: '0 0 24.736 23.468',
    data: '<g transform="translate(.256 .365)" _fill="none" _stroke="#666" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Path 6822" d="M2.21 21.74L.79 20.33a1 1 0 010-1.42L12.11 7.6l2.82 2.83L3.62 21.74a1 1 0 01-1.41 0z" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" data-name="Path 6823" d="M19.18 6.18l-2.83-2.82.71-.71a1 1 0 011.41 0l1.41 1.41a1 1 0 010 1.42z" stroke-linecap="round" stroke-linejoin="round"/><rect pid="2" data-name="Rectangle 1804" width="3" height="6" rx="1" transform="rotate(45 .624 29.861)" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" data-name="Path 6824" d="M18.47 6.89l.71-.71-3.34-3.34a8 8 0 00-8.63-1.77L5 1.94a13.11 13.11 0 018.48 4.24L12.11 7.6l2.82 2.83.71-.71 2.12 2.12L20.59 9z" stroke-linecap="round" stroke-linejoin="round"/><path pid="4" data-name="Path 6825" d="M13.12 12.23l6.54 6.54a2 2 0 11-2.83 2.83l-6.54-6.54" stroke-miterlimit="10"/><path pid="5" data-name="Line 5320" stroke-miterlimit="10" d="M8.88 10.82L5.52 7.46"/><path pid="6" data-name="Path 6826" d="M1.99 2.51L.57 3.93 2.7 7.46l2.12.71 1.41-1.41-.7-2.13z" stroke-linecap="round" stroke-linejoin="round"/></g>'
  }
})
