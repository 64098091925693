import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

type Setting = {[key: string]: string | Setting}

export type InstallParams = {
  client: string
  site: string
  charitableDonations: Setting
}
class State {
  client: string | undefined
  site: string | undefined
  charitableDonations: Setting[] = []
}

export default new Vuex.Store({
  state: new State(),

  getters: {
    enabled: state => state.charitableDonations.length,
  },

  mutations: {
    initialize (state, {
      client,
      site,
      charitableDonations,
    }) {
      state.client = client
      state.site = site
      state.charitableDonations = charitableDonations
    },
  },
})
