/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit': {
    width: 16,
    height: 16,
    viewBox: '1494 1119.793 24.207 24.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1496.5 1137.5l17-17 4 4-17 17z"/><path pid="1" d="M1494.5 1143.5l6-2-4-4-2 6M1510.5 1123.5l4 4"/></g>'
  }
})
