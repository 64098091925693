/**
 * Client for the My Payments service API
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = process.env?.GSG_PAYHIST_SERVICE || `${baseGovHubUrl}/svc/payment-history`
  }

  // Don't log receipt 404s to Sentry, since this is a common expected case when
  // a user reloads a receipt page they do not have access to.
  isException (error) {
    if (
      /\/receipts\/\w+/.test(error?.config?.url) &&
      error?.response?.status === 404
    ) {
      return false
    }

    return true
  }

  ping () {
    return this.get('/api/v2/ping')
  }

  getPayments (page = 1, perPage = 10) {
    return this.get(`/api/v2/receipts?page=${page}&perPage=${perPage}`)
  }

  getPayment (id) {
    return this.get(`/api/v2/receipts/${id}`)
  }
}
