import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import { Payable } from '@grantstreet/payables'

export default class {
  // XXX: If there's ever a case where we rapidly create and de-reference carts
  // it might be necessary to add a destroy method, that clears timeout
  #expirationInterval

  // Cart items have this format:
  //   {
  //     id,      // UUID from the cart service
  //     payable, // Payable object
  //     quantity: 2,
  //     amount: 1.00,
  //     enrollInAutopay: false,           // Only used when checking out
  //     scheduledPaymentAgreement, null,  // Only used when checking out
  //   }
  constructor ({ id, items = [], secondsToExpiry, locked } = {}) {
    this.id = id
    this.items = items || []
    if (this.items[0] && !(this.items[0] instanceof Payable)) {
      // We re-construct the payables if this is being built from local storage.
      // Note: This is not where we inflate cart items. That should be done by
      // cart helper methods when dispatching inflateAndUpdateCart from the cart
      // store.
      for (const item of this.items) {
        if (item.payable) {
          // TODO: Should we be reconstructing item.updatedPayable too?
          item.payable = new Payable(item.payable)
        }
      }
    }
    /*
     * The expiration timestamp is localized to the Client timezone. This may
     * not match the timezone the user is currently in; which can cause the
     * cart to be immediately expired.
     * If a secondsToExpiry value is provided, we'll update the expiration
     * timestamp to the correct timestamp in the user's timezone.
     */
    if (secondsToExpiry !== undefined) {
      let localizedExpiration = new Date()
      localizedExpiration = localizedExpiration.setSeconds(
        localizedExpiration.getSeconds() + secondsToExpiry,
      )
      this.expiration = localizedExpiration
    }

    this.locked = locked || false
  }

  // Returns the number of minutes until the carts expiration or
  // null if there is no expiration.
  get minutesUntilExpiration () {
    return this.expiration
      ? (new Date(this.expiration).getTime() - Date.now()) / (60 * 1000)
      : null
  }

  // Returns the number of payables in the cart. Duplicate payables
  // (item.quantity > 1) each count individually.
  get count () {
    return this.items.reduce(
      (count, { quantity = 1 }) => count + parseInt(quantity)
      , 0)
  }

  // Returns true if any item in the cart is slated for autopay
  get enrollInAutopay () {
    return this.items.some(item => item.enrollInAutopay)
  }

  get hasItemCanAutopay () {
    return this.items.some(
      item => item.payable.scheduledPaymentsConfig.allowedTypes.recurring?.beforeDue?.includes('amountDue'),
    )
  }

  // Returns an array of all of the unique display types represented in the
  // cart.
  get configDisplayTypes () {
    const types = []
    for (const item of this.items) {
      const type = item.payable.configDisplayType
      if (!types.find(({ name }) => name === type.name)) {
        types.push(type)
      }
    }
    return types
  }

  get hasDelayedItems () {
    // Raw theoretically shouldn't be necessary. Hopefully we're not bypassing
    // instantiating new Payables by assigning uninflated items directly to a
    // cart instance.
    return this.items.some(item => item.updatedPayable?.shouldDelayPayment || item.payable.shouldDelayPayment || item.payable.raw.should_delay_payment)
  }

  get hasImmediateItems () {
    // Raw theoretically shouldn't be necessary. Hopefully we're not bypassing
    // instantiating new Payables by assigning uninflated items directly to a
    // cart instance.
    return this.items.some(item =>
      (item.updatedPayable && !item.updatedPayable.shouldDelayPayment) ||
      (item.payable && !item.payable.shouldDelayPayment) ||
      (item.payable.raw && !item.payable.raw.should_delay_payment),
    )
  }

  // Triggers an interval to monitor the expiration of the cart.
  //
  // When the cart is about to expire (<10 minutes from now), this will emit a
  // "cart.expiresIn" message on the event bus. An updated expiration message
  // will be emitted every 30 seconds.
  //
  // When the cart is expired, this will emit a "cart.expired" message on the
  // event bus and stop the interval.
  // TODO: What happens if this cart is replaced by a new cart instance?
  checkForExpiration () {
    const intervalSeconds = 30 // check expiration every this seconds

    // If this cart won't expire then quit
    if (!this.expiration) {
      // This might have previously had an expiration date
      clearInterval(this.#expirationInterval)
      this.#expirationInterval = null
      this.expired = false
      return
    }

    const minutes = this.minutesUntilExpiration
    if (minutes > 0) {
      // Emit cart warning event every 30 seconds after only 10 minutes remain
      if (minutes <= 10) {
        // minutes is a float. Round this up in the expiration message so that
        // 'Your cart will expire in 0 minutes' does not confuse the user.
        // Since the interval runs every 30 seconds, we can expect there to be
        // at least 30 seconds between this 1 minute warning, and the cart
        // expiring. But also... who waits until 1 minute remains...
        EventBus.$emit('cart.expiresIn', Math.ceil(minutes))
      }

      // Set interval
      if (!this.#expirationInterval) {
        this.#expirationInterval = setInterval(
          () => this.checkForExpiration(),
          intervalSeconds * 1000,
        )
      }
      this.expired = false
      return
    }

    // If the cart is expired, emit an event and quit
    if (this.#expirationInterval) {
      clearInterval(this.#expirationInterval)
      this.#expirationInterval = null
    }
    this.expired = true
    EventBus.$emit('cart.expired')
  }

  // Returns the cart items of the passed configDisplayType.
  itemsOfDisplayType (configDisplayType) {
    return this.items.filter(
      item => item.payable.configDisplayType.name === configDisplayType.name,
    )
  }

  displayTypeSubtotal (configDisplayType) {
    let subtotal = 0
    for (const item of this.itemsOfDisplayType(configDisplayType)) {
      subtotal += parseFloat(item.amount * (item.quantity || 1))
    }
    return subtotal
  }

  get needsDelivery () {
    for (const item of this.items) {
      if (item.payable.requiresShipping) {
        return true
      }
    }
    return false
  }

  total (fee) {
    let total = 0
    for (const type of this.configDisplayTypes) {
      total += this.displayTypeSubtotal(type)
    }
    // May be a string
    total += parseNumber(fee)

    return total
  }

  // The subtotal (less fees) of all items
  get subtotal () {
    let subtotal = 0
    for (const type of this.configDisplayTypes) {
      subtotal += this.displayTypeSubtotal(type)
    }
    return subtotal
  }

  isInCart (payable) {
    const pathSet = new Set()

    // Put all paths in the cart into a set
    for (const item of this.items) {
      pathSet.add(item.payable.path)
      // if item is a composite payable, go one level deeper
      if (item.payable.componentPaths != null) {
        for (const componentPath of item.payable.componentPaths) {
          pathSet.add(componentPath)
        }
      }
    }

    if (pathSet.has(payable.path)) {
      return true
    }

    // If payable is a composite payable, check if any of it's parts are
    // already in the cart
    if (payable.componentPaths != null) {
      for (const componentPath of payable.componentPaths) {
        if (pathSet.has(componentPath)) {
          return true
        }
      }
    }

    for (const item of this.items) {
      if (item.payable.uniqueId != null && item.payable.uniqueId === payable.uniqueId) {
        return true
      }
    }

    return false
  }
}
