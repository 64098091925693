/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'message-text': {
    width: 16,
    height: 16,
    viewBox: '1444 1222.5 24 19',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1450.5 1223h11a6 6 0 016 6v2a6 6 0 01-6 6h-11l-6 4v-12a6 6 0 016-6zM1450.5 1228h12M1450.5 1232h12"/></g>'
  }
})
