/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setting-down': {
    width: 17.5,
    height: 32.311,
    viewBox: '0 0 17.5 32.311',
    data: '<g _fill="none" _stroke="#0077b3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><g transform="translate(-141.75 -1155.75)"><path pid="0" d="M145.335 1161.627l-1.23-2.046 1.476-1.476 2.046 1.23v0a5.556 5.556 0 011.252-.521l.578-2.317h2.086l.578 2.317a5.556 5.556 0 011.252.521v0l2.046-1.23 1.476 1.476-1.23 2.046h0a5.558 5.558 0 01.521 1.252l2.317.578v2.086l-2.317.578a5.558 5.558 0 01-.521 1.252h0l1.23 2.046-1.476 1.476-2.046-1.23v0a5.556 5.556 0 01-1.252.521l-.578 2.317h-2.086l-.578-2.317a5.556 5.556 0 01-1.252-.521v0l-2.046 1.23-1.476-1.476 1.23-2.046h0a5.558 5.558 0 01-.521-1.252l-2.317-.578v-2.086l2.317-.578a5.558 5.558 0 01.521-1.252z"/><circle pid="1" cx="3.13" cy="3.13" r="3.13" transform="translate(147.37 1161.37)"/></g><path pid="2" d="M12.663 27.337L8.75 31.25M4.837 27.337L8.75 31.25M8.75 21.25v10"/></g>'
  }
})
