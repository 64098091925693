/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-forward': {
    width: 16,
    height: 16,
    viewBox: '1644 1023.894 24 16.213',
    data: '<path pid="0" d="M1667.5 1031.907l-7.288 7.381a1 1 0 01-1.712-.7v-3.681s-6-3-14 4c0 0 2-10 14-10v-3.493a1 1 0 011.7-.714z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
