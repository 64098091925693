// See: @grantstreet/cart-vue/src/store/helpers.js>inflateTransaction()
import { getItemsByDisplayType } from '../store/helpers.js'

export default class {
  // Transaction items have this format:
  //   {
  //     id,      // UUID from the cart service
  //     payable, // Payable object
  //     quantity: 2,
  //     amount: 1.00,
  //     enrollInAutopay: false,           // Only used when checking out
  //     scheduledPaymentAgreement, null,  // Only used when checking out
  //   }
  constructor ({ id, items, delayed, fee, paypalMerchantIDs } = {}) {
    this.id = id
    // Items are inflated by ../store/helpers.js>inflateTransaction()
    this.items = items
    this.delayed = delayed
    this.fee = fee
    this.paypalMerchantIDs = paypalMerchantIDs
  }

  // The amount of all items plus the fee of the transaction
  get total () {
    return parseFloat(this.fee) + this.items.reduce((prev, cur) => {
      return prev + parseFloat(cur.amount * (cur.quantity || 1))
    }, 0)
  }

  // See helper
  get itemsByDisplayType () {
    return getItemsByDisplayType(this.items)
  }
}
