/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'messaging-illustration': {
    width: 16,
    height: 16,
    viewBox: '0 0 120 120',
    data: '<g data-name="Layer 2"><circle pid="0" cx="60" cy="60" r="60" _fill="#eaeaea"/><path pid="1" d="M26 33.55l32.18 25.19a3 3 0 003.64 0L94 33.55z" _fill="#0077b3"/><path pid="2" d="M94 39.14v44.35a3 3 0 01-3 3H29a3 3 0 01-3-3V39.14l32.18 25.18a2.94 2.94 0 003.64 0z" _fill="#0077b3"/><path pid="3" d="M69.74 33.55L56.13 57.13l2 1.61a3 3 0 003.64 0L94 33.55zM60.78 64.82L48.3 86.45H91a3 3 0 003-3V39.14L61.82 64.32a2.78 2.78 0 01-1.04.5z" _fill="#09678c"/></g>'
  }
})
