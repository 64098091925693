/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // donations-prod & -nonprod projects
    prod: 'https://c23e705558254f5d9ee791ab89dd4922@o168195.ingest.sentry.io/4504765688053760',
    nonprod: 'https://7a5680bb499f483691548c9f3d7fb2f9@o168195.ingest.sentry.io/4504765722918912',
  },
})
