/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar': {
    width: 16,
    height: 16,
    viewBox: '1694 1170.5 24 23',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1713.5 1173h3a1 1 0 011 1v18a1 1 0 01-1 1h-21a1 1 0 01-1-1v-18a1 1 0 011-1zM1700.5 1171v4M1711.5 1171v4M1694.5 1178h23"/></g>'
  }
})
