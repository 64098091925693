/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-up-level': {
    width: 16,
    height: 16,
    viewBox: '1547 1020 18 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round"><path pid="0" d="M1554.5 1034.5l-7-7 7-7" stroke-linejoin="round"/><path pid="1" d="M1547.5 1027.5h11a6 6 0 016 6v10" stroke-miterlimit="10"/></g>'
  }
})
