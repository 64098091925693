/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'in-person-pickup': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<circle pid="0" cx="16" cy="16" r="15.5" _stroke="#666"/><path pid="1" d="M21.514 14.184c0 3.186-3.71 7.392-5.04 8.8a.65.65 0 01-.474.202.659.659 0 01-.473-.201c-1.337-1.409-5.047-5.602-5.04-8.801a5.37 5.37 0 011.614-3.83A5.564 5.564 0 0116 8.766c1.462 0 2.865.57 3.899 1.586a5.37 5.37 0 011.614 3.83z" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M16 16.822c1.433 0 2.595-1.141 2.595-2.55 0-1.407-1.162-2.548-2.595-2.548-1.433 0-2.595 1.14-2.595 2.549 0 1.408 1.162 2.549 2.595 2.549z" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
