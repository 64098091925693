/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'line-graph-points': {
    width: 16,
    height: 16,
    viewBox: '1343.797 1320 24.203 24.203',
    data: '<g transform="translate(1344.5 1320.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="2" cy="2" r="2" transform="translate(6 11)"/><circle pid="1" cx="2" cy="2" r="2" transform="translate(14 15)"/><path pid="2" d="M22 8l-5.04 7.24M14.21 16.11l-4.42-2.22M6.75 14.56L0 23M0 0v23M23 23H0M18 9l4-1 1 4"/></g>'
  }
})
