/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'message': {
    width: 16,
    height: 16,
    viewBox: '1344 1222.5 24 19',
    data: '<path pid="0" d="M1365.5 1223h-19a2 2 0 00-2 2v10a2 2 0 002 2h16l5 4v-16a2 2 0 00-2-2z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
