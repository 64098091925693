export class TimeoutError extends Error {
  static name = 'TimeoutError'
}

export const withTimeout = (promise, timeout) => Promise.race([
  promise,
  new Promise((resolve, reject) =>
    setTimeout(() => reject(new TimeoutError('Operation Timed Out')), timeout),
  ),
])
export const isTimeout = result => result instanceof TimeoutError

export default withTimeout
