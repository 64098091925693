import withTimeout, { isTimeout } from '@grantstreet/psc-js/utils/timeout.js'
import { sentryException } from './sentry.js'
import { useLogin } from '@grantstreet/login'

let utils

/**
 * Sets up the E-Wallet helpers.
 */
export const initLoginHelpers = gsgLogin => {
  utils = useLogin(gsgLogin)
  return useLoginHelpers()
}

/**
 * Stubs the E-Wallet helpers for easy component setup on sites don't actually
 * use login. Calling helpers will throw.
 */
export const stubLoginHelpers = () => {
  const throwOp = () => {
    throw new Error('Login is not set up for this application.')
  }
  utils = {
    logIn: throwOp,
    logOut: throwOp,
  }
  return useLoginHelpers()
}

/**
 * Returns helpers for initializing and rerendering E-Wallet.
 */
export const useLoginHelpers = () => {
  if (!utils) {
    throw new TypeError('Login helpers must first be set up with initLoginHelpers.')
  }

  return helpers
}

const helpers = {

  async handleLogin ({
    beforeLogin,
    onError,
  }) {
    beforeLogin?.()

    try {
      await withTimeout(
        // logIn will redirect to the new page so this promise will never
        // resolve. The timeout is actually racing the redirect.
        utils.logIn(),
        30 * 1000,
      )
    }
    catch (error) {
      onError?.()

      if (isTimeout(error)) {
        sentryException('Reaching the Login Service timed out.', {
          level: 'warning',
        })
        return
      }

      throw error
    }
  },

  handleLogOut: (redirectUri) => utils.logOut(redirectUri),

}
