/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-delivery': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g _stroke="#666" _fill="none"><g data-name="Ellipse 421"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g><g stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1459" d="M15.809 20.574H9.681A.643.643 0 019 19.893v-4.089a3.371 3.371 0 013.4-3.4h0a3.371 3.371 0 013.4 3.4z" stroke-linecap="round"/><path pid="3" data-name="Path 1460" d="M12.404 20.574h11.574a.643.643 0 00.681-.681v-4.089a3.371 3.371 0 00-3.4-3.4h-8.855" stroke-linecap="round"/><path pid="4" data-name="Line 273" stroke-linecap="round" d="M17.851 15.809V9"/><path pid="5" data-name="Rectangle 805" stroke-linecap="round" d="M17.851 9h3.404v2.043h-3.404z"/><path pid="6" data-name="Line 274" d="M18.532 20.574V25"/><path pid="7" data-name="Line 275" d="M16.489 20.574V25"/></g></g>'
  }
})
