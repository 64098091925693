/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vehicle-registration': {
    width: 16,
    height: 16,
    viewBox: '0 0 25.606 21.497',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 17" d="M24.375 11.863l-3.169-4.13-2.017-5.425A2.154 2.154 0 0017.22.963a45.741 45.741 0 00-8.884 0 2.29 2.29 0 00-1.968 1.345L4.35 7.734l-3.169 4.13a2.073 2.073 0 00-.431 1.248v6.579a1.066 1.066 0 001.056 1.056h1.057a1.066 1.066 0 001.056-1.056v-2.065h17.767v2.065a1.066 1.066 0 001.056 1.056h1.056a1.066 1.066 0 001.056-1.056v-6.579a3.96 3.96 0 00-.479-1.249z"/><path pid="1" data-name="Path 18" d="M18.517 14.553h2.257a.861.861 0 00.864-.864v-1.201"/><path pid="2" data-name="Path 19" d="M7.04 14.553H4.783a.861.861 0 01-.864-.864V12.44"/><path pid="3" data-name="Path 20" d="M3.919 8.262h17.767"/><path pid="4" data-name="Path 21" d="M3.919 8.262H1.806A1.066 1.066 0 01.75 7.205h0a1.066 1.066 0 011.056-1.056h3.122z"/><path pid="5" data-name="Path 22" d="M21.638 8.262h2.065a1.066 1.066 0 001.056-1.056h0a1.066 1.066 0 00-1.056-1.057h-3.121z"/></g>'
  }
})
