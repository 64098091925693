import Vue from 'vue'
import PayHub from '@grantstreet/govhub-vue'
import { sentryException } from '@grantstreet/govhub-vue/src/sentry.js'
import { addI18n, mergeLangs } from '@grantstreet/psc-vue/utils/i18n.ts'
import en from './translations/en.ts'
import es from './translations/es.ts'

// Install PayHub
// Don't use Vue.use, it's sync, doesn't look at return values, and there's
// no benefit to the syntactic sugar.
const ok = await PayHub(Vue)

// You can check the status of the installer and continue/return
if (!ok && ok !== null) {
  const error = new Error('Error installing PayHub')
  console.error(error)
  // Sentry initialization is the first step of the installer so this will
  // only fail if sentry is incapable of sending messages
  sentryException(error)
}

addI18n(mergeLangs({ en, es }), sentryException)
