/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // schedpay-vue-prod & -nonprod projects
    prod: 'https://0239ccdd85e14964a8c5f294fa8936e6@o168195.ingest.sentry.io/4504607206998016',
    nonprod: 'https://a96f13e18ba340c0a80feae4f9db119d@o168195.ingest.sentry.io/4504607210143744',
  },
})
