<template>
  <Banners
    v-if="useAnnouncementsBanners"
  />
  <div
    v-else
  >
    <div
      v-if="hasAnnouncement"
      v-dompurify-html="$t('announcement')"
      class="font-danger-dk bg-danger-lt border-bottom p-3"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  computed: {

    ...mapConfigGetters(['useAnnouncementsBanners']),
    ...mapGetters('PayHub', [
      'hasAnnouncement',
    ]),
  },
}
</script>
