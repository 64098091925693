// *README:*
//
// https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone/15171030#15171030
// has great information about why we need libraries to do our date math and why
// if you find yourself writing complicated date code (even here) *you should
// stop* and find a library instead.
//
//
// Passing a string in any format other than JS's simplified ISO format to the
// Date constructor or Date.prototype.parse() will lead to unpredictable
// behavior and bugs. We should probably just never allow passing strings to
// those and enforce using individual component values.
// Read:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_formats
//
// Quote:
// When the time zone offset is absent, date-only forms are interpreted as a UTC
// time and date-time forms are interpreted as local time. This is due to a
// historical spec error that was not consistent with ISO 8601 but could not be
// changed due to web compatibility. See Broken Parser - A Web Reality Issue:
// https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality/
//
//
//
import { formatInTimeZone, getTimezoneOffset, fromZonedTime } from 'date-fns-tz'

/**
 * *Use this instead of new Date(<string>) or Date.prototype.parse(<string>).
 * Those are not safe.*
 *
 * Extracts month, day, and year from a string in the
 * format MM/DD/YYYY or MM/DD/YY, then returns new Date object using these
 * extracted values. If the input string does not match the expected format, it
 * returns null.
 * @function getDateFromMDY
 * @param  {string} dateString - A string in MM/DD/YYYY or MM/DD/YY format.
 * @return {Date|null} - Returns a new Date object or null
 */
export const getDateFromMDY = dateString => {
  const match = dateString.match(/^(?<month>\d{2})\/(?<day>\d{2})\/(?<year>\d{2}(?:\d{2})?)$/)
  if (!match) {
    return null
  }
  return new Date(
    // "Integer value representing the year. Values from 0 to 99 map to the
    // years 1900 to 1999. All other values are the actual year."
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#year
    parseInt(match.groups.year, 10),
    parseInt(match.groups.month, 10) - 1,
    parseInt(match.groups.day, 10),
  )
}

// Converts the passed date object to a YYYY-MM-DD string
export const ymd = (dateObject = new Date()) => {
  const year = dateObject.getFullYear()
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
  const date = ('0' + dateObject.getDate()).slice(-2)
  return year + '-' + month + '-' + date
}

// Converts the passed date object to a MM/DD/YYYY string
export const mdy = (dateObject = new Date(), { includeYear = true } = {}) => {
  const year = dateObject.getFullYear()
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
  const date = ('0' + dateObject.getDate()).slice(-2)
  return `${month}/${date}${includeYear ? `/${year}` : ''}`
}

// Converts a *simplified* ISO 8610 format string into a date object *in local
// time*
// This is important because Date.parse interprets different types of input as
// existing in different time zones.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#differences_in_assumed_time_zone
// That means that ISO will be parsed as UTC but toString()ed in local time. The
// date constructor, when given individual date components, will interpret them
// as local time.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters
// So using this you can avoid interpretation errors.
export const isoToLocalDate = iso => {
  const [year, month, day] = iso.split('-')
  const adjustedMonth = parseInt(month) - 1
  return new Date(year, twoDigit(adjustedMonth), day)
}

// Returns a new date one day after the passed date
export const aDayAfter = (date) => {
  return addDays(date, 1)
}

export function addDays (dateObject, numDays) {
  if (!dateObject || !numDays) {
    return dateObject
  }
  const newDate = new Date(dateObject)
  newDate.setDate(newDate.getDate() + numDays)
  return newDate
}

// Converts to two character string with leading zero
export const twoDigit = (numeral) => {
  return numeral < 10 ? '0' + numeral : String(numeral)
}

// Copies time from a to b
export const copyTime = (a, b) => setTimeOnDate({
  date: b,
  hours: a.getHours(),
  minutes: a.getMinutes(),
  seconds: a.getSeconds(),
})

// Returns true if date, month, and year are the same
export const datesEqual = (a, b) => a.getDate() === b.getDate() &&
  a.getMonth() === b.getMonth() &&
  a.getFullYear() === b.getFullYear()

// Returns the suffix (th, st, nd, rd) for the passed day number. *Returns empty
// string for non-english*
// Source: https://stackoverflow.com/a/15397495/1054633
export function daySuffix (dayNumber, locale = 'en') {
  // Given that the suffix in Spanish is depenent on the gender of the
  // succeeding word it's totally acceptable to just use the numeral
  if (!/en(?:\W|$)/i.test(locale)) {
    return ''
  }
  if (dayNumber > 3 && dayNumber < 21) {
    return 'th'
  }

  switch (dayNumber % 10) {
  case 1: return 'st'
  case 2: return 'nd'
  case 3: return 'rd'
  default: return 'th'
  }
}

// Returns a string for the passed Date object in the format "Sept 3, 2020".
// Note that June, July, and September will use four characters, while other
// months will use three.
// Spanish abbreviations are as follows:
// Jan   en.
// Feb   febr.
// Mar   mzo.
// Apr   abr.
// May   may.
// June  jun.
// July  jul.
// Aug   ag.
// Sept  set.
// Oct   oct.
// Nov   nov.
// Dec   dic.
const customMonths = {
  // Remember that in both the date object and array syntax these are 0 indexed.
  en: {
    5: 'June',
    6: 'July',
    8: 'Sept',
  },
  es: {
    0: 'en.',
    1: 'febr.',
    2: 'mzo.',
    4: 'may.',
    7: 'ag.',
    8: 'set.',
  },
}

/**
 * Format a date object to our custom localized string format.
 * @function formatDate
 * @param  {Date}     date  The date object to be formatted.
 * @param  {Boolean}  [numeric=false] If true indicates date should be numeric.
 * @param  {String}   [locale=i18n.global.locale.value]  Locale to use.
 * @return {string}         Localized, formatted date string
 */
export function formatDate (
  date,
  numeric,
  locale,
) {
  if (!date) {
    return ''
  }

  const monthType = numeric ? 'numeric' : 'short'
  const monthIndex = date.getMonth()
  locale = locale.substring(0, 2)
  const customMonth = customMonths[locale] ? customMonths[locale][monthIndex] : null

  let dateString = date.toLocaleDateString(locale, {
    year: 'numeric',
    month: monthType,
    day: 'numeric',
  })

  if (numeric) {
    return dateString
  }

  // Swap in custom words and reorder for Spanish
  dateString = dateString.split(' ')
  if (locale === 'es') {
    const day = dateString[0]
    dateString[0] = dateString[1]
    dateString[1] = day
  }
  dateString[0] = customMonth || dateString[0]
  dateString = `${dateString[0]} ${dateString[1]} ${dateString[2]}`

  return dateString
}

/**
 * @function toLocalDatestring
 * @param  {String} dateString  Accepts a date string without time/timeZone.
 * @return {String}             A date string localized to the user's timeZone.
 */
export const toLocalDatestring = (dateString) => {
  // Create a new date (assumed to be in the users timeZone) and convert to a
  // string localized to UTC
  const string = new Date(dateString).toLocaleString('en-US', { timeZone: 'UTC' })
  // This strips all non-ascii characters from the date string to ensure this
  // can be parsed into a real date later (IE adds non-ascii characters that
  // require this)
  // eslint-disable-next-line no-control-regex
  return string.replace(/[^\x00-\x7F]/g, '')
}

// Returns a new date one day before the passed date
export const aDayBefore = (date) => {
  if (!date) {
    return
  }
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() - 1)
  return newDate
}

/**
 * @function getOffsetToClientTZ
 * Get the offset between the local time and client time in milliseconds.
 * @param  {String} [clientTimezone='America/New_York'] The clients IANA timezone.
 * @return {Number} The milliseconds offset between local and server time.
 */
export const getOffsetToClientTZ = (clientTimezone = 'America/New_York') => {
  const now = new Date()

  // getTimezoneOffset reports being behind UTC as positive
  // Also, it returns minutes so convert to milliseconds
  const localOffset = now.getTimezoneOffset() * 60 * 1000

  // date-fns-tz reports being behind UTC as negative, so flip to match
  const clientOffset = getTimezoneOffset(clientTimezone, now) * -1

  // Return the difference
  return clientOffset - localOffset
}

/**
 * @function getFirstValidScheduleDateForClient
 * Return the earliest date which is still present or future for both this
 * machine and the client.
 * If today no longer exists in the client's timezone don't allow the user
 * to schedule anything for today.
 * We can't just use the server date because it could be "yesterday" for the
 * customer which would be confusing.
 * @param  {String} clientTimezone  The clients timezone.
 * @param  {Date}   [localDate]     A date object to use as local time.
 * @return {Date} A date which exists for both this machine and the client.
 */
export const getFirstValidScheduleDateForClient = (clientTimezone, localDate = new Date()) => {
  const serverDate = getDateProxy(clientTimezone, localDate)
  const serverDateYear = serverDate.getFullYear()
  const serverDateMonth = serverDate.getMonth()
  const localDateYear = localDate.getFullYear()
  const localDateMonth = localDate.getMonth()

  if (
    serverDateYear > localDateYear ||
    (
      serverDateYear === localDateYear &&
      serverDateMonth > localDateMonth
    ) ||
    (
      serverDateYear === localDateYear &&
      serverDateMonth === localDateMonth &&
      serverDate.getDate() > localDate.getDate()
    )
  ) {
    return serverDate
  }

  return localDate
}

/**
 * @function getDateProxy
 *
 * Caller beware! This is not quite deprecated, but per
 * https://grantstreet.slack.com/archives/C02MF0UU6LR/p1693344513866799:
 * "getDateProxy was the wrong way to solve js Date x IE issues when I wrote it
 * and I'd say it's usually a mistake to use it nowadays."
 *
 * "It does something called Epoch Shifting, which is problematic
 * and extremely difficult to not mess up completely. See:
 * https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone/15171030#15171030,
 * ask questions in slack, or come bug me if you're still convinced you need
 * this." - VS
 *
"
 *
 * Convert a datetime from one timezone to a different datetime in this timezone
 * which will have a similar (and useful) textual representation.
 * Original represented in browser time:
 * Wed Feb 19 2020 21:00:00 GMT-0800 (Pacific Standard Time)
 * Original as it needs to be displayed:
 * Wed Feb 20 2020 00:00:00 GMT-0500 (Eastern Standard Time)
 * Returned from this function:
 * Wed Feb 20 2020 00:00:00 GMT-0800 (Pacific Standard Time)
 * Useful for getting a textual representation or date components (M, D, Y, etc)
 * of a datetime as it would be output in a different timezone.
 * e.g. '2020-02-20'
 * @param  {String} [clientTimezone='America/New_York'] The target IANA timezone.
 * @param  {Date} [date=new Date()] The date to be converted.
 * @return {Date} A new datetime with a familiar textual representation.
 */
export const getDateProxy = (timezone = 'America/New_York', date = new Date()) =>
  new Date(date.getTime() - getOffsetToClientTZ(timezone))

/**
 * @function advanceToNextDate
 * Advances the passed date object to the next month/year on a given numerical
 * date. Does not mutate.
 * Remember that if you pass a date which doesn't exist in the target month
 * it will advance the same number of days. E.g. Sept 31st will be Oct 1st.
 * @param  {Date}   datetime  A date object to work from.
 * @param  {Number} [date=1]  Numerical date to advance to.
 * @return {Date}             The valid date.
 */
export const advanceToNextDate = (datetime, date = 1) => {
  datetime = new Date(datetime)

  let month = datetime.getMonth()
  datetime.setDate(date)
  month += 1
  if (month < 12) {
    datetime.setMonth(month)
  }
  else {
    datetime.setMonth(0)
    datetime.setFullYear(datetime.getFullYear() + 1)
  }

  return datetime
}

/**
 * @function getNextValidCalendarDate
 * Returns the next valid occurrence of a calendar date on which a payment could
 * be scheduled recurring. Valid dates for recurring payments are the 1-28th.
 * If datetime is before "today" at 00:00:00 or if it is invalid, then the
 * function advances from datetime to the next occurrence of targetDate.
 * Does not mutate. Doesn't account for client/server time.
 *
 * e.g.
 * Assuming today is <= 2020-08-20
 * getNextValidCalendarDate(new Date('12/28/2020')) // 12/28/2020
 * getNextValidCalendarDate(new Date('12/29/2020')) // 01/01/2021
 * getNextValidCalendarDate(new Date('08/20/2020'), 20, 20) // 08/20/2020
 * getNextValidCalendarDate(new Date('08/21/2020'), 20, 20) // 09/20/2020
 * getNextValidCalendarDate(new Date('12/21/2020'), 7, 20) // 01/07/2021
 * getNextValidCalendarDate(new Date('12/21/2020'), 1, 20) // 01/01/2021
 * (see the unit tests for examples, as well).
 *
 * @param  {Date}   datetime        A date object to work from.
 * @param  {Number} [targetDate=1]  The date to advance to.
 * @param  {Number} [limitDate=28]  The latest acceptable date.
 * @throws {RangeError}             Throws if targetDate > limitDate
 * @return {Date}                   The valid date.
 */
export const getNextValidCalendarDate = (datetime, targetDate = 1, limitDate = 28) => {
  // If the target date is invalid throw
  if (targetDate > limitDate) {
    throw new RangeError('Target date cannot be greater than the limit date')
  }

  // If the date is within bounds ()
  if (
    datetime.getDate() <= limitDate &&
    datetime >= setTimeOnDate({ date: new Date(), hours: 0, minutes: 0, seconds: 0 })
  ) {
    return new Date(datetime)
  }
  return advanceToNextDate(datetime, targetDate)
}

/**
 * @function setTimeOnDate
 * Returns a new date object with the provided time set.
 * @param {object} obj A parameters object.
 * @param {Date} obj.date A date object to start from.
 * @param {number|string} obj.hours The hours to set on the new date. If a
 *                                  meridiem is provided then it will be trated
 *                                  as 12 hour format.
 * @param {number|string} [obj.minutes=date.getMinutes()] The minutes to be set.
 * @param {number|string} [obj.seconds=date.getSeconds()] The seconds to be set.
 * @param {string} obj.meridiem Meridiem information. e.g. "am". If provided,
 *                              hours will be treated as 12 hour format.
 * @return {Date} A date with the provided time set.
 */
export const setTimeOnDate = ({
  date,
  hours,
  minutes,
  seconds,
  meridiem,
}) => {
  if (hours) {
    hours = parseInt(hours)
    if (meridiem === 'pm' && hours !== 12) {
      hours += 12
    }
    else if (meridiem === 'am') {
      hours = hours === 12 ? 0 : hours
    }
  }

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    typeof hours === 'undefined' ? date.getHours() : hours,
    typeof minutes === 'undefined' ? date.getMinutes() : parseInt(minutes),
    typeof seconds === 'undefined' ? date.getSeconds() : parseInt(seconds),
  )
}

/**
 * @function extractHoursMeridiem
 * Extracts hour information in twelve hour format and calculates meridiem.
 * @param  {Date} date            Date to be used.
 * @param  {boolean} objectFormat Return object with time and meridiem props.
 * @return {string|object}        String or object.
 */
export const extractHoursMeridiem = (date, objectFormat = false) => {
  let time = date.getHours()
  const meridiem = time >= 12 ? 'pm' : 'am'

  if (meridiem === 'am') {
    time = time === 0 ? 12 : time
  }
  else if (time > 12) {
    time -= 12
  }

  time = `${time}:00`

  if (!objectFormat) {
    return `${time}${meridiem}`
  }
  return {
    time,
    meridiem,
  }
}

/**
 * Formats a date object into a string using a given format string and/or
 * timezone. The format string uses date-fns formatting.
 * See https://date-fns.org/v2.29.3/docs/format
 * The timezone defaults to "America/New_York" (EST).
 * @function toClientTimestamp
 * @param  {Date}           Date    A date object to format.
 * @param  {formatString}   string  The format string for the date.
 * @param  {clientTimeZone} [string="America/New_York"] The client's timezone.
 * @return {string}         Localized, formatted date string
 */
export const toClientTimestamp = (
  date,
  formatString,
  clientTimeZone = 'America/New_York',
) => {
  return formatInTimeZone(new Date(date), clientTimeZone, formatString)
}

/**
 * Does the reverse of `toClientTimestamp` and creates a local Date object from
 * a timestamp string and a given timezone. I.e., What time is it in your neck
 * of the woods if it is 8am in Honolulu?
 *
 * Defaults to "America/New_York" (EST) if no timezone is specified.
 * @function fromClientTimestamp
 * @param {dateString}     string A date string in the format of "%Y-%m-%dT%H:%M:%S" without timezone
 * @param {clientTimeZone} [string="America/New_York"] The timezone to create the date from
 * @returns {Date} A localized Date object
 */
export const fromClientTimestamp = (
  dateString,
  clientTimeZone = 'America/New_York',
) => {
  return fromZonedTime(new Date(dateString), clientTimeZone)
}

// Currently not used in the monorepo,
// but probably used in the direct-charge repo
/**
 * Does the same as fromClientTimestamp but only for timeless date strings,
 * eg. '2023-04-01'
 * This will throw an error on improperly formatted strings.
 * @function fromClientDate
 * @param {dateString}     string A date string in the format of "%Y-%m-%d" without hours, minutes, seconds, or timezone
 * @param {clientTimeZone} [string="America/New_York"] The timezone to create the date from
 * @returns {Date} A localized Date object
 */
export const fromClientDate = (
  dateString,
  clientTimeZone,
) => {
  if (!/^\d\d\d\d-\d\d-\d\d$/.test(dateString)) {
    throw new Error(`${dateString} is not in the expected date format yyyy-mm-dd`)
  }
  return fromClientTimestamp(`${dateString}T00:00:00`, clientTimeZone)
}

/**
 * Accepts a string version of a date that possibly includes a time component using T or comma
 * Strips the time component and returns a date object with the given date at midnight user's time
 * @param {String} dateString  String version of date with time represented after T or comma
 * @return {Date}
 */
export const getTimelessDateFromString = (
  dateString,
) => {
  const tIndex = dateString.indexOf('T')
  const commaIndex = dateString.indexOf(',')
  dateString = tIndex > -1 ? dateString.substring(0, tIndex) : commaIndex > -1 ? dateString.substring(0, commaIndex) : dateString
  // XXX: This is a bug and we need to fix it when we refactor our date
  // handling. See comments at top of doc.
  const date = new Date(dateString)
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

/**
 * Accepts a date object that possibly includes a time component
 * and returns a string in "YYYY-MM-DD" that represents the UTC date of that date
 * @param {Date} date
 * @return {String}
 */
export const getTimelessStringFromDate = (date) => {
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()

  return `${year}-${twoDigit(month)}-${twoDigit(day)}`
}

/**
 * Accepts a date object that possibly includes a time component
 * and returns a string in "YYYY-MM-DD" that represents the local date of that date.
 * In other words, this returns just the date part of the date without making any TZ adjustments.
 * @param {Date} date
 * @return {String}
 */
export const getTimelessStringFromDateLocal = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${twoDigit(month)}-${twoDigit(day)}`
}

/**
 * @function relativeTimestamp
 * Returns a timestamp for one date relative to another.
 * E.g.
 * Now
 * 42 seconds ago
 * 7 minutes ago
 * 9 hours ago
 * Yesterday
 *
 * Falls back to mm/dd or mm/dd/yyy format
 * I.e.
 * 09/17
 * 08/15/2018
 *
 * @param  {Object} params A parameters object
 * @param  {String} from   The Date being described
 * @param  {String} to     The Date it is relative to
 * @return {String}        A string description of the "from" date
 */
export const relativeTimestamp = ({ from, to, includeYear }) => {
  // Remember to keep tests up to date
  if (typeof from !== 'object' || typeof to !== 'object') {
    throw new TypeError('Arguments must be instances of Date')
  }
  // TODO: Localize these
  // I suggest splitting the time formats (seconds, minutes, hours, etc.) out of
  // a comma delimited string like we do for months

  const elapsed = to - from

  const msPerSecond = 1000
  const msPerMinute = msPerSecond * 60
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerYear = msPerDay * 365

  if (elapsed < msPerSecond) {
    return 'Now'
  }
  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / msPerSecond)} seconds ago`
  }
  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} minutes ago`
  }
  if (elapsed < msPerDay) {
    const count = Math.round(elapsed / msPerHour)
    const hoursToday = to.getHours()
    if (count <= hoursToday) {
      return `${count} hours ago`
    }
    return 'Yesterday'
  }
  return mdy(from, { includeYear: includeYear || elapsed >= msPerYear })
}

/**
 * @function formatDateOnInput
 * Formats a string into mm/dd/yyyy format as it is being entered.
 * E.g.
 * 01          -> 01/
 * 2           -> 02/
 * 03/4        -> 03/04
 * 04/052      -> 04/05/2
 *
 * @param  {String} delimiter   Delimiter between month, date, and year (default: '/')
 * @param  {String} dateString  The current date string to be formatted
 * @return {String}             The input dateString in mm/dd/yyyy format
 */
export const formatDateOnInput = (dateString, delimiter = '/') => {
  let val = dateString

  // Handle typing slashes after single digit
  if ((val.length === 2 || val.length === 5) && val[val.length - 1] === delimiter) {
    val = val.substring(0, val.length - 2) + '0' + val.substring(val.length - 2)
  }

  val = val.replace(/\D/g, '')

  if (val.length === 1 && val > 1) { // Handle interpreting month
    val = '0' + val + delimiter // No months greater than 12 so autofill slash and 0
  }
  else if (val.length > 1) {
    val = val.substring(0, 2) + delimiter + val.substring(2)
  }

  if (val.length === 4 && val.substring(3) > 3) { // Handle interpreting day
    val = val.substring(0, 2) + delimiter + '0' + val.substring(3) + delimiter
    // No days greater than 31 so autofill slash and 0
  }
  else if (val.length > 4) {
    val = val.substring(0, 5) + delimiter + val.substring(5)
  }

  if (val.length > 10) val = val.substring(0, 10) // max 10 characters 'mm/dd/yyyy'

  return val
}

// English and Spanish 3-letter abbreviations for the day of the week. Array
// indices are aligned with the Date object's `getDay()` method.
const dayAbbreviations = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  es: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
}

/**
 * Returns the abbreviated day of week based on a Date object's `getDay()`
 * method and a given locale. Defaults to "en" if no locale is given.
 * @function getAbbreviatedDay
 * @param {dayIndex} number An Date.getDay() index; should be between 0 and 6
 * @param {locale}   string Either "en" or "es"; defaults to "en"
 * @returns {string} An abbreviation for the day of the week; returns an empty
 *                   string if an invalid dayIndex is given
 */
export const getAbbreviatedDay = (dayIndex, locale = 'en') => {
  if (dayIndex < 0 || dayIndex > 6) {
    return ''
  }
  if (locale !== 'en' && locale !== 'es') {
    locale = 'en'
  }
  return dayAbbreviations[locale][dayIndex]
}

/**
 * The days of the week, in English, to use for APIs that require these
 * values (like Scheduled Payments when specifying a weekly day)
 */
export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
