<template>
  <span v-if="!enableHomePage">
    <!--
      The home page is never a valid route for some embedded solutions (Embedded
      Public Site and Checkout Widget)
    -->
  </span>
  <router-link
    v-else-if="siteUsesHomepage || definitelyGoHome"
    :to="{
      name: 'home',
      params: $route.params,
    }"
    :class="`btn-${variant}`"
    :aria-label="$t('checkout.home_page.new_service')"
    class="btn"
  >
    <slot>{{ $t('checkout.return') }}</slot>
  </router-link>
  <b-link
    v-else-if="returnUrl"
    :href="returnUrl"
    :class="`btn-${variant}`"
    class="btn"
  >
    <slot>{{ $t('checkout.return') }}</slot>
  </b-link>
  <b-link
    v-else-if="returnUrlFromCurrentCart"
    :href="returnUrlFromCurrentCart"
    :class="`btn-${variant}`"
    class="btn"
  >
    <slot>{{ $t('checkout.return') }}</slot>
  </b-link>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  props: {
    variant: {
      type: String,
      default: 'outline-primary',
    },
    definitelyGoHome: {
      type: Boolean,
      default: false,
    },
    // This url is for when we want to manually specify the url
    returnUrl: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapConfigGetters(['siteUsesHomepage']),

    ...mapGetters('PayHub', {
      returnUrlFromCurrentCart: 'returnUrl',
    }),

    ...mapState('PayHub', ['enableHomePage']),
  },
}
</script>
