/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wrench-circle': {
    width: 16,
    height: 16,
    viewBox: '1694 1220 24 24',
    data: '<g transform="translate(1694.5 1220.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M6.04 21.62l3.94-3.95.47-.47M1.69 17.48l1.93-1.93 2.59-2.59M14.58 6l-3.286 3.294a1 1 0 000 1.412L12.7 12.12a1 1 0 001.415 0l3.3-3.29M17.41 8.83a5.992 5.992 0 01-6.96 8.37M6.21 12.96A5.992 5.992 0 0114.58 6"/><circle pid="1" cx="11.5" cy="11.5" r="11.5"/></g>'
  }
})
