/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'loop': {
    width: 16,
    height: 16,
    viewBox: '1344 1022.293 24 19.414',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M1357.5 1023l-3 3M1357.5 1029l-3-3M1354.5 1035l3 3M1354.5 1041l3-3M1354.5 1026h7a6 6 0 016 6h0a6 6 0 01-6 6h-1.031M1357.5 1038h-7a6 6 0 01-6-6h0a6 6 0 016-6h1"/></g>'
  }
})
