/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'property-tax': {
    width: 16,
    height: 16,
    viewBox: '0 0 21.5 21.5',
    data: '<g _fill="none" _stroke="#666" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 15" d="M13.801 20.75h-6.1v-9.126a.4.4 0 01.426-.426h5.245a.4.4 0 01.426.426z"/><path pid="1" data-name="Path 16" d="M20.366 7.45L18.15 5.91V1.603a.855.855 0 00-.853-.853h-.853a.855.855 0 00-.853.853v2.473L10.771.75l-9.637 6.7a.838.838 0 00-.384.72v1.28h2.6v10.447a.855.855 0 00.853.853h13.093a.855.855 0 00.853-.853V9.45h2.6V8.17a.838.838 0 00-.383-.72z" stroke-linecap="round" stroke-linejoin="round"/></g>'
  }
})
