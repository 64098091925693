/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'minus-circle': {
    width: 16,
    height: 16,
    viewBox: '1344 1120 24 24',
    data: '<g transform="translate(1344.5 1120.5)" _fill="none" _stroke="#666" stroke-linejoin="round"><circle pid="0" cx="11.5" cy="11.5" r="11.5"/><path pid="1" stroke-linecap="round" d="M5.5 11.5h12"/></g>'
  }
})
