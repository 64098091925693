<template>
  <b-modal
    v-model="active"
    hide-footer
    :title="''"
    size="md"
    class="text-body text-left"
    hide-header
    body-class="redirectWarning"
    no-close-on-esc
  >

    <span class="d-flex flex-row justify-content-end align-items-end pt-3 pr-3">
      <a
        class="cursor-pointer"
        @click="active = false"
      >
        <svgicon
          :fill="false"
          icon="x"
        />
      </a>
    </span>

    <div class="px-5 pt-3 pb-4">
      <b-row>
        <b-col
          v-dompurify-html="$t(loginSiteEnabled ? 'redirect.modal.withlogin.text' : 'redirect.modal.nologin.text', {client_site: redirectLink})"
        />
      </b-row>
      <b-row
        no-gutters
        align-h="end"
      >
        <b-col
          class="mt-2 order-sm-last"
          cols="12"
          sm="auto"
        >
          <LoginButton
            v-if="loginSiteEnabled"
            class="w-100"
            size="md"
            @show-login-disabled-modal="loginDisabled = true"
            @log-in="$emit('log-in', $event)"
          >
            {{ $t('topnav.login') }}
          </LoginButton>
          <b-button
            v-else
            variant="primary"
            @click="active = false"
          >
            {{ $t('common.cancel') }}
          </b-button>
        </b-col>
        <b-col
          class="mt-2 mr-0 mr-sm-2"
          cols="12"
          sm="auto"
        >
          <b-button
            variant="outline-primary"
            class="w-100"
            @click="continueToSite"
          >
            {{ $t('redirect.modal.continue') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="loginDisabled"
      >
        <Alert
          class="mt-5"
          variant="danger"
        >
          {{ $t('login_modal.disabled.description_short') }}
        </Alert>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import Alert from './Alert.vue'
import LoginButton from './LoginButton.vue'

export default {
  emits: ['input', 'log-in'],
  components: {
    Alert,
    LoginButton,
  },

  props: {
    value: {
      type: Boolean,
      default: null,
    },
    redirectLink: {
      type: String,
      default: null,
    },
    // Whether login is enabled at all
    loginSiteEnabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    active: {
      get () {
        return Boolean(this.value && this.redirectLink)
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },

  data: () => ({
    loginDisabled: false,
  }),

  methods: {
    continueToSite () {
      window.location.href = this.redirectLink
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .redirectWarning.modal-body {
  padding: 0;
}
</style>
