/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'parking-ticket': {
    width: 16,
    height: 16,
    viewBox: '0 0 12.946 21.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 1319" d="M7.26 3.327a3.311 3.311 0 012.962 3.038H8.837c0-.923-1.077-1.692-2.346-1.692s-2.346.769-2.346 1.692H2.76a3.311 3.311 0 012.961-3.038 4.252 4.252 0 01.693-.077 8.012 8.012 0 01.846.077zM6.414.75a5.414 5.414 0 00-1.538.231 5.8 5.8 0 00-4 3.885 5.946 5.946 0 00.462 3.346l1.654 5.692a3.047 3.047 0 002.5 1.615h.154v4.462a.771.771 0 00.768.769h.114a.771.771 0 00.769-.769v-4.5h.654a2.739 2.739 0 002.385-1.654l1.347-5.577a6.439 6.439 0 00.385-3.385h0a5.874 5.874 0 00-4-3.885A6.852 6.852 0 006.414.75zm1.423 8.769"/><g data-name="Group 1899"><path pid="1" data-name="Path 1320" d="M6.067 8.365c1.038 0 1.846.231 1.846 1.154s-.846 1.154-1.846 1.154"/><path pid="2" data-name="Line 115" d="M6.067 8.365v3.846"/></g></g>'
  }
})
