/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-details': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<g data-name="Group 2434"><g data-name="Ellipse 419" _fill="none" _stroke="#666"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g></g><g data-name="Group 2440" _fill="none" _stroke="#666" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1468" d="M20.043 10.391h2.783V25H11V10.391h2.783" stroke-linecap="round"/><path pid="3" data-name="Path 1469" d="M20.044 10.391v.7h-6.261v-.7A1.4 1.4 0 0115.174 9h3.479a1.4 1.4 0 011.391 1.391z" stroke-linecap="round"/><path pid="4" data-name="Path 1470" d="M12.391 15.265l1.391 1.391 2.087-2.091"/><path pid="5" data-name="Path 1471" d="M12.391 19.439l1.391 1.391 2.087-2.091"/><path pid="6" data-name="Line 335" stroke-linecap="round" d="M17.609 15.957h3.478"/><path pid="7" data-name="Line 336" stroke-linecap="round" d="M17.609 20.13h3.478"/></g>'
  }
})
