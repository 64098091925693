/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shipping': {
    width: 16,
    height: 16,
    viewBox: '0 0 36 36',
    data: '<g _fill="none"><circle pid="0" cx="18" cy="18" r="17"/><circle pid="1" cx="18" cy="18" r="16.5" _stroke="#666"/></g><g transform="translate(8.5 10.5)" _fill="none" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-miterlimit="10"><path pid="2" d="M6.7 11.9h4.5V2.2c0-.4-.2-.7-.6-.7H2.2c-.4 0-.7.2-.7.6V11.9h1.7M16.5 11.9h1v-2c0-.2-.1-.4-.3-.6l-1.8-.9-.3-2.2c0-.4-.3-.6-.7-.6h-3.2v6.3H13" stroke-linejoin="round"/><circle pid="3" cx="14.7" cy="11.9" r="1.7"/><circle pid="4" cx="5" cy="11.9" r="1.7"/></g>'
  }
})
