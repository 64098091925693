/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'water-bill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16.87 21.504',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 1321" d="M9.492 1.422h1.579a1.037 1.037 0 011.81.578 1.015 1.015 0 01-.462 1.04 1.151 1.151 0 01-1.117 0 2.492 2.492 0 01-.347-.308H9.45a1.442 1.442 0 01-.462.5v2.349h.385v.886a3.113 3.113 0 011.579 1.309h3.35a2.112 2.112 0 011.733 1.5 3.564 3.564 0 01.077 1.117v4.044h-3.2v-3.426h-1.918a3.232 3.232 0 01-2.85 1.617 3.165 3.165 0 01-2.619-1.579H2.95v.616H.75V7.237h2.157v.616h2.772a3.081 3.081 0 011.579-1.309l.039-.039V5.62h.423V3.27a1.448 1.448 0 01-.539-.539H5.564a1.084 1.084 0 01-1.04.462.992.992 0 01-.847-.693 1.117 1.117 0 01.193-1.04 1.093 1.093 0 011-.347 1.161 1.161 0 01.578.347h1.695A1.376 1.376 0 018.529.767a1.279 1.279 0 01.963.655z"/><path pid="1" data-name="Path 1322" d="M15.846 19.291a1.464 1.464 0 01-2.927 0c0-.809 1.463-2.965 1.463-2.965s1.464 2.157 1.464 2.965z"/></g>'
  }
})
