/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'more-vertical': {
    width: 16,
    height: 16,
    viewBox: '1703 1070 6 24',
    data: '<g transform="translate(1703.5 1070.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="2.5" cy="2.5" r="2.5"/><circle pid="1" cx="2.5" cy="2.5" r="2.5" transform="translate(0 18)"/><circle pid="2" cx="2.5" cy="2.5" r="2.5" transform="translate(0 9)"/></g>'
  }
})
