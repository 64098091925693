/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'open-package': {
    width: 16,
    height: 16,
    viewBox: '1844 1272.5 24 19',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1847.5 1280.13v7.87l8 3v-12l-8-3M1864.5 1276l-9 3v12l9-3v-8"/><path pid="1" d="M1847.5 1276l8 3 9-3-9-3z"/><path pid="2" d="M1855.5 1279l3 3 9-3-3-3zM1855.5 1279l-3 3-8-3 3-3z"/></g>'
  }
})
