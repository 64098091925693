import {
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#type-only-imports-and-export
  type SharedHelperOptions,
  type LoginFunctionOptions,
  type SharedHelperUtilities,
  normalizeFieldMap,
} from '../plugin.ts'
import type { User as OidcUser } from 'oidc-client-ts'
import { sentryException } from '../sentry.js'
import getOidcSettings, {
  type OidcSettingsOptions,
} from './config.ts'
import Vuex from 'vuex'
import Vue from 'vue'

import {
  vuexOidcCreateStoreModule,
  vuexOidcProcessSignInCallback,
  vuexOidcCreateUserManager,
  type VuexOidcErrorPayload,
} from 'vuex-oidc'

Vue.use(Vuex)

/**
 * Checks whether the current URL is a callback URL, and if so finalizes the
 * login callback process *and redirects the page* if so.
 *
 * @function handleOidcCallbackPage
 * @return {Promise<boolean>} - Returns false if oidc processes or redirect failed, true
 * otherwise.
 */
export const handleOidcCallbackPage = async ({
  oidcClient,
  baseRoute,
}: OidcSettingsOptions): Promise<boolean> => {
  if (/\/callback(?:[/#?]|$)/.test(window.location.href)) {
    // This is the callback in the main window
    try {
      const settings = getOidcSettings({
        baseRoute,
        oidcClient,
      })

      // TODO: PSC-20491 - Remove these casts when this issue is resolved
      // https://github.com/perarnborg/vuex-oidc/issues/210
      const redirectPath = await vuexOidcProcessSignInCallback(settings) as unknown as string
      const oidcUserManager = vuexOidcCreateUserManager(settings)
      await oidcUserManager.getUser()

      // User just successfully logged in
      window.history.replaceState(null, document.title, redirectPath)
    }
    catch (error) {
      console.error('Authentication error:', error)
      sentryException(error as Error)
      window.history.replaceState(null, document.title, '/')
      return false
    }
  }

  // This is Auth0-specific cruft, which we need because it doesn't do
  // "end_session" by the OIDC book. (If it did, vuex-oidc would handle this
  // automatically.)
  // XXX: Should we remove it then?
  if (/\/logout(?:[/#?]|$)/.test(window.location.href)) {
    const url = sessionStorage.getItem('vuex_oidc_active_route') || '/'
    window.history.replaceState(null, document.title, url)
  }
  return true
}

const handleOidcError = (error?: VuexOidcErrorPayload): void => {
  // This is never a user-visible error, but if something unexpected
  // blew up, we want the Sentry notification about it.

  if (!error) {
    return
  }

  if (error.error === 'invalid_grant') {
    // This error is thrown if a user's refresh token has expired; ignore.
    // Unfortunately, the error does not include any more details to specify
    // to ignore only refresh token failures.
    return
  }

  if (error.toString().match(/Login required|End-User authentication is required/)) {
    // The "Login required" error is normal processing when the user isn't
    // logged in, so we don't notify in that case.
    return
  }

  console.error(error)
  if (error.context && error.error) {
    sentryException({
      name: error.context,
      message: error.error,
    })
    return
  }
  // For some reason, error.context and error.error seem to be undefined for
  // some high volume alerts. Lets see if this gets us any details...
  sentryException({
    name: error.toString(),
    message: error.toString(),
  })
}

export type FullHelperExclusiveOptions = OidcSettingsOptions &
  {
    getAuthQueryParams: () => Record<string, string>
  }

export type FullHelperOptions = SharedHelperOptions & FullHelperExclusiveOptions

export type FullLoginHelpers = SharedHelperUtilities & {
  // *Do not* add any properties to this local type that are shared with
  // ExternalLoginHelpers. Those need to go in SharedHelperUtilities.
  handleOidcCallbackPage: () => ReturnType<typeof handleOidcCallbackPage>
}

/**
 * Creates utilities for "full" login mode, which supports a full app login
 * experience (redirecting the user to a login page, handling the callback, and
 * handling silent token renewals).
 */
export default ({
  oidcClient,
  getAuthQueryParams,
  baseRoute,
  user,
  beforeLogin,
  onUserLoaded,
  onUserSignedOut,
  onLoginFailure,
  userFieldMap = {},
}: FullHelperOptions): FullLoginHelpers => {
  // Format the structure for consistency
  const normalizedFieldMap = normalizeFieldMap(userFieldMap)

  const setPublicUserFields = (oidcUser: OidcUser) => {
    // Update the public user object with fields from the oidcUser
    for (const [appField, { current, deprecated }] of Object.entries(normalizedFieldMap)) {
      // Fallback to deprecated fields if the current field name isn't set
      let newValue
      for (const field of [current, ...deprecated]) {
        if (oidcUser.profile[field]) {
          newValue = oidcUser.profile[field]
          break
        }
      }
      // Unsets local field if no value was set in oidc
      user[appField] = newValue
    }

    user.id = oidcUser.profile.sub
    user.email = oidcUser.profile.email
    user.getAccessToken = () => oidcUser.access_token as (string | undefined)
  }

  const store = new Vuex.Store({
    modules: {
      oidcStore: vuexOidcCreateStoreModule(
        getOidcSettings({
          baseRoute,
          oidcClient,
        }),

        {
          dispatchEventsOnWindow: false,
        },

        {
          userLoaded: (oidcUser: OidcUser) => {
            // Update the global `user` object fields based on the new OidcUser
            setPublicUserFields(oidcUser)

            onUserLoaded(user)
          },

          userUnloaded: () => user.reset(),

          userSignedOut: () => {
            user.reset()
            onUserSignedOut()
          },

          accessTokenExpired: async () => {
            user.reset()

            // To unload all user-specific data, just reload the page.
            // It seems risky to try to unload it from each module,
            // although that seem cleaner in principle. This should
            // happen rarely, regardless.
            if (store.getters.oidcUser) {
              await store.commit('removeOidcUser')

              // .reload() doesn't work properly with history in FF
              // eslint-disable-next-line no-self-assign
              window.location.href = window.location.href
            }
          },

          oidcError: (oidcError) => {
            onLoginFailure(
              (oidcError?.error && !(oidcError.error instanceof Error))
                ? new Error(oidcError.error)
                // Typescript can't properly narrow the property type so the
                // assertion is necessary
                : oidcError?.error as Error | undefined)
          },

          silentRenewError: handleOidcError,
          automaticSilentRenewError: handleOidcError,
        },
      ),
    },
  })

  /**
   * This will resolve once we know whether the user is logged in or not. That
   * is determined by vuex-oidc code in a hidden iframe.
   */
  let resolveAuthPromise
  const authPromise = new Promise<void>(resolve => {
    resolveAuthPromise = resolve
  })

  return {
    // Initialize asynchronously. Must resolveAuthPromise().
    initAuth: () => store.dispatch('authenticateOidcSilent').catch(handleOidcError).then(resolveAuthPromise),

    authPromise,
    /**
     * Launches the OIDC sign in/sign up workflow.
     */
    logIn: ({
      signup,
    }: LoginFunctionOptions = {}): Promise<void> => {
      beforeLogin()

      return store.dispatch('authenticateOidc', {
        redirectPath: window.location.href,
        options: {
          extraQueryParams: {
            ...getAuthQueryParams?.(),
            'origin_url': window.location.href,
            ...(signup ? { 'action': 'signup' } : {}),
          },
        },
      })
    },

    logOut: async (redirectUri?: string): Promise<void> => {
      if (redirectUri && typeof redirectUri !== 'string') {
        console.warn(`@grantstreet/login: redirectUri must be a string. ${typeof redirectUri} given.`)
        redirectUri = undefined
      }

      const oidcUser = await store.dispatch('getOidcUser')

      // If id_token is not accessible then the user data may have been removed or
      // user is already logged out. See PSC-10593
      if (!oidcUser?.id_token) {
        // Try to re-fetch oidcUser
        try {
          await store.dispatch('authenticateOidcSilent')
        }
        // If re-fetch fails, we assume the user is already logged out
        catch (error) {
          console.warn('could not get user - skipping provider logout')
          // We need to make sure page reloads to E.g. stop showing user data on
          // page
          window.location.reload()
          return
        }
      }
      return store.dispatch('signOutOidc', {
        // May be null
        'post_logout_redirect_uri': redirectUri || window.location.href,
      })
    },

    /**
     * Update the Login Service profile and public User instance.
     */
    updateUserData: async (data: {[key: string]: string | undefined}) => {
      // Get the current user to update
      const oidcUser = await store.dispatch('getOidcUser')

      // Map data to configured oidc fields.
      for (const [appField, value] of Object.entries(data)) {
        // If someone passes arbitrary values that aren't defined during setup
        // when we shouldn't be storing them in the service.
        if (!normalizedFieldMap[appField]) {
          continue
        }

        const { current, deprecated } = normalizedFieldMap[appField]

        oidcUser.profile[current] = value
        // Override any deprecated field names for this now that a new value
        // has been set.
        for (const field of deprecated) {
          oidcUser.profile[field] = undefined
        }
      }

      // Post the update
      await store.dispatch('storeOidcUser', oidcUser)

      // Update local User instance
      setPublicUserFields(oidcUser)
    },

    handleOidcCallbackPage: () => handleOidcCallbackPage({ oidcClient, baseRoute }),
  }
}
