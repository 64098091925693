<template>
  <div class="bg-white rounded-xl p-4 font-italic text-center">
    <div>{{ $t('checkout.empty') }}</div>
    <HomepageLink
      v-if="siteUsesHomepage"
      class="mt-3"
    />
    <CancelCartButton
      v-else
      fallback-homepage
      class="mt-3"
    />
  </div>
</template>

<script>
import HomepageLink from './HomepageLink.vue'
import CancelCartButton from './CancelCartButton.vue'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  components: {
    HomepageLink,
    CancelCartButton,
  },

  computed: {
    ...mapConfigGetters(['siteUsesHomepage']),
  },
}
</script>
