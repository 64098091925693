/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'toilet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.795 1.702h-3.22a1.872 1.872 0 00-1.873 1.872v7.658c0 1.034.838 1.873 1.872 1.873h3.22a1.872 1.872 0 001.873-1.873V3.574a1.872 1.872 0 00-1.872-1.872zM2.9 13.105h19.398M12.945 22.298c-5.495 0-9.951-4.12-9.951-9.193M21.09 13.105c0 3.164-2.77 5.73-6.197 5.73M5.185 22.298H17.99M16.4 18.656l-.552 3.642M10.362 12.74c0-.73.29-2.369.655-2.369h10.345c.355 0 .655 1.648.655 2.369" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
