/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flower': {
    width: 21.5,
    height: 24.5,
    viewBox: '0 0 21.5 24.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Path 6837" d="M12.75 2.75c0 1.1-2 4-2 4s-2-2.9-2-4a2 2 0 114 0z"/><path pid="1" data-name="Path 6838" d="M5.75 5.75c1.1 0 4 2 4 2s-2.9 2-4 2a2 2 0 010-4z"/><path pid="2" data-name="Path 6839" d="M8.75 12.75c0-1.1 2-4 2-4s2 2.9 2 4a2 2 0 01-4 0z"/><path pid="3" data-name="Path 6840" d="M15.75 9.75c-1.1 0-4-2-4-2s2.9-2 4-2a2 2 0 010 4z"/><path pid="4" data-name="Path 6841" d="M10.75 23.75c2-7 4-10 10-10-4 6-4 10-10 10z"/><path pid="5" data-name="Path 6842" d="M10.75 23.75c-2-7-4-10-10-10 4 6 4 10 10 10z"/></g>'
  }
})
