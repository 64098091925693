/**
 * Client for the E-Wallet microservice API
 */
import GenericApiClient from '@grantstreet/api-client'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { baseEWalletUrl } from '@grantstreet/psc-environment/environment.js'

// This is used in the microservice API URL, like:
// https://e-wallet.grantstreet.com/api/v1/get_tenders
const API_VERSION = 'v1'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.app = 'E-Wallet'
    this.axios.defaults.headers.post['Content-Type'] = 'application/json'
    this.baseUrl = process.env?.GSG_EWALLET_SERVICE || this.getBaseUrl()
  }

  getBaseUrl () {
    let baseUrl = baseEWalletUrl

    // TODO: Get rid of this JWT origin-matching block once GovHub is setting
    // GSG_ENVIRONMENT
    if (!baseUrl) {
      let decodedJwt
      try {
        decodedJwt = jwtDecode(this.jwt)
      }
      catch (error) {
        console.error('Error decoding jwt')
      }

      if (!decodedJwt || !decodedJwt.origin) {
        return ''
      }

      baseUrl = decodedJwt.origin
    }

    const slash = baseUrl.endsWith('/') ? '' : '/'
    return `${baseUrl}${slash}api/${API_VERSION}`
  }

  getAuthHeader () {
    return this.jwt
  }

  // Find out if the user is behind a VPN
  async authProbe (handler) {
    try {
      // probe the server with an authorization header
      await axios.post(this.url('/probe'), '', {
        headers: {
          Authorization: 'Test',
        },
      })
    }
    catch (error) {
      // failure here means the header never made it to the server, so the user
      // is behind a VPN

      // Checking if error.response is undefined is here to catch opaque headers
      // from CORS errors. We are unable to see the response status but we
      // know that we have errored. This is mainly for when Cloudflare blocks an IP,
      // however it might be possible that it might catch some weird network errors as well
      // such as a dropped internet connection. We would not be able to know since
      // the response body is hidden
      if ((error.response && error.response.status === 403) ||
        typeof error.response === 'undefined') {
        handler()
      }
    }
  }

  getTenders () {
    return this.get('/get_tenders')
  }

  savePaymentInfo (data) {
    return this.post('/save_payment_info', data)
  }

  saveTender (data) {
    return this.post('/tenders', data)
  }

  removeTender (userId, tenderId) {
    return this.post(`/remove_tender/${tenderId}`)
  }

  setLastUsedTender (tenderId) {
    return this.post(`/set_last_used_tender/${tenderId}`)
  }

  getRoutingNumber (number) {
    return this.get(`/get_routing_number/${number}`)
  }

  claimTender (tenderId, oldUserId, disclosureText) {
    const decodedJwt = jwtDecode(this.jwt)
    return this.post(`/claim_tender/${tenderId}`, {
      'old_user_id': oldUserId,
      'new_user_id': decodedJwt.user_id,
      'card_on_file_disclosure': disclosureText,
    })
  }

  createPayPalSetupToken (data) {
    return this.post('/create_paypal_setup_token', data)
  }

  savePayPalTender (data) {
    return this.post('/tenders/paypal', data)
  }

  validateTender (tenderId) {
    return this.post(`/tenders/${tenderId}/validate`)
  }

  validateApplePayMerchant (targetDomain, merchantDisplayName) {
    return this.post(
      '/validate_apple_pay_merchant',
      { 'target_domain': targetDomain, 'merchant_display_name': merchantDisplayName },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }

  validateGooglePayDomain (targetDomain) {
    return this.post(
      '/validate_google_pay_domain',
      { 'target_domain': targetDomain },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }
}
