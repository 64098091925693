/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calculator-receipt': {
    width: 16,
    height: 16,
    viewBox: '1944 1270 24 24',
    data: '<g transform="translate(1944.5 1270.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><rect pid="0" width="16" height="23" rx="1"/><path pid="1" d="M3 2h10v7H3zM16 2h6a1 1 0 011 1v14a1 1 0 01-1 1h-6zM20 2v16M2 12h2v2M7 12h2v2M12 12h2v2M2 18h2v2M7 18h2v2M12 18h2v2"/></g>'
  }
})
