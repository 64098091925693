/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'magnifying-glass-minus': {
    width: 16,
    height: 16,
    viewBox: '1844 1120 24.207 24.207',
    data: '<g transform="translate(1844.5 1120.5)" _fill="none" _stroke="#666" stroke-linecap="round"><circle pid="0" cx="8" cy="8" r="8" stroke-linejoin="round"/><path pid="1" stroke-linejoin="round" d="M23 23l-9-9"/><path pid="2" stroke-miterlimit="10" d="M12 8H4"/></g>'
  }
})
