/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upload': {
    width: 16,
    height: 16,
    viewBox: '1844.5 1019.793 23 24.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1851 1025.5l5-5M1861 1025.5l-5-5M1867 1037.5v5a1.008 1.008 0 01-1 1h-20.022a.989.989 0 01-.978-1v-5M1856 1039.5v-19"/></g>'
  }
})
