/* eslint-disable-next-line no-restricted-imports -- This line is the only place
 * we should be importing from the global Sentry package in this package.
 * Everywhere else we should use the sentryException from this file.
 */
import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // login-vue-prod & -nonprod projects
    prod: 'https://74083be2e5cb4e31865a2d1ce6582c26@o168195.ingest.sentry.io/5937682',
    nonprod: 'https://63727d2d4a8c44828497f5d9370521be@o168195.ingest.sentry.io/5937666',
  },
})
