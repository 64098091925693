/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hamburger-menu': {
    width: 16,
    height: 16,
    viewBox: '1444 1073.5 24 17',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1444.5 1074h23M1444.5 1090h23M1444.5 1082h23"/></g>'
  }
})
