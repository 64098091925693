/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'presentation': {
    width: 16,
    height: 16,
    viewBox: '1994 1271 24 22.203',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1998.5 1284.5l4-5 5 4 6-9M1994.5 1271.5h23"/><path pid="1" d="M1995.5 1271.5h21v15a1 1 0 01-1 1h-19a1 1 0 01-1-1zM2004.5 1287.5l-4 5M2011.5 1292.5l-4-5"/></g>'
  }
})
