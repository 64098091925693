/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shuffle': {
    width: 16,
    height: 16,
    viewBox: '1744 1023.293 24.207 17.414',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1764.5 1024l3 3M1764.5 1030l3-3M1764.5 1034l3 3M1764.5 1040l3-3M1744.5 1027c11 0 12 10 23 10M1767.5 1027a14.877 14.877 0 00-9.84 3.64M1754.51 1033.22a14.963 14.963 0 01-10.01 3.78"/></g>'
  }
})
