<template>
  <b-button
    class="hamburger p-1"
    :aria-label="label || $t('menu')"
    :variant="`outline-${variant}`"
    :class="{active: value}"
    @click.prevent="$emit('input', !value)"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="d-flex align-items-center">
      <div class="hamburger-icon p-0 d-block">
        <span
          v-for="(_, i) in Array(3)"
          :key="`hamburger-${_uid}-${i}`"
          class="d-block w-100"
          :class="{
            active: value,
            [`bg-${variant}`]: !value,
            'bg-white': value || hover,
          }"
        />
      </div>
      <div
        v-if="label"
        class="hamburger-label ml-1"
      >
        {{ label }}
      </div>
    </div>
  </b-button>
</template>

<script>
export default {
  emits: ['input'],
  data: () => ({
    hover: false,
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary-link',
    },
    label: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.hamburger {
  &-label {
    font-size: 1rem;
  }

  &-icon {
    width: 1.5rem;

    span {
      height: .125rem;
      margin: .25rem 0;
      transition: transform 0.2s, opacity 0.2s, background-color 0.2s;

      &.active {
        opacity: 0;

        &:first-child {
          opacity: 1;
          transform: translate(0, .375rem) rotate(-45deg);
        }

        &:last-child {
          opacity: 1;
          transform: translate(0, -.375rem) rotate(45deg);
        }
      }
    }
  }
}
</style>
