<template>
  <div>
    <GovHubNavBar
      v-model="topNavActive"
      :brand="brand"
      :routes="routes"
      :should-allow-redirect="shouldAllowRedirect"
      :is-multilingual="isMultilingual"
      :use-login="useLogin"
      :logged-in="loggedIn"
      :logout-redirect-url="logoutRedirectUrl"
      :dropdown-items="dropdownItems"
      @set-locale="$emit('set-locale', $event)"
      @redirect-click="$emit('redirect-click', $event)"
      @log-in="$emit('log-in', $event)"
      @log-out="$emit('log-out', $event)"
    >

      <template #brand="{brand: slotBrand}">
        <slot
          name="brand"
          :brand="slotBrand"
        />
      </template>

      <template #tools="{showLoginDisabledModal}">
        <slot
          name="tools"
          :show-login-disabled-modal="showLoginDisabledModal"
        />
      </template>

    </GovHubNavBar>

    <GovHubNavMenu
      v-if="routes"
      v-model="topNavActive"
      :routes="routes"
      :should-allow-redirect="shouldAllowRedirect"
      :is-multilingual="isMultilingual"
      @set-locale="$emit('set-locale', $event)"
      @redirect-click="$emit('redirect-click', $event)"
    />
  </div>
</template>

<script>
import GovHubNavBar from './GovHubNavBar.vue'
import GovHubNavMenu from './GovHubNavMenu.vue'

export default {
  emits: ['set-locale', 'redirect-click', 'log-in', 'log-out'],
  components: {
    GovHubNavBar,
    GovHubNavMenu,
  },

  props: {
    brand: {
      type: Object,
      validator: brand => typeof brand === 'object',
      // Intentional
      default: () => undefined,
    },
    routes: {
      type: Array,
      default: undefined,
    },
    shouldAllowRedirect: {
      type: Function,
      default: () => true,
    },
    isMultilingual: {
      type: Boolean,
      default: true,
    },
    useLogin: {
      type: Boolean,
      default: true,
    },
    loggedIn: {
      type: Boolean,
      required: true,
    },
    logoutRedirectUrl: {
      type: String,
      default: null,
    },
    dropdownItems: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    topNavActive: false,
  }),
}

</script>

<style lang="scss" scoped>
</style>
