/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'film': {
    width: 24.5,
    height: 24.5,
    viewBox: '0 0 24.5 24.5',
    data: '<g transform="translate(.25 .25)" data-name="Layer 2"><circle pid="0" data-name="Ellipse 1529" cx="11.5" cy="11.5" r="11.5" transform="translate(.5 .5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="1" data-name="Path 6832" d="M11.13 16.25l-1.3 2.26a1 1 0 00.5 1.43 4.69 4.69 0 003.34 0 1 1 0 00.5-1.43l-1.3-2.26a1 1 0 00-1.74 0z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="2" data-name="Path 6833" d="M12.87 7.75l1.3-2.26a1 1 0 00-.5-1.43 4.69 4.69 0 00-3.34 0 1 1 0 00-.5 1.43l1.3 2.26a1 1 0 001.74 0z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="3" data-name="Path 6834" d="M16.25 12.87l2.26 1.3a1 1 0 001.43-.5 4.69 4.69 0 000-3.34 1 1 0 00-1.43-.5l-2.26 1.3a1 1 0 000 1.74z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="4" data-name="Path 6835" d="M7.75 11.13l-2.26-1.3a1 1 0 00-1.43.5 4.69 4.69 0 000 3.34 1 1 0 001.43.5l2.26-1.3a1 1 0 000-1.74z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><path pid="5" data-name="Line 5323" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.5 23.5h-12"/><circle pid="6" data-name="Ellipse 1530" cx="1" cy="1" r="1" transform="translate(11 11)" _fill="#666"/></g>'
  }
})
