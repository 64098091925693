/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shopping-cart-plus': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M1.735 1.735h3.918l1.852 14.811c.058.49.48.862.97.862H19.66a.972.972 0 00.921-.646l1.205-3.302M14.47 6.633H6.632M19.367 24.265a1.96 1.96 0 100-3.918 1.96 1.96 0 000 3.918zM8.592 24.265a1.96 1.96 0 100-3.918 1.96 1.96 0 000 3.918zM21.326 3.694V9.57M24.265 6.633h-5.877" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
