/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'map': {
    width: 16,
    height: 16,
    viewBox: '1394 1270 23.999 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1394.5 1271.943v16.939a1 1 0 00.553.894l6.968 3.484a1 1 0 00.943-.026l6.071-3.469a1 1 0 01.943-.026l6.074 3.037a1 1 0 001.447-.894v-17.689a1 1 0 00-.649-.936l-6.974-2.616a1 1 0 00-.745.017l-6.262 2.684a1 1 0 01-.745.017l-6.273-2.352a1 1 0 00-1.351.936zM1402.5 1293.5v-20M1409.5 1289.5v-19"/></g>'
  }
})
