/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'leaf': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.7 19.8s4-3.9 4.1-5.5c.1-1.9.1-5.7 2.1-6.2.9-.5.8 3.4.9 2.6.2-1.9 1.9-6 3.8-5.7.9.4-.3 3.3.6 3 1.8-.7 1.8-4.2 4.1-4 1.3.4 0 1.8.7 1.5 2.9-1.2 2.4-2 3.7-1.8 1.3.5.8 2.4-1.9 4.9-.9.8 1.9-.2 2.3.6.7 1.8-2.4 3.2-6.2 3.7-1.6.2 3 1.4 2.4 2.7-1.4 2.4-5.4-.5-5.8.2-.5.8 2.5 1.3 1.8 2.5-1.3 1.4-4.7-.1-7-1.3-1.5-.8-5.4 4.2-5.4 4.2l-.2-1.4h0z" _fill="none" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
