import { helpers } from '@vuelidate/validators'
import { containsCcnLengthNumber, isCardNumber } from './cards.js'

export const currency = helpers.regex(/^-?(0|[1-9]\d{0,})(\.\d{1,2})?$/)

// You cannot use required() for checkboxes because Vuelidate treats `false` as
// valid. Instead we explicitly check for `true`.
export const checked = (value) => value === true

export const nonCard = (value) => !isCardNumber(value)

export const noLongNumbers = (value) => !containsCcnLengthNumber(value)

// This validation basically matches the PEx validator (PSC-975). It may not
// refuse everything that the schep back end refuses (since that uses
// google/libphonenumber, which is too heavy to include in our JS bundle; see
// PSC-4039).
export const validPhone = (value) => {
  const digitsOnly = value.replace(/\D/g, '')

  // Contains 7-15 digits
  if (!/^\d{7,15}$/.test(digitsOnly)) return false

  // First digit cannot be 0
  if (digitsOnly.startsWith('0')) return false

  // Contains only allowed characters
  if (/[^+\d() -]/.test(value)) return false

  // If a plus sign is present, it must be the first character
  if (value.lastIndexOf('+') > 0) return false

  // If this is not an international number (does not have a plus sign at the
  // beginning), it must be at least 10 digits. This is what PEx does. Plus
  // signs replace the International Call Prefix.
  if (!value.trim().startsWith('+') && digitsOnly.length < 10) return false

  return true
}

// Tests HTML strings for how many newlines are displayed
export const maxLines = (value) => {
  // Count the lines in the conent
  const match = value.match(/(<(?:div|p).*?>)/gi)
  return !match || match.length < 8
}

export const notOnlyDigitsMoreThan = (maxDigits) => (value) => {
  if (!value) return true
  const trimmed = value.replace(/\s/g, '')
  return trimmed.length <= maxDigits || !/^\d+$/.test(trimmed)
}

export const lastNameRequired = (value) => {
  if (!value) {
    return true
  }

  // We require a space between non-space characters
  // to ensure that the user has entered a first and
  // last name
  if (/\S\s+\S/.exec(value)) {
    return true
  }

  return false
}

export const validUrl = (value) => {
  if (!value) {
    return true
  }

  return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(value)
}

export const secureUrl = (value) => {
  if (!value) {
    return true
  }

  return !/^http:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(value)
}
