/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'briefcase': {
    width: 16,
    height: 16,
    viewBox: '1694.5 1270.5 23 23',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1702 1275v-3a1 1 0 011-1h6a1 1 0 011 1v3M1703 1285h-8v-9a1 1 0 011-1h20a1 1 0 011 1v9h-8M1716 1288v4a1 1 0 01-1 1h-18a1 1 0 01-1-1v-4"/><path pid="1" d="M1703 1283h6v4h-6z"/></g>'
  }
})
