/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'folder': {
    width: 16,
    height: 16,
    viewBox: '1394 1121 24 22',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1416.5 1142.5h-21a1 1 0 01-1-1v-19a1 1 0 011-1h5.623a1 1 0 01.66.249l2.844 2.5a1 1 0 00.66.249h11.213a1 1 0 011 1v16a1 1 0 01-1 1zM1394.5 1129.5h23"/></g>'
  }
})
