/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chat': {
    width: 16,
    height: 16,
    viewBox: '1394 1221.5 24 21',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1411.5 1222h-16a1 1 0 00-1 1v11a1 1 0 001 1h3v5l7-5h6a1 1 0 001-1v-11a1 1 0 00-1-1z"/><path pid="1" d="M1406.5 1238h2l5 4v-4h3a1 1 0 001-1v-11a1 1 0 00-1-1h-1"/></g>'
  }
})
