/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exclamation-red-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle cx="8" cy="8" r="8" fill="#fff"/><path fill="#BE2C37" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 12.245a.653.653 0 1 0 0-1.306.653.653 0 0 0 0 1.306ZM8 8.986l-.607-4.865a.327.327 0 0 1 .32-.366h.568a.326.326 0 0 1 .326.366L8 8.986Z"/>'
  }
})
