/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'star-of-life': {
    width: 24.5,
    height: 23.518,
    viewBox: '0 0 24.5 23.518',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 5844" d="M23.75 9.66l-3.932-6.81-3.636 2.1V.75H8.318v4.2l-3.636-2.1L.75 9.66l3.636 2.1-3.636 2.1 3.932 6.81 3.636-2.1v4.2h7.864v-4.2l3.636 2.1 3.932-6.81-3.636-2.1z"/><g data-name="Group 8144"><path pid="1" data-name="Path 5845" d="M13.012 6.474a.9.9 0 11-.956-.845.9.9 0 01.956.845z"/><path pid="2" data-name="Line 4786" d="M12.111 19.463V7.432"/><g data-name="Group 8143"><g data-name="Group 8142"><path pid="3" data-name="Path 5846" d="M13.014 18.862c-.665 0-2.106 0-2.106-1.053 0-.491.539-.889 1.2-.889"/><path pid="4" data-name="Path 5847" d="M12.111 14.698c.831 0 1.5.5 1.5 1.111s-.673 1.111-1.5 1.111"/><path pid="5" data-name="Path 5848" d="M12.111 14.698c-1 0-1.8-.6-1.8-1.334s.808-1.333 1.8-1.333"/><path pid="6" data-name="Path 5849" d="M11.209 8.919c1.5 0 3.008.293 3.008 1.556 0 .859-.943 1.556-2.106 1.556"/></g><path pid="7" data-name="Path 5850" d="M11.209 8.919a.593.593 0 01-.6.585 7.064 7.064 0 01-1.5-.585 7.065 7.065 0 011.5-.585.593.593 0 01.6.585z"/></g></g></g>'
  }
})
