/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'loop-circular': {
    width: 16,
    height: 16,
    viewBox: '1494 1019.985 24 24.03',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1494.5 1032a11.5 11.5 0 0122-4.69M1516.5 1022.5v5M1511.5 1027.5h5"/><g><path pid="1" d="M1495.5 1036.69a11.5 11.5 0 0022-4.69M1495.5 1041.5v-5M1500.5 1036.5h-5"/></g></g>'
  }
})
