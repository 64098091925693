/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '1544 1073.5 24 17',
    data: '<path pid="0" d="M1544.5 1082l9 8 14-16" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
