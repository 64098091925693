/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'login-icon-guest': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M11.286 6.518a2.286 2.286 0 01-2.712 2.248 2.482 2.482 0 01-1.86-2.36v-.69a2.482 2.482 0 011.86-2.36 2.286 2.286 0 012.712 2.248v.914z" _stroke="#0077B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M9 16.51A7.51 7.51 0 109 1.49a7.51 7.51 0 000 15.02z" _stroke="#0077B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M3.88 14.492A5.566 5.566 0 019 11.286a5.566 5.566 0 015.12 3.206" _stroke="#0077B3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
