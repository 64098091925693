/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard': {
    width: 16,
    height: 16,
    viewBox: '1944 1170 24 24',
    data: '<g transform="translate(1944.5 1170.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="11.5" cy="11.5" r="11.5"/><path pid="1" d="M2.5 11.5a9 9 0 1118 0"/><g transform="translate(-.5 -.5)"><path pid="2" d="M12 7.5v5"/><circle pid="3" cx="1.5" cy="1.5" r="1.5" transform="translate(10.5 12.5)"/></g></g>'
  }
})
