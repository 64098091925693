/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-shopping-cart': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g data-name="Artwork 62" transform="translate(11 11)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" _stroke="#666" _fill="none"><path pid="0" data-name="Path 1279" d="M2.609 2.609H12L10.038 8a.522.522 0 01-.49.343H3.591a.522.522 0 01-.518-.457L2.087 0H0"/><circle pid="1" data-name="Ellipse 383" cx="1.043" cy="1.043" r="1.043" transform="translate(8.348 9.913)"/><circle pid="2" data-name="Ellipse 384" cx="1.043" cy="1.043" r="1.043" transform="translate(2.609 9.913)"/></g><g data-name="Ellipse 419" _stroke="#666" _fill="none"><circle pid="3" cx="17" cy="17" r="17" _stroke="none"/><circle pid="4" cx="17" cy="17" r="16.5"/></g>'
  }
})
