import imgAmex from './assets/images/card-brands/amex.png'
import imgDiscover from './assets/images/card-brands/discover.png'
import imgMastercard from './assets/images/card-brands/mastercard.png'
import imgVisa from './assets/images/card-brands/visa.png'

import cvvDefaultEn from './assets/images/cvv-default.png'
import cvvDefaultEs from './assets/images/cvv-default-es.png'
import cvvAmexEn from './assets/images/cvv-amex.png'
import cvvAmexEs from './assets/images/cvv-amex-es.png'

// Maps DataVault card brand hints (lowercase) to image names
// in assets/images/card-brands/
const brandImages = {
  'american express': imgAmex,
  'amex': imgAmex,
  'discover card': imgDiscover,
  'discover': imgDiscover,
  'mastercard': imgMastercard,
  'visa': imgVisa,

  // DV returns 'Discover Card' for Diners Club, JCB, and UnionPay cards.
  // DV can also return 'Unknown' for things like some debit cards.
}

// Converts the passed card brand string into the PEx standard format (which is
// also the format which TaxSys expects in API requests). If the passed brand is
// completely unrecognizable it will be returned without modification.
export function normalizeCardBrand (brandString) {
  let cardBrand = ''
  if (brandString) {
    brandString = brandString.trim()
    cardBrand = brandString.toLowerCase()
  }

  return cardBrand === 'visa' ? 'VISA'
    : cardBrand === 'mastercard' ? 'MasterCard'
    : (cardBrand === 'discover' ||
       cardBrand === 'discover card') ? 'Discover Card'
    : (cardBrand === 'amex' ||
       cardBrand === 'american express') ? 'American Express'
    : brandString || 'Unknown'
}

export function getCvvImage (brand, locale) {
  if (!brand) {
    brand = ''
  }

  brand = brand.toLowerCase()
  if (brand === 'amex' || brand === 'american express') {
    // These are translated now, so this is a key in the i18n lexicon
    if (locale === 'es') return cvvAmexEs
    return cvvAmexEn
  }
  else {
    // picture of a Mastercard - shows 3 digits on the back
    if (locale === 'es') return cvvDefaultEs
    return cvvDefaultEn
  }
}

export function getCvvAltText (brand, component) {
  if (!brand) {
    brand = ''
  }

  brand = brand.toLowerCase()
  if (brand === 'american express') {
    brand = 'amex'
  }

  const brandKey = `cvv-image.alt-text.${brand}`

  return component.$te(brandKey) ? component.$t(brandKey) : component.$t('cvv-image.alt-text.default')
}

export function getBrandImage (brand) {
  if (!brand) return
  return brandImages[brand.toLowerCase()]
}

export default getBrandImage
