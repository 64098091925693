<template>
  <div>
    <h4 class="mb-2">{{ $t('contact.header') }}</h4>
    <p>{{ $t('contact.description') }}</p>

    <Alert
      id="error_feedback"
      :show="showError"
      variant="danger"
      icon="x-circle"
      icon-color="#63171d"
      dismissible
      @dismissed="showError = false"
    >{{ error }}</Alert>

    <Alert
      id="success_feedback"
      :show="showMessage"
      dismissible
      @dismissed="showMessage = false"
    >{{ message }}</Alert>

    <b-form
      class="contact-form"
      @submit.prevent="submit"
    >
      <b-form-group
        :label="$t('topic.label')"
        :label-for="$t('topic.label')"
        label-cols-md="3"
        horizontal
        breakpoint="md"
        class="position-relative required mb-2"
        @blur="v$.topic.$touch()"
      >
        <b-form-select
          :id="$t('topic.label')"
          v-model="topic"
          :state="!v$.topic.$error"
          :options="translatedTopicOptions"
          class="hide-style text-truncate"
          name="Topic"
          @input="v$.topic.$reset()"
          @blur="v$.topic.$touch()"
        />
        <ValidationErrors
          :validator="v$.topic"
          :errors="{ required: $t('topic.required'), }"
        />
      </b-form-group>

      <b-form-group
        :label="$t('comments.label')"
        :label-for="$t('comments.label')"
        label-cols-md="3"
        horizontal
        class="position-relative required mb-2 validation-errors-target"
        breakpoint="md"
      >
        <b-form-textarea
          :id="$t('comments.label')"
          v-model.trim="comments"
          :state="!v$.comments.$error"
          :rows="3"
          :max-rows="3"
          :placeholder="$t('comments.placeholder')"
          name="Comments"
          @input="v$.comments.$reset()"
          @blur="v$.comments.$touch()"
        />
        <ValidationErrors
          :validator="v$.comments"
          :errors="{
            required: $t('comments.required'),
            maxLength: $t('comments.maxlength'),
            noCcnLengthNumber: $t('comments.noSensitiveData'), }"
        />
      </b-form-group>

      <br>

      <b-form-group
        :label="$t('first_name.label')"
        label-for="first_name"
        label-cols-md="3"
        horizontal
        class="position-relative required mb-2"
        breakpoint="md"
      >
        <b-form-input
          id="first_name"
          v-model.trim="firstName"
          :state="!v$.firstName.$error"
          name="first_name"
          autocomplete="given-name"
          maxlength="100"
          @input="v$.firstName.$reset()"
          @blur="v$.firstName.$touch()"
        />
        <ValidationErrors
          :validator="v$.firstName"
          :errors="{
            required: $t('first_name.required'),
            maxLength: $t('first_name.maxlength'),
            notCardNumber: $t('comments.noSensitiveData'), }"
        />
      </b-form-group>

      <b-form-group
        :label="$t('last_name.label')"
        label-for="last_name"
        label-cols-md="3"
        horizontal
        class="position-relative required mb-2"
        breakpoint="md"
      >
        <b-form-input
          id="last_name"
          v-model.trim="lastName"
          :state="!v$.lastName.$error"
          name="last_name"
          autocomplete="family-name"
          maxlength="100"
          @input="v$.lastName.$reset()"
          @blur="v$.lastName.$touch()"
        />
        <ValidationErrors
          :validator="v$.lastName"
          :errors="{
            required: $t('last_name.required'),
            maxLength: $t('first_name.maxlength'),
            notCardNumber: $t('comments.noSensitiveData'), }"
        />
      </b-form-group>

      <b-form-group
        :label="$t('email.label')"
        :label-for="$t('email.label')"
        label-cols-md="3"
        horizontal
        class="position-relative required mb-2"
        breakpoint="md"
      >
        <b-form-input
          :id="$t('email.label')"
          v-model.trim="email"
          :state="!v$.email.$error"
          name="Email"
          type="email"
          autocomplete="email"
          maxlength="100"
          @input="v$.email.$reset()"
          @blur="v$.email.$touch()"
        />
        <ValidationErrors
          :validator="v$.email"
          :errors="{
            required: $t('email.required'),
            maxLength: $t('email.maxlength'),
            email: $t('email.invalid'),
            notCardNumber: $t('comments.noSensitiveData'), }"
        />
      </b-form-group>

      <b-form-group
        :label="$t('phone.label')"
        :label-for="$t('phone.label')"
        label-cols-md="3"
        horizontal
        breakpoint="md"
        class="position-relative mb-2"
      >
        <b-form-input
          :id="$t('phone.label')"
          v-model.trim="phoneNumber"
          :state="!v$.phoneNumber.$error"
          name="phone_number"
          type="tel"
          maxlength="30"
          @input="v$.phoneNumber.$reset()"
          @blur="v$.phoneNumber.$touch()"
        />
        <ValidationErrors
          :validator="v$.phoneNumber"
          :errors="{
            maxLength: $t('phone.maxlength'),
            notCardNumber: $t('comments.noSensitiveData'),
            validPhone: $t('extra_fields.phone.invalid'), }"
        />
      </b-form-group>

      <div v-if="topic !== ''">
        <b-form-group
          v-for="(field, index) in customFields"
          :key="field.key"
          :label="field.customLabel"
          :label-for="field.customLabel"
          label-cols-md="3"
          horizontal
          breakpoint="md"
          class="position-relative mb-2"
          :class="{ required: field.required}"
        >
          <b-form-input
            :id="field.key"
            v-model.trim="customFieldInputs[index].value"
            :state="!v$.customFieldInputs.$each.$response.$data[index].value.$error"
            :placeholder="field.customHint"
            name="custom_fields_input"
            maxlength="100"
            @input="v$.customFieldInputs.$each.$response.$data[index].value.$reset"
            @blur="v$.customFieldInputs.$each.$response.$data[index].value.$touch()"
          />
          <ValidationErrors
            :validator="v$.customFieldInputs.$each.$response.$data[index].value"
            :errors="{
              required: $t('custom_field.required'),
              maxLength: $t('reference.maxlength'),
              notCardNumber: $t('comments.noSensitiveData'), }"
          />
        </b-form-group>
      </div>

      <b-form-group
        label=""
        label-cols-md="3"
        horizontal
        class="mb-2"
        breakpoint="md"
      >
        {{ $t("required.label") }}
      </b-form-group>

      <div
        v-dompurify-html="$t('contact.disclaimer', { clientName: contactEntity($route.meta.displayType) })"
        class="my-4"
      />

      <div class="d-flex flex-row justify-content-end">
        <b-button
          :aria-label="$t('clear.label')"
          class="mr-2"
          variant="outline-primary"
          @click="clearForm()"
        >
          {{ $t('clear.label') }}
        </b-button>
        <ProgressButton
          :disabled="v$.$dirty && v$.$invalid"
          :waiting="$wait.is('contacting us')"
          :aria-label="$t('submit.label')"
          variant="primary"
          type="submit"
        >
          {{ $t('submit.label') }}
        </ProgressButton>
      </div>

      <i18n-t
        v-if="contactPhone"
        keypath="contact.phone"
        tag="div"
        class="my-4"
        scope="global"
      >
        <a :href="`tel:${contactPhone}`">{{ contactPhone }}</a>
      </i18n-t>
    </b-form>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength, requiredIf, helpers } from '@vuelidate/validators'
import { validPhone } from '@grantstreet/psc-js/utils/validators.js'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import cloneDeep from 'lodash/cloneDeep.js'
import { mapGetters } from 'vuex'
import { containsCcnLengthNumber, isCardNumber } from '@grantstreet/psc-js/utils/cards.js'
import { getPayableSource } from '@grantstreet/payables'
import { mapConfigState } from '@grantstreet/psc-config'

const notCardNumber = value => !isCardNumber(value)
const noCcnLengthNumber = value => !containsCcnLengthNumber(value)

export default {
  components: {
    ProgressButton,
    ValidationErrors,
    Alert,
  },

  mixins: [ scrollToMixin ],

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    defaultContactUsInfo: {
      type: Object,
      default: null,
      notes: "This pull the user's profile info if they're logged in to use for autofilling contact forms.",
    },
  },

  data () {
    return {
      showMessage: false,
      showError: false,
      message: '',
      error: '',
      topic: '',
      comments: '',
      customFieldInputs: [],
      firstNameInput: null,
      lastNameInput: null,
      emailInput: null,
      phoneNumberInput: null,
    }
  },

  computed: {
    translatedTopicOptions () {
      // Deep copy the array so we don't modify the original translation keys
      const options = cloneDeep(this.topicOptions)

      for (const option of options) {
        if (option.text) {
          option.text = option.text[this.$i18n.locale] || ''
        }

        if (option.customFields) {
          for (const field of option.customFields) {
            // Include English translation of custom label as field key
            field.key = field.customLabel.en
            field.customLabel = field.customLabel[this.$i18n.locale] || ''
            field.customHint = field.customHint[this.$i18n.locale] || ''
          }
        }
      }

      return options
    },

    firstTopicValue () {
      const curRoute = this.$route
      if (curRoute) {
        for (const top of this.translatedTopicOptions) {
          // If any of our topics have the current page defined as their
          // default, select them as our default topic
          if (top.defaultOnPage?.pageRoute === curRoute.name) {
            return top.value
          }
        }
      }
      return this.translatedTopicOptions[0]
        ? this.translatedTopicOptions[0].value
        : ''
    },

    contactPhone () {
      const payableSource = getPayableSource(this.$route.meta.displayType)

      return (payableSource?.displayContactPhone && payableSource?.contactPhone)
    },

    // For firstName, lastName, email, and phoneNumber:
    // 1. Check if the user has input a value and keep that value if so
    // 2. Check if the user is logged in and a value can be pulled from there (if
    // it isn't blank for the user's profile)
    // 3. Return a blank string
    firstName: {
      get () {
        if (this.firstNameInput !== null) {
          return this.firstNameInput
        }
        else if (this.defaultContactUsInfo !== null) {
          return this.defaultContactUsInfo.givenName
        }

        return ''
      },

      set (val) {
        this.firstNameInput = val
      },
    },

    lastName: {
      get () {
        if (this.lastNameInput !== null) {
          return this.lastNameInput
        }
        else if (this.defaultContactUsInfo !== null) {
          return this.defaultContactUsInfo.familyName
        }

        return ''
      },

      set (val) {
        this.lastNameInput = val
      },
    },

    email: {
      get () {
        if (this.emailInput !== null) {
          return this.emailInput
        }
        else if (this.defaultContactUsInfo !== null) {
          return this.defaultContactUsInfo.email
        }

        return ''
      },

      set (val) {
        this.emailInput = val
      },
    },

    phoneNumber: {
      get () {
        if (this.phoneNumberInput !== null) {
          return this.phoneNumberInput
        }
        else if (this.defaultContactUsInfo !== null) {
          return this.defaultContactUsInfo.phone
        }

        return ''
      },

      set (val) {
        this.phoneNumberInput = val
      },
    },

    customFields () {
      return this.translatedTopicOptions.find(({ value }) => value === this.topic)?.customFields || []
    },

    ...mapGetters('PayHub', [
      'contactEntity',
    ]),

    ...mapGetters('Help', [
      'topicOptions',
    ]),

    ...mapConfigState(['config']),
  },

  mounted () {
    this.topic = this.firstTopicValue
  },

  validations: {
    comments: {
      required,
      maxLength: maxLength(2000),
      noCcnLengthNumber,
    },
    email: {
      required,
      maxLength: maxLength(100),
      email,
      notCardNumber,
    },
    firstName: {
      required,
      maxLength: maxLength(100),
      notCardNumber,
    },
    lastName: {
      required,
      maxLength: maxLength(100),
      notCardNumber,
    },
    phoneNumber: {
      maxLength: maxLength(30),
      notCardNumber,
      validPhone: function (val) {
        if (!val) return true
        return validPhone(val)
      },
    },
    customFieldInputs: {
      $each: helpers.forEach({
        value: {
          maxLength: maxLength(100),
          notCardNumber,
          required: requiredIf(function ({ field }) {
            return this.customFields.find(({ key }) => key === field)?.required
          }),
        },
      }),
    },
    topic: {
      required,
    },
  },

  watch: {
    customFields () {
      this.customFieldInputs = this.customFields.map(({ key }) => ({
        field: key,
        value: '',
      }))
    },
  },

  methods: {
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    mounted () {
      this.v$.$reset()
    },

    async submit () {
      if (!this.validate()) return

      // Prep custom field inputs for submission
      const filledFields = this.customFieldInputs.filter(({ value }) => value !== '')
      const fieldInputs = filledFields.reduce((obj, fieldInput) => ({ ...obj, [fieldInput.field]: fieldInput.value }), {})

      this.$store.dispatch('wait/start', 'contacting us')

      try {
        const api = this.$store.getters['API/contact']
        await api.contact({
          client: this.config.client,
          site: this.config.site,
          topic: this.topic,
          body: this.comments,
          // eslint-disable-next-line camelcase
          first_name: this.firstName,
          // eslint-disable-next-line camelcase
          last_name: this.lastName,
          email: this.email,
          // eslint-disable-next-line camelcase
          phone_number: this.phoneNumber,
          url: window.location.href,
          // eslint-disable-next-line camelcase
          custom_fields: fieldInputs,
        })

        this.message = this.$t('messaging.success')
        this.showMessage = true
        this.clearForm()
        this.scrollTo('#success_feedback')
      }
      catch (error) {
        console.error('Error sending email:', error)
        // Show error with contact phone number only if there is a
        // contact phone number that can be shown
        if (this.contactPhone) {
          this.error = this.$t('messaging.api-error.default', {
            'entity': this.contactEntity(this.$route.meta.displayType),
            'phone': this.contactPhone,
          })
        }
        else {
          this.error = this.$t('messaging.api-error.no_phone')
        }

        this.showError = true
        this.scrollTo('#error_feedback')
      }
      finally {
        this.$store.dispatch('wait/end', 'contacting us')
      }
    },

    clearForm () {
      this.v$.$reset()
      // Do we want to preselect the first question?
      this.topic = this.firstTopicValue
      this.comments = ''
      this.phone = ''
      this.phoneNumber = ''
      this.customFieldInputs = []
    },
  },
}
</script>

<style lang="scss" scoped>

.hide-style{
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  height:3rem;
}
.hide-style::-ms-expand {
    display: none;
}

.contact-form{
  @include media-breakpoint-up(md) {
    max-width: 33rem !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 35rem !important;
  }
}

</style>
