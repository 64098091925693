/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mail-open': {
    width: 16,
    height: 16,
    viewBox: '1894 1170.066 24 23.849',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1917.5 1177.415v15a1 1 0 01-1 1h-21a1 1 0 01-1-1v-15"/><path pid="1" d="M1917.5 1177.415l-10.98-6.684a1 1 0 00-1.04 0l-10.98 6.684 10.884 8.518a1 1 0 001.233 0z"/></g>'
  }
})
