<template>
  <footer data-test="footer">
    <FooterBar
      :default-contact-us-info="defaultContactUsInfo"
      :class="footerBarClass"
    />
    <Copyright
      v-if="showGsgBranding"
    />
  </footer>
</template>

<script>
// In the long term, this file should probably move to something like @grantstreet/govhub-vue
// However, we don't want to jump the gun on PayHub pulling in GovHub-like code
// So it lives in PayHub for now
// <Copyright> should probably live there too (or maybe even in @grantstreet/psc-vue)
import Copyright from '../components/Copyright.vue'
import FooterBar from '@grantstreet/help-vue/src/components/FooterBar.vue'

export default {
  components: {
    FooterBar,
    Copyright,
  },

  props: {
    defaultContactUsInfo: {
      type: Object,
      default: null,
      notes: "This pull the user's profile info if they're logged in to use for autofilling contact forms.",
    },
    footerBarClass: {
      type: [String, Object, Array],
      default: '',
      notes: 'PayHub expects a certain panel margin/padding; see PSC-7644.',
    },
    showGsgBranding: {
      type: Boolean,
      default: true,
      notes: 'Shows the "Powered by Grant Street Group" text in the footer',
    },
  },
}
</script>
