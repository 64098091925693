import { baseGovHubUrl } from '@grantstreet/psc-environment'
import { joinUrl } from '@grantstreet/psc-js/utils/urls.js'
import { VuexOidcClientSettings } from 'vuex-oidc'

export type OidcSettingsOptions = {
  oidcClient: string
  baseRoute?: string
}

export default ({
  oidcClient,
  baseRoute = '',
}: OidcSettingsOptions): VuexOidcClientSettings => {
  const origin = joinUrl(window.location.origin, baseRoute)

  return {
    authority: `${process.env?.GSG_LOGIN_SERVICE || (baseGovHubUrl + '/svc/login')}/oidc`,
    clientId: oidcClient,
    redirectUri: joinUrl(origin, '/callback'),
    responseType: 'code',
    scope: 'openid email profile offline_access',

    // XXX: These two keys aren't part of the settings type accepted in V4. They
    // were added in the initial commit for this file in PSC-7090. I think we
    // should probably remove them but wasn't able to conclude that positively.
    // Not in the vuex-oidc docs:
    // https://github.com/perarnborg/vuex-oidc/wiki
    // But documented here:
    // https://github.com/IdentityModel/oidc-client-js/wiki
    filterProtocolClaims: true,
    loadUserInfo: true,

    automaticSilentRenew: true,
    automaticSilentSignin: true,
    silentRedirectUri: joinUrl(origin, '/oidc-silent-renew.html'),

    // To have the access tokens automatically refresh every 10 seconds,
    // uncomment the following line (this is 1 hr minus 10 seconds, in seconds):
    // accessTokenExpiringNotificationTimeInSeconds: '3590',

    // I don't love this cast but this version of vuex-oidc has broken types.
  } as VuexOidcClientSettings
}
