<template>
  <component
    :is="vueComponent"
    v-bind="$attrs"
    ref="userVerification"
  >
    <!-- Proxy any slots -->
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </component>
</template>

<script>
import { sentryException } from './sentry.ts'
import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin.ts'

export default {
  compatConfig: {
    // This is necessary for child-emitted events to propagate via $attrs
    // (https://github.com/vuejs/core/issues/4566#issuecomment-917997056)
    INSTANCE_LISTENERS: false,
  },

  mixins: [
    createModuleLoaderMixin({
      moduleName: 'UserVerification',
      componentRefName: 'userVerification',
      supportedComponents: {
        'UserVerificationAccordionLink': () =>
          import('./components/UserVerificationAccordionLink.vue'),
        'UserVerificationStatus': () =>
          import('./components/UserVerificationStatus.vue'),
        'UserVerificationPIN': () =>
          import('./components/UserVerificationPIN.vue'),
        'UserVerificationPINStoreWrapper': () =>
          import('./components/UserVerificationPINStoreWrapper.vue'),
      },
      exceptionLogger: sentryException,
    }),
  ],
}
</script>
