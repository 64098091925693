/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'utilities': {
    width: 16,
    height: 16,
    viewBox: '0 0 13.677 21.506',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 1291" d="M3.35 12.686v2a1.74 1.74 0 001.735 1.735h3.474a1.74 1.74 0 001.735-1.735v-2a1.561 1.561 0 01.607-1.3 6.111 6.111 0 002-5.119A6.176 6.176 0 007.518.799 6.066 6.066 0 00.75 6.873a5.735 5.735 0 002.082 4.512 1.766 1.766 0 01.518 1.301z"/><path pid="1" data-name="Path 1292" d="M7.171 20.755h-.694a1.773 1.773 0 01-1.736-1.388l-.607-2.95H9.34l-.606 2.95a1.589 1.589 0 01-1.563 1.388z"/><path pid="2" data-name="Line 58" d="M6.824 9.476v6.941"/><path pid="3" data-name="Line 59" d="M9.427 6.873L6.824 9.476"/><path pid="4" data-name="Line 60" d="M4.221 6.873l2.603 2.603"/></g>'
  }
})
