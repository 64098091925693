import { formatParams } from '@grantstreet/psc-js/utils/routing.js'

export default {
  namespaced: true,
  state: {
    router: {},
  },

  getters: {
    route: state => state.router.currentRoute,
    formattedParams: state => formatParams(state.router.currentRoute?.params),
  },

  mutations: {
    setRouter (state, router) {
      state.router = router
    },
  },
}
