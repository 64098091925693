/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location-services': {
    width: 16,
    height: 16,
    viewBox: '1343.793 1069.842 24.412 24.317',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1344.548 1070.5l23 10M1344.453 1070.573l10.095 22.927M1367.548 1080.5l-12 1-1 12"/></g>'
  }
})
