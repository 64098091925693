<template>
  <div>
    <NavSteps
      :current-step="currentStep"
    />
    <div class="gh-container">
      <h1
        data-test="page-title"
        class="gh-page-margin gh-title-margin"
      >{{ title }}</h1>
      <LoginRequiredModal
        :aria-labeledby="$t('schedpay.welcome_modal.profile_benefits_list')"
        :show="schedPayOnlyCart"
        data-test="login-required-schedpay-only-cart"
      >
        <ClientLogo class="logo ml-auto mr-auto d-block mb-4" />
        <h3 class="mb-5">{{ $t('schedpay.welcome_modal.intro') }}</h3>
        <h4>{{ $t('schedpay.welcome_modal.profile_benefits_title') }}</h4>
        <ul
          v-dompurify-html="$t('schedpay.welcome_modal.profile_benefits_list')"
          class="mb-4"
        />
      </LoginRequiredModal>

      <CheckoutWrapper
        :init-failure="initFailure"
        :is-redirect="isRedirect"
        :use-return-button="true"
      >
        <template v-if="currentStep === 'confirmation'">
          <h2>{{ $t('schedpay.creation_confirmation') }}</h2>
          <h3>{{ $t('receipt.confirmation.email') }} <span class="text-primary text-lowercase">{{ userEmail }}</span>.</h3>
          <hr>
        </template>

        <EmptyCart v-else-if="!items.length" />

        <!-- Hide the subtotal and rely on schep to display fee and totals -->
        <!-- No need to handle login from Cart since this is schep -->
        <Cart
          v-else
          hide-subtotals
        />

        <Alert
          v-if="items.length && items.length > 1"
          variant="danger"
          :dismissible="false"
        >
          {{ $t('schedpay.one_item_allowed') }}
        </Alert>
        <div
          v-else-if="items.length || currentStep === 'confirmation'"
          class="bg-white rounded-xl p-4"
        >
          <h2
            v-if="currentStep === 'confirmation'"
            class="mb-4"
          >{{ $t('checkout.schedule.summary') }}</h2>
          <h2
            v-else
            class="mb-4"
          >{{ $t('schedpay.title') }}</h2>
          <SchedPayLoader
            v-if="cachedPayable"
            component="SchedPay"
            :payable="cachedPayable"
            :hide-payable-details="currentStep !== 'confirmation'"
            default-mode-after-save
            hide-close-button
            hide-done-button
            @save="savedSchedule"
          />
        </div>
      </CheckoutWrapper>
    </div>
  </div>
</template>

<script>
import CheckoutWrapper from '../../components/CheckoutWrapper.vue'
import SchedPayLoader from '@grantstreet/schedpay-vue/src/SchedPayLoader.vue'
import Cart from '@grantstreet/cart-vue/src/components/Cart.vue'
import EmptyCart from '../../components/EmptyCart.vue'
import NavSteps from '../../components/NavSteps.vue'
import ClientLogo from '../../components/ClientLogo.vue'
import LoginRequiredModal from '../../components/LoginRequiredModal.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import { mapGetters } from 'vuex'
import { mapConfigState } from '@grantstreet/psc-config'

// Remember that this component used to share some basic logic with the other
// checkout components. If you make changes to beforeMount or some other common
// places, you should probably see if the same changes need to be made in those
// other components as well.

export default {
  components: {
    SchedPayLoader,
    Cart,
    ClientLogo,
    LoginRequiredModal,
    CheckoutWrapper,
    EmptyCart,
    Alert,
    NavSteps,
  },

  props: {
    // Was this a redirect? If so we display custom loading+error messages.
    isRedirect: {
      type: Boolean,
    },
  },

  data () {
    return {
      currentStep: 'schedule',
      userEmail: '',

      // We cache the payable in the cart because we clear the cart as soon as a
      // schedule is created but still want to display schep for the payable.
      //
      // On sites using basic auth this component's watcher loads after Cart's
      // Cart/inflateAndUpdateCart runs, so schep will never load if a value
      // isn't set here.
      cachedPayable: this.$store.getters['Cart/items']?.[0]?.payable,
      // Was there a failure initializing the cart?
      initFailure: false,
    }
  },

  computed: {
    title () {
      return this.currentStep === 'confirmation'
        ? this.$t('checkout.schedule.summary')
        : this.$t('checkout.cart.default')
    },

    schedPayOnlyCart () {
      return this.config.schedPay && this.config.schedPay.schedPayOnlyCart
    },

    ...mapGetters('Cart', [
      'cartLoadPromise',
      'items',
    ]),

    ...mapGetters('eWallet', {
      eWalletLoadPromise: 'loadPromise',
    }),

    ...mapConfigState(['config']),
  },

  watch: {
    items (newItems) {
      if (!newItems) return

      if (newItems.length === 1) {
        this.cachedPayable = newItems[0].payable
      }
    },
  },

  async mounted () {
    // Ensure E-Wallet submission is enabled
    this.$wait.end('submitting')

    // Lazy-load E-Wallet when we first reach the cart page
    try {
      await this.cartLoadPromise
      await this.eWalletLoadPromise
    }
    catch (error) {
      console.error('Could not initialize cart:', error)

      if (error.response.status === 429) {
        this.$router.replace({
          name: 'rate-limit-error',
          params: this.$route.params,
        })
      }
      else {
      // Redirect to error page (formerly Base.redirectToErrorPage)
        this.$router.replace({
          name: this.isRedirect ? 'redirect-error' : 'cart-error',
          params: this.$route.params,
        })
      }
      this.initFailure = true
    }

    this.$store.commit('Cart/setCheckoutNavSteps', [
      {
        name: 'schedule',
        title: 'schedpay.create_schedule',
        icon: 'progress-bar-shopping-cart',
      },
      {
        name: 'confirmation',
        title: 'schedpay.schedule_confirmation',
        icon: 'progress-bar-details',
      },
    ])
  },

  methods: {
    async savedSchedule (schedule) {
      this.userEmail = schedule.user.email
      this.currentStep = 'confirmation'

      await this.$nextTick()
      window.scrollTo(0, 0)

      await this.$store.dispatch('Cart/retireCart')
    },
  },
}
</script>
<style lang="scss" scoped>
  .logo {
    max-width: 20rem;
  }
</style>
