/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag-2': {
    width: 21.611,
    height: 24.221,
    viewBox: '0 0 21.611 24.221',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Path 6836" d="M20.861 9.33c-3.68 5.2-12.21.69-15.89 5.89-1.12-2.87-1.88-5.13-3-8 3.68-5.17 11.86-1.27 15.58-6.47z"/><path pid="1" data-name="Line 5329" d="M.971 5.25l7 18"/></g>'
  }
})
