<template>
  <b-modal
    v-if="useLogin && (!authFinished || !user.loggedIn)"
    :show="show"
    :visible="show"
    hide-footer
    size="md"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    static
    data-test="login-required-modal"
  >

    <span
      v-if="closable"
      class="d-flex flex-row justify-content-end align-items-end pr-3"
    >
      <a
        class="cursor-pointer"
        @click="$emit('closed')"
      >
        <svgicon
          :fill="false"
          icon="x"
        />
      </a>
    </span>

    <slot />

    <div class="d-flex justify-content-end mb-4 align-items-center">
      <b-row
        class="w-100"
        no-gutters
        align-h="end"
      >
        <b-col
          class="mt-2 d-flex align-items-center"
          cols="12"
          sm="auto"
        >
          <b-link
            v-if="returnUrl"
            data-test="return-to-county-button"
            :href="returnUrl"
            class="mr-3 btn btn-link btn-sm"
          >
            {{ $t('schedpay.welcome_modal.return_to_county_site') }}
          </b-link>
        </b-col>
        <b-col
          class="mt-2 order-sm-last"
          cols="12"
          sm="auto"
        >
          <LoginButton
            class="w-100"
            size="md"
            @show-login-disabled-modal="showLoginDisabledPrompt"
            @log-in="handleLogin($event)"
          >
            {{ $t('topnav.login') }}
          </LoginButton>
        </b-col>
        <b-col
          class="mt-2 mr-0 mr-sm-2"
          cols="12"
          sm="auto"
        >
          <b-button
            v-if="closable"
            variant="outline-primary"
            class="w-100"
            @click="$emit('closed')"
          >
            {{ $t('common.cancel') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <Alert
      v-if="loginDisabled"
      class="mt-5"
      variant="danger"
    >
      {{ $t('login_modal.disabled.description_short') }}
    </Alert>
  </b-modal>
</template>

<script>
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import LoginButton from '@grantstreet/psc-vue/components/LoginButton.vue'
import { mapGetters } from 'vuex'
import { mapConfigState, mapConfigGetters } from '@grantstreet/psc-config'
import { useGsgUser } from '@grantstreet/user'
import { useLoginHelpers } from '../login-helpers.js'

export default {
  emits: ['closed'],
  components: {
    Alert,
    LoginButton,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },

  setup: () => ({
    user: useGsgUser().user,
    handleLogin: useLoginHelpers().handleLogin,
  }),

  data () {
    return {
      authFinished: false,
      loginDisabled: false,
    }
  },

  computed: {
    returnUrl () {
      return (this.urls && this.urls.return) ||
        this.config.payHub.searchPageUrl
    },

    ...mapGetters('Cart', ['urls']),

    ...mapConfigState(['config']),
    ...mapConfigGetters(['useLogin']),
  },

  async mounted () {
    await this.$store.getters['PayHub/authPromise']
    this.authFinished = true
  },

  methods: {
    showLoginDisabledPrompt () {
      this.loginDisabled = true
    },
  },
}
</script>
