/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-outline': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<defs id="defs3865"/><g id="Ellipse_483" data-name="Ellipse 483" _stroke="#666" _fill="none"><circle pid="0" cx="17" cy="17" r="17" id="circle3867" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5" id="circle3869" _fill="none"/></g>'
  }
})
