import { onMounted, onUnmounted, ref } from 'vue'

export function useScrollAmount () {
  const scrollAmount = ref(0)
  const scrollRafHandle = ref(0)
  const loop = () => {
  // Don't update if we've scrolled to the bottom of the page. The shrinking
  // nav/logo decreases the page height and thus the amount you can scroll.
  // This can lead to some jittery situations where you've scrolled to the
  // bottom of a page but the nav hasn't finished shrinking because of
  // little to no content. When this happens, the nav shrinks, the page gets
  // smaller and forces your scrollY to a lower value which causes the nav
  // to grow because your scrollY decreased which makes the page bigger and
  // allows you to scroll some more on and on to infinity.
    if ((window.innerHeight + window.scrollY) < document.body.offsetHeight) {
      scrollAmount.value = window.scrollY
    }
    scrollRafHandle.value = requestAnimationFrame(loop)
  }

  onMounted(() => {
    scrollRafHandle.value = requestAnimationFrame(loop)
  })

  onUnmounted(() => {
    cancelAnimationFrame(scrollRafHandle.value)
  })

  return scrollAmount
}
