/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-terms': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<g data-name="Group 2436"><g data-name="Ellipse 419" _fill="none" _stroke="#666"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g></g><g data-name="Group 2441" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1472" d="M22.152 24.931L11.762 25a.654.654 0 01-.693-.693L11 12.532l3.463-3.463L22.082 9a.654.654 0 01.693.693l.139 14.545a.814.814 0 01-.762.693z"/><path pid="3" data-name="Path 1473" d="M14.463 9.069v2.771a.654.654 0 01-.693.693H11z"/><path pid="4" data-name="Line 337" d="M14.532 17.381l5.541-.069"/><path pid="5" data-name="Line 338" d="M14.532 20.844l5.541-.069"/></g>'
  }
})
