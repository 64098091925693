<template>
  <LargeError
    :class="{
      'border-top pt-6': !$route.meta.hideHeader && enableHeader,
    }"
    :type="type"
    :hide-support-link="hideSupportLink"
    :is-redirect="isRedirect"
    :show-button="showButton"
  />
</template>

<script>
import { mapState } from 'vuex'
import LargeError from '@grantstreet/govhub-vue/src/components/LargeError.vue'

export default {
  components: {
    LargeError,
  },

  props: {
    type: {
      // LargeError does more type validation
      type: String,
      default: 'notFound',
    },
    hideSupportLink: {
      type: Boolean,
      default: false,
    },
    isRedirect: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('PayHub', ['enableHeader']),
  },
}
</script>
