/**
 * Client for the address microservice
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment/environment.js'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = process.env?.GSG_ADDRESSES_SERVICE || `${baseGovHubUrl}/svc/addresses`
  }

  getAddresses () {
    return this.get('/v1/addresses')
  }

  addAddress (address) {
    return this.post('/v1/addresses', address.apiParams)
  }

  removeAddress (addressId) {
    return this.delete(`/v1/addresses/${addressId}`)
  }

  updateAddress (address) {
    return this.patch(`/v1/addresses/${address.id}`, address.apiParams)
  }

  updateLastUsedAddress (addressId) {
    return this.patch(`/v1/addresses/${addressId}`, { lastUsed: new Date() })
  }
}
