/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'reload': {
    width: 16,
    height: 16,
    viewBox: '1444.007 1019.994 24.008 24.017',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M1446.507 1020.5v5M1451.507 1025.5h-5M1446.527 1025.5a11.492 11.492 0 11-2.02 6.5"/></g>'
  }
})
