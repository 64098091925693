/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shopping-cart': {
    width: 16,
    height: 16,
    viewBox: '1994 1170 24 24',
    data: '<g transform="translate(1994.5 1170.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M5 5h18l-3.761 10.342a1 1 0 01-.94.658H6.883a1 1 0 01-.992-.876L4 0H0"/><circle pid="1" cx="2" cy="2" r="2" transform="translate(16 19)"/><circle pid="2" cx="2" cy="2" r="2" transform="translate(5 19)"/></g>'
  }
})
