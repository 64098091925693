/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar-history': {
    width: 16,
    height: 16,
    viewBox: '1744 1171 24 22',
    data: '<g transform="translate(1744.5 1171.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M10.59 19H1a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1v5.25M0 7h20M4 0v3M16 0v3"/><circle pid="1" cx="5" cy="5" r="5" transform="translate(13 11)"/><path pid="2" d="M18 13v3M20 18l-2-2"/></g>'
  }
})
