import Availability from './Availability.ts'

/*
 * The TaxSys office locations API returns "availability" in this format:
 *   {
 *     "availability": [
 *       { "date": "2022-05-09", "open": 1 },
 *       { "open": 1, "date": "2022-05-10" },
 *       { "open": 1, "date": "2022-05-11" },
 *       { "open": 1, "date": "2022-05-12" },
 *       { "open": 1, "date": "2022-05-13" },
 *       { "open": 1, "date": "2022-05-16" },
 *       { "open": 1, "date": "2022-05-17" }
 *     ],
 *     "information": null,
 *     "mapLocation": "https://www.google.com/maps/place/1515+Sarno+Rd,+Melbourne,+FL+32935/@28.1205772,-80.6448274,17z/data=!4m5!3m4!1s0x88de0fcd41382beb:0x1de8a736c8cbc6ff!8m2!3d28.1206766!4d-80.6446772",
 *     "officeHours": "9:00am - 5:00pm",
 *     "locationId": 1,
 *     "description": "Melbourne Branch",
 *     "location": "1515 Sarno Road\nMelbourne, FL 32935"
 *   }
 */

export default class Office {
  availability: Availability[]
  information: object | null
  mapLocation: string
  officeHours: string
  locationId: number
  description: string
  location: string

  constructor ({
    availability = [],
    information = null,
    mapLocation = '',
    officeHours = '',
    locationId = -1,
    description = '',
    location = '',
  }) {
    this.availability = availability.map(a => new Availability(a))
    this.information = information
    this.mapLocation = mapLocation
    this.officeHours = officeHours
    this.locationId = locationId
    this.description = description
    this.location = location

    if (this.availability.length) {
      this.availability.sort((
        a: { date: string },
        b: { date: string },
      ) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }
  }

  get isOpenToday () {
    return Boolean(this.availability?.[0]?.isOpen)
  }

  get nextBusinessDay () {
    // TaxSys only returns availability on business days, so the next business day
    // second item in the array.
    return this.availability?.[1]
  }

  get isOpenNextBusinessDay () {
    return Boolean(this.nextBusinessDay?.isOpen)
  }
}
