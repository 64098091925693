/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'media-play': {
    width: 16,
    height: 16,
    viewBox: '1894 1070 24 24',
    data: '<g transform="translate(1894.5 1070.5)" _fill="none" _stroke="#666" stroke-linecap="round"><circle pid="0" cx="11.5" cy="11.5" r="11.5" stroke-miterlimit="10"/><path pid="1" d="M17 11.5l-9-6v12z" stroke-linejoin="round"/></g>'
  }
})
