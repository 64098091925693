<template>
  <LoadingBars v-if="loading" />
  <component
    :is="component"
    v-else
    :is-redirect="isRedirect"
    :tender-validation-error="tenderValidationError"
    :tender-type-for-validation-error="tenderTypeForValidationError"
  />
</template>

<script>
import CartPage from './Checkout/CartPage.vue'
import SchedulePage from './Checkout/SchedulePage.vue'
import { mapGetters, mapState } from 'vuex'
import { sentryException } from '../sentry.js'
import { searchPayablesPath } from '@grantstreet/payables'
import queryString from 'query-string'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { mapConfigState } from '@grantstreet/psc-config'

export default {
  components: {
    LoadingBars,
  },

  data () {
    return {
      loading: true,
    }
  },

  computed: {
    component () {
      if (this.config.schedPay && this.config.schedPay.schedPayOnlyCart) {
        return SchedulePage
      }
      return CartPage
    },

    isRedirect () {
      return window.history.state.isRedirect ? window.history.state.isRedirect : false
    },

    tenderValidationError () {
      return window.history.state.tenderValidationError ? window.history.state.tenderValidationError : ''
    },

    tenderTypeForValidationError () {
      return window.history.state.tenderTypeForValidationError ? window.history.state.tenderTypeForValidationError : ''
    },

    ...mapGetters('Cart', [
      'isInCart',
      'cartLoadPromise',
    ]),

    ...mapConfigState(['config']),
    ...mapState('PayHub', ['enableHomePage']),
  },

  async created () {
    // The application may send us a list of payable paths to populate the cart
    // with. Extract list of payable paths from url.
    const allPaths = Object.values(queryString.parse(window.location.search))
    if (!allPaths.length) {
      this.loading = false
      return
    }

    await this.cartLoadPromise

    try {
      // Look up payables
      const items = (await Promise.all(allPaths.map(async path => {
        let payable
        try {
          payable = await searchPayablesPath({
            path,
            language: this.$i18n.locale,
          })
        }
        catch (error) {
          // This is probably a 404 from searching for something that isn't a
          // path PSC-15644
          console.error(error)
          return
        }
        if (this.isInCart(payable)) {
          return
        }
        return {
          path,
          amount: payable.amount,
          quantity: 1,
          details: payable,
        }
      }))).filter(item => Boolean(item))

      if (items.length) {
        // Add payables to cart
        await this.$store.dispatch('Cart/addToCart', { items })
      }
      this.loading = false
    }
    catch (error) {
      console.error(error)
      sentryException(new Error('Error while adding payables to cart via query params: ' + error))
      if (enableHomePage) {
        this.$router.push({
          name: 'home',
          params: this.$route.params,
        })
      }
    }
  },
}

</script>
