import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'

export default class MyPaymentItem {
  constructor ({
    amount,
    quantity,
    path,
    details,
    failed,
    userErrorMessage,
  }) {
    this.amount = parseNumber(amount)
    this.quantity = quantity
    this.path = path
    this.failed = failed
    this.userErrorMessage = userErrorMessage

    // TODO PSC-522 we'll need to track more of this for the receipt
    this.description = details.description
    this.displayName = details.display_name
    this.displayType = details.display_type

    // Our Sunshine vehicle-registration payables error without this
    // TODO these should really be the same schema as cart items
    this.customParameters = details.custom_parameters || {}

    this.configDisplayType = ''
  }
}
