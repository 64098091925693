/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'call': {
    width: 16,
    height: 16,
    viewBox: '1747.483 1220.002 17.043 24.001',
    data: '<path pid="0" d="M1751.002 1234.46c.02.036.042.069.062.1s.038.071.059.107c3.713 6.481 10.125 10.294 11.313 8.3l1.5-2.848a.849.849 0 00-.051-.817l-.016-.018c-.209-.409-.4-.422-1.5-1.147-.569-.428-1.175-.893-1.575-1.244a.946.946 0 00-1.261-.05l-.618.611a1.109 1.109 0 01-.8.318c-.889-.015-2.591-2.416-3.539-3.9-.268-.42-.481-.772-.6-.987s-.318-.576-.545-1.02c-.802-1.563-2.01-4.246-1.574-5.021a1.111 1.111 0 01.677-.526l.84-.224a.946.946 0 00.594-1.114c-.1-.523-.194-1.281-.276-1.988-.07-1.312.014-1.484-.232-1.872v-.018a.847.847 0 00-.68-.454l-3.217-.146c-2.323.017-2.271 7.481 1.439 13.958z" _fill="none" _stroke="#666" stroke-miterlimit="10"/>'
  }
})
