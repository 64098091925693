/**
 * Client for the Contact microservice
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    this.baseUrl = process.env?.GSG_CONTACT_SERVICE || `${baseGovHubUrl}/svc/contact`
  }

  ping () {
    return this.get('/api/v1/ping')
  }

  contact (args) {
    return this.post('/api/v1/contact', args)
  }
}
