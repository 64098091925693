export default {
  namespaced: true,

  state: {
    hint: null,
    sendEvents: true,

    highlight: {
      'ais-Highlight-highlighted': 'search-key',
    },
  },

  getters: {},

  mutations: {},

  actions: {},
}
