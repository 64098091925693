/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'airport-fees': {
    width: 16,
    height: 16,
    viewBox: '0 0 23.501 21.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 1282" d="M19.131 9.321H4.56l-.762-2.19a.915.915 0 00-.858-.667H.75l.857 4.952a.927.927 0 00.952.762h6.858l-1.9 8.571h2.857l4.762-8.571h6.952a.69.69 0 00.476-1.143l-.571-.571a4.2 4.2 0 00-2.862-1.143z" stroke-linejoin="round"/><path pid="1" data-name="Path 1283" d="M9.317 9.321L7.417.75h2.286a1 1 0 01.857.476l4.476 8.1z"/></g>'
  }
})
