/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'device-laptop': {
    width: 16,
    height: 16,
    viewBox: '1594 1323.5 24 17',
    data: '<g _fill="none" _stroke="#666" stroke-miterlimit="10"><path pid="0" d="M1594.5 1337h23v1a2 2 0 01-2 2h-19a2 2 0 01-2-2zM1597.6 1324h16.8a1.1 1.1 0 011.1 1.1v11.9h-19v-11.9a1.1 1.1 0 011.1-1.1z"/><path pid="1" d="M1599.627 1326h12.746a1.127 1.127 0 011.127 1.127V1337h-15v-9.873a1.127 1.127 0 011.127-1.127z"/></g>'
  }
})
