// Return a promise that resolves in the given number of milliseconds
export const sleep = (time = 1000) => new Promise(resolve => setTimeout(resolve, time))

export class DebounceError extends RangeError {
  static name = 'DebounceError'
}

/**
 * @function createExponentialDebounce
 * Returns a function which will return exponentially larger numbers
 * ((2 ** retry) * scalar) each time it's called until it reaches maxRetries,
 * at which point it will throw.
 * @param  {object} params        - A params object.
 * @param  {number} maxRetries    - The maximum number of retries.
 * @param  {number} scalar        - A scalar for the delay.
 * @param  {boolean} addNoise     - If true then a noise factor of up to 20% is
 * added to each delay returned.
 * @throws {DebounceError}           - Throws if maxRetries is exceeded.
 * @return {function}             - The function
 */
export const createExponentialDebounce = ({ maxRetries = 7, scalar = 100, addNoise = true }) => {
  const getDelay = function () {
    if (this.count > maxRetries) {
      throw new DebounceError('Maximum number of retries exceeded')
    }

    let delay = Math.pow(2, this.count) * scalar
    this.count++
    // Add up to 20% of the delay
    if (addNoise) {
      delay += delay * 0.2 * Math.random()
    }
    return delay
  }

  // Expose these in case anyone cares. (But check against original argument
  // values.)
  getDelay.count = 0
  getDelay.maxRetries = maxRetries
  getDelay.scalar = scalar
  getDelay.addNoise = addNoise

  return getDelay.bind(getDelay)
}

export const rafDebounce = callback => {
  let rafHandle
  return (...args) => {
    if (rafHandle) {
      cancelAnimationFrame(rafHandle)
    }

    callback = callback.bind(callback, ...args)
    rafHandle = requestAnimationFrame(() => {
      rafHandle = undefined
      callback()
    })
  }
}

/**
  * A note on Promises, execution, order, and tasks:
  *
  * Sometimes queueing an additional task or microtask can ensure your
  * expected execution order for chained promises in multiple calling
  * contexts. If you're not seeing the execution order you expect try looking
  * at some of these resources.
  *
  *
  * Good resources on JS tasks, microtasks, and the event loop:
  *
  * This video explains the engine well: https://youtu.be/cCOL7MC4Pl0?t=475
  * This timestamp specifically explains microtasks:
  * https://youtu.be/cCOL7MC4Pl0?t=1452
  *
  * https://developer.mozilla.org/en-US/docs/Web/API/HTML_DOM_API/Microtask_guide
  *
  * This section has an excellent interactive demo where you can walk through
  * the event loop step by step:
  * https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/#why-this-happens
  */

/**
 * Class to create a mutex which guarantees a single lock on an instance.
 */
export class Mutex {
  #queue = Promise.resolve()

  #currentLock = this.#queue

  /**
   * Wait for a lock on the mutex.
   * @returns - Returns a promise which when a lock is acquired resolves to the
   * unlock function.
   */
  async lock () {
    let unlock
    // Create a new lock that other calls will chain off of
    const newLock = new Promise(resolve => {
      unlock = () => {
        resolve()
        if (this.#queue === newLock) {
          this.#queue = Promise.resolve()
          this.#currentLock = undefined
        }
      }
    })

    const previousLock = this.#queue
    this.#queue = newLock

    await previousLock
    this.#currentLock = newLock
    return unlock
  }

  /**
   * The current lock.
   */
  get currentLock () {
    return this.#currentLock
  }
}

export default sleep
