/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'schep-calendar': {
    width: 240,
    height: 240,
    viewBox: '0 0 240 240',
    data: '<g clip-path="url(#clip0_523_2890)"><path pid="0" d="M120 240c66.274 0 120-53.726 120-120S186.274 0 120 0 0 53.726 0 120s53.726 120 120 120z" _fill="#EAEAEA"/><path pid="1" d="M165 54h24v130a6.003 6.003 0 01-1.757 4.243A6.003 6.003 0 01183 190H57a6 6 0 01-6-6V54h24" _fill="#fff"/><path pid="2" d="M189 89.9V54H51v35.9h138z" _fill="#0077B3"/><path pid="3" d="M189 89.9V54h-36l-24 35.9h60z" _fill="#09678C"/><path pid="4" d="M81 42a6 6 0 016 6v12a6 6 0 11-12 0V48a6 6 0 016-6z" _fill="#CCC"/><path pid="5" d="M80.1 42a5.1 5.1 0 015.1 5.1v10.2a5.1 5.1 0 11-10.2 0V47.1a5.1 5.1 0 015.1-5.1z" _fill="#999"/><path pid="6" d="M159 42a6.002 6.002 0 016 6v12a6 6 0 11-12 0V48a6 6 0 016-6z" _fill="#CCC"/><path pid="7" d="M158.1 42a5.098 5.098 0 015.1 5.1v10.2a5.098 5.098 0 01-5.1 5.1 5.098 5.098 0 01-5.1-5.1V47.1a5.098 5.098 0 015.1-5.1z" _fill="#999"/><path pid="8" d="M126.81 159.8h-9.46v-34.41h-8.16v-7c5.31-.27 8.78-2.24 9.73-6.19h7.89v47.6z" _fill="#666"/></g><defs><clipPath id="clip0_523_2890"><path pid="9" _fill="#fff" d="M0 0h240v240H0z"/></clipPath></defs>'
  }
})
