import publicLoginPic from '../assets/login-illustration.png'
import savedItemsPic from '../assets/msi-illustration.png'
import scheduledPaymentsPic from '../assets/schep-illustration.png'
import myPaymentsPic from '../assets/mypayments-illustration.png'
import payhubGeneralPic from '../assets/payhub-illustration.png'
import announcementsPic from '../assets/announcements-illustration.png'
import deliveryPic from '../assets/delivery-illustration.png'
import ewalletPic from '../assets/ewallet-illustration.png'
import helpPic from '../assets/help-illustration.png'
import messagingPic from '../assets/messaging-illustration.png'
import searchPic from '../assets/search-illustration.png'
import shoppingCartPic from '../assets/shopping-cart-illustration.png'

export default [
  {
    key: 'publicLogin',
    image: publicLoginPic,
    // Keep these in sync with the Site Settings in welcome.json
    title: 'intro.public.login.title',
    description: 'intro.public.login.description',
  },
  {
    key: 'savedItems',
    image: savedItemsPic,
    title: 'intro.saved.items.title',
    description: 'intro.saved.items.description',
  },
  {
    key: 'scheduledPayments',
    image: scheduledPaymentsPic,
    title: 'intro.scheduled.payments.title',
    description: 'intro.scheduled.payments.description',
  },
  {
    key: 'myPayments',
    image: myPaymentsPic,
    title: 'intro.my.payments.title',
    description: 'intro.my.payments.description',
  },
  {
    key: 'payhubGeneral',
    image: payhubGeneralPic,
    title: 'intro.payhub.general.title',
    description: 'intro.payhub.general.description',
  },
  {
    key: 'announcements',
    image: announcementsPic,
    title: 'intro.announcements.title',
    description: 'intro.announcements.description',
  },
  {
    key: 'delivery',
    image: deliveryPic,
    title: 'intro.delivery.title',
    description: 'intro.delivery.description',
  },
  {
    key: 'eWallet',
    image: ewalletPic,
    title: 'intro.ewallet.title',
    description: 'intro.ewallet.description',
  },
  {
    key: 'help',
    image: helpPic,
    title: 'intro.help.title',
    description: 'intro.help.description',
  },
  {
    key: 'messaging',
    image: messagingPic,
    title: 'intro.messaging.title',
    description: 'intro.messaging.description',
  },
  {
    key: 'search',
    image: searchPic,
    title: 'common.search',
    description: 'intro.search.description',
  },
  {
    key: 'shoppingCart',
    image: shoppingCartPic,
    title: 'intro.shopping.cart.title',
    description: 'intro.shopping.cart.description',
  },
]
