/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plans': {
    width: 24.5,
    height: 20.5,
    viewBox: '0 0 24.5 20.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Path 6817" d="M3.75 19.75a3 3 0 010-6"/><path pid="1" data-name="Path 6818" d="M.75 3.75a3 3 0 013-3"/><path pid="2" data-name="Line 5312" d="M20.75 19.75h-17"/><path pid="3" data-name="Line 5313" d="M3.75.75v13"/><path pid="4" data-name="Line 5314" d="M.75 16.75v-13"/><path pid="5" data-name="Path 6819" d="M20.75 19.75a3 3 0 000-6"/><path pid="6" data-name="Path 6820" d="M20.75.75a3 3 0 013 3"/><path pid="7" data-name="Line 5315" d="M20.75.75v13"/><path pid="8" data-name="Line 5316" d="M23.75 16.75v-13"/><path pid="9" data-name="Line 5317" d="M3.75 3.75h17"/><path pid="10" data-name="Line 5318" d="M9.75 8.75l4 4"/><path pid="11" data-name="Line 5319" d="M13.75 8.75l-4 4"/></g>'
  }
})
