/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gear-settings': {
    width: 16,
    height: 16,
    viewBox: '1643.998 1070 24.005 24',
    data: '<g transform="translate(1644.498 1070.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M4.075 7.371L2.308 4.429l2.121-2.121 2.942 1.767h0a7.951 7.951 0 011.8-.75L10 0h3l.83 3.33a7.951 7.951 0 011.8.75h0l2.942-1.768 2.121 2.121-1.768 2.938h0a7.951 7.951 0 01.75 1.8l3.33.83v3l-3.33.83a7.951 7.951 0 01-.75 1.8h0l1.768 2.942-2.121 2.121-2.942-1.769h0a7.951 7.951 0 01-1.8.75L13 23h-3l-.83-3.33a7.951 7.951 0 01-1.8-.75h0l-2.941 1.772-2.121-2.121 1.767-2.941h0a7.951 7.951 0 01-.75-1.8L0 13v-3l3.33-.83a7.951 7.951 0 01.75-1.8z"/><circle pid="1" cx="4.5" cy="4.5" r="4.5" transform="translate(7 7)"/></g>'
  }
})
