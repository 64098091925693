/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'money-receipt': {
    width: 16,
    height: 16,
    viewBox: '0 0 14.022 17.5',
    data: '<path pid="0" _stroke="#666" _fill="none" d="M6.998 12.321v1.299m0-9.74v1.299M5.055 11.02s0 1.3 1.825 1.3c2.285 0 2.285-3.109 0-3.571s-2.286-3.571 0-3.571a1.313 1.313 0 011.422 1.3m4.97 10.272v-16M.75 16.75v-16m12.522 16l-2.087-1.391-2.087 1.391-2.087-1.391-2.087 1.391-2.087-1.391L.755 16.75m0-16l2.087 1.391L4.929.75l2.087 1.391L9.103.75l2.087 1.391L13.277.75"/>'
  }
})
