/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'person-add': {
    width: 16,
    height: 16,
    viewBox: '1994 1120.5 24 23',
    data: '<g transform="translate(1994.5 1121)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><ellipse pid="0" cx="5" cy="6" rx="5" ry="6" transform="translate(4)"/><path pid="1" d="M18 22H0a7 7 0 017-7h4a7 7 0 017 7zM20 0v6M17 3h6"/></g>'
  }
})
