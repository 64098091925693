export default class Availability {
  date: string
  open: 1 | 0

  constructor ({
    date = '',
    open = 0,
  }: {
    date: string
    open: 1 | 0
  }) {
    this.date = date
    this.open = open
  }

  get isOpen () {
    return this.open === 1
  }
}
