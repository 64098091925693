<template>
  <div
    class="svg-container"
    :class="{ 'svg-fade': fade || (trigger === 'hover' && isHovered) }"
    :style="`width: ${width}; height: ${height};`"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >

    <svgicon
      :icon="icon"
      :color="color"
      :fill="fill"
      :width="width"
      :height="height"
      :dir="dir"
      :style="`transition: opacity ${timing};`"
    />
    <svgicon
      :icon="icon"
      :color="altColor"
      :fill="fill"
      :width="width"
      :height="height"
      :dir="dir"
      :style="`transition: opacity ${timing};`"
      class="svg-alt"
    />

  </div>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    altColor: {
      type: String,
      required: true,
    },
    fade: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '1rem',
    },
    height: {
      type: String,
      default: '1rem',
    },
    fill: {
      type: Boolean,
      default: false,
    },
    timing: {
      type: String,
      default: '.35s',
    },
    dir: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      isHovered: false,
    }
  },
}
</script>
<style lang="scss" scoped>
.svg-container {
  position: relative;

  &.svg-fade {
    .svg-alt {
      opacity: 1;
    }
  }
}

.svg-icon {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;

  &.svg-alt {
    opacity: 0;
  }
}

</style>
