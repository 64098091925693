/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 16,
    height: 16,
    viewBox: '1794.5 1020 23 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1811 1034.5l-5 5M1801 1034.5l5 5M1817 1037.5v5a1.008 1.008 0 01-1 1h-20.022a.989.989 0 01-.978-1v-5M1806 1020.5v19"/></g>'
  }
})
