import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import ModuleLoadError from '../components/ModuleLoadError.vue'

// We can't list @grantstreet/sentry as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import type { SentryMessageFunction } from '@grantstreet/sentry'
import sleep, { createExponentialDebounce, DebounceError } from '@grantstreet/psc-js/utils/sleep.js'
import {
  type AsyncComponentLoader,
  type Component,
  defineAsyncComponent,
} from 'vue'

// For some reason ts is really struggling with this one, so I had to make a
// local definition
type AsyncComponentResolveResult = Awaited<ReturnType<AsyncComponentLoader<Component>>>

/**
 * Creates a mixin that supports dynamically importing+rendering a module's
 * "entry components" (the top-level module components that can be rendered in
 * an app, e.g., <DeliveryMethod />).
 *
 * This mixin pattern lets us enforce code splitting by default and, when
 * combined with the strict-modules ESLint rule (from
 * @grantstreet/eslint-plugin), ensures that consumers support code splitting
 * instead of importing the entry components statically.
 *
 * Specifically, this mixin:
 *
 *  - Adds a `component` prop that accepts the desired entry component name
 *  - Validates that name against the list of supportedComponents
 *  - Dynamically imports the component
 *  - Reports any errors to the module's Sentry project via sentryException
 *  - Handles rendering component loading and error states
 *  - Proxies any explicitly supported proxyMethods calls (methods supported via
 *    $refs with the componentRefName) to the child component
 *
 * Usage:
 *
 *   <template>
 *     <!-- This mixin computes vueComponent based on supportedComponents -->
 *     <component
 *       :is="vueComponent"
 *       v-bind="$attrs"
 *     />
 *   </template>
 *
 *   <script>
 *   import { createModuleLoaderMixin } from '@grantstreet/psc-vue/utils/module-loader-mixin'
 *   import { sentryException } from './sentry'
 *
 *   export default {
 *     mixins: [
 *       createModuleLoaderMixin({
 *         moduleName: 'Donations',
 *         supportedComponents: {
 *           'Donations': () => import('./components/Donations.vue'),
 *         },
 *         exceptionLogger: sentryException,
 *       }),
 *     ],
 *   }
 *   </script>
 *
 * Notice that you must proxy the props ($attrs) and listeners ($listeners)
 * through to the child component. If you want to proxy slots, you can do so
 * like this:
 *
 * <template>
 *   <component
 *     :is="vueComponent"
 *     v-bind="$attrs"
 *     v-on="$listeners"
 *   >
 *     <!-- Proxy any slots -->
 *     <template
 *       v-for="(_, slot) of $scopedSlots"
 *       #[slot]="scope"
 *     >
 *       <slot
 *         :name="slot"
 *         v-bind="scope"
 *       />
 *     </template>
 *   </component>
 * </template>
 *
 * If you want to support calling methods on the child component, add a `ref` to
 * the `<component>` and then pass `componentRefName` and `proxyMethods` to this
 * mixin factory.
 */
export const createModuleLoaderMixin = ({
  moduleName,
  supportedComponents,
  exceptionLogger,
  componentRefName,
  proxyMethods,
}: {
  /** The name of this module (for errors) */
  moduleName: string

  /** A map of component names to functions that import them */
  supportedComponents: { [key: string]: AsyncComponentLoader<Component> }

  exceptionLogger: SentryMessageFunction

  /** The component's ref value. */
  componentRefName: string

  /** Method names that should be callable directly on the child component */
  proxyMethods?: string[]
}) => {
  let resolveLoadPromise: () => void
  let rejectLoadPromise: (error: Error) => void
  let isSettled = false
  const loadPromise = new Promise<void>((resolve, reject) => {
    rejectLoadPromise = (error: Error) => {
      isSettled = true
      return reject(error)
    }
    resolveLoadPromise = () => {
      isSettled = true
      return resolve()
    }
  })

  return {
    props: {
      component: {
        type: String,
        required: true,
      },
    },

    computed: {
      vueComponent () {
        // @ts-expect-error Vue TS is confused by this mixin reference, but we
        // will need to refactor this into a composable during the Vue 3
        // migration so we can ignore for now.
        const component: string = this.component

        if (!Object.keys(supportedComponents).includes(component)) {
          const error = new Error(`Unsupported ${moduleName} component '${component}'`)
          console.error(error)
          exceptionLogger(error)
          return ModuleLoadError
        }

        // Used to settle the load promise after the request has resolved. If
        // the request rejects it will be handled by the onError.
        const handleLoadPromise = async (result: AsyncComponentResolveResult): Promise<void> => {
          // I think this should always exist but there's a backup just in case
          if ('default' in result) {
            // If there was a mounted method then wrap it and resolve the load
            // promise on mount. (The result object isn't a component instance
            // yet so we need to wrap the function like this.)
            const originalMounted = result.default.mounted
            // Non-arrow function so it gets access to the child component's
            // `this` when it is instantiated and run
            result.default.mounted = function (...args) {
              resolveLoadPromise()
              return originalMounted?.call(this, ...args)
            }
            return
          }

          // I don't think this should ever need to happen but if it does then
          // it will poll for the component ref.
          // This will poll 9 times (once before sleeping) and the last time at
          // about 2550ms (cumulative). After the last iteration it will throw.
          try {
            // Delay the first check until the end of the microtasks queue since
            // there's no chance the component has mounted yet.
            await Promise.resolve()
            const getDebounce = createExponentialDebounce({ maxRetries: 7, scalar: 10, addNoise: true })
            while (true) {
              // @ts-expect-error ts isn't smart enough to know that `this` is
              // the component by the time this function runs.
              if (this.$refs[componentRefName]) {
                return resolveLoadPromise()
              }
              await (sleep(getDebounce()))
            }
          }
          catch (error) {
            rejectLoadPromise(error instanceof DebounceError
              ? new RangeError(`Timeout exceeded waiting for ${component} to load.`)
              // Ts can't figure out that the error class imported from js
              // extends Error
              : error as Error)
          }
        }

        return defineAsyncComponent({
          loader: async () => {
            const result: AsyncComponentResolveResult = await supportedComponents[component]()
            // Kick this off asynchronously
            handleLoadPromise(result)
            return result
          },
          loadingComponent: LoadingBars,
          errorComponent: ModuleLoadError,
          // Retry up to three times then reject
          onError: (error, retry, fail, attempts) => {
            if (attempts <= 3) {
              return retry()
            }
            rejectLoadPromise(error)
            fail()
          },
        })
      },
    },

    methods: {
      ...(proxyMethods || []).reduce(
        (methods, method) => {
          methods[method] = function (...parameters) {
            if (!this.isLoaded()) {
              // This is from a mistake during development so it should be
              // fixable and we should probably be loud about it
              const error = new TypeError(`The target ${moduleName} component is not loaded. Use .isLoaded() or .getLoadPromise().`)
              exceptionLogger(error)
              throw error
            }

            // XXX: We could make this a map to
            // methods[method] = { get () {} set () {} }
            // and handle non-function property access too.
            if (typeof this.$refs[componentRefName][method] !== 'function') {
              throw new TypeError(`No ${method} method is defined for the component.`)
            }

            return this.$refs[componentRefName][method](...parameters)
          }
          return methods
        }, {} as {
          $refs: Record<string, {[key: string]: (...args: Array<unknown>) => unknown | unknown}>
          isLoaded: () => boolean
        },
      ),

      // Does what it says on the tin.
      isLoaded () {
        return isSettled
      },

      /**
       * Returns a promise that resolves when the dynamic component loads.
       */
      async getLoadPromise () {
        return loadPromise
      },
    },

    errorCaptured (error) {
      exceptionLogger(error)
      return false
    },

    // Prevent Vue from literally rendering props passed to the loader and
    // proxied to the child component in the generated HTML (which results in
    // code like `cart="[object Object]"` in the HTML, even though the actual
    // object value is being correctly passed to the child):
    inheritAttrs: false,
  }
}
