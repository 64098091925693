//
// Login Module
//
// The initialization here is a little bit complicated, because
// login has to handle "callbacks" after the OIDC login and logout.
// The callback is a full page load. Our initialization routine
// isn't flexible enough to handle that through vue-router, but
// we do avoid a full page reload.
//
// Additionally, @grantstreet/login provides "authPromise", which resolves
// after we've either loaded the user or determined that nobody is
// logged in.

import {
  App,
  markRaw,
  // *DO NOT* use getCurrentInstance outside of plugin development. It's an
  // intentionally undocumented api. It's not intended for application
  // development but is exposed and widely used for plugin development. This is
  // exactly how pinia and other libraries work
  getCurrentInstance as dangerouslyGetCurrentInstance,
  inject,
} from 'vue'

import type { User } from '@grantstreet/user'

import useExternalLogin, {
  type ExternalHelperExclusiveOptions,
  type ExternalLoginHelpers,
} from './implementations/external.ts'

import useFullLogin, {
  type FullHelperExclusiveOptions,
  type FullLoginHelpers,
} from './implementations/full.ts'

export type LoginFunctionOptions = {
  signup?: boolean
}

type DisallowedUserOverrides = {
  // The service is the authoritative source for these values so we don't allow
  // the application to override them.
  id?: never
  email?: never
  getAccessToken?: never
}

// You can only depreciate fields specific to domains
type deprecatedOidcField = `${'https://'}${string}`

type OidcFieldData = {
  // Current values will need to be checked at runtime to make sure they don't
  // contain 'sub' or 'email'. The service is the authoritative source for those
  // so we don't allow the application to override them.
  current: string
  deprecated: deprecatedOidcField | Array<deprecatedOidcField>
}
export const disallowedOidcOverrides = ['sub', 'email']

export type UserFieldMap = {
  [key: string]: string | OidcFieldData
} & DisallowedUserOverrides

type NormalizedOidcFieldData = {
  current: string
  deprecated: Array<deprecatedOidcField>
}

type NormalizedFieldMap = {
  [key:string]: NormalizedOidcFieldData
} & DisallowedUserOverrides

/**
 * Normalized field map for consistency within gsg login
 */
export const normalizeFieldMap = (map: UserFieldMap): NormalizedFieldMap => {
  const internalFieldMap = {} as NormalizedFieldMap

  for (const [appField, oidcFields] of Object.entries(map)) {
    const { current, deprecated } = typeof oidcFields === 'object'
      ? oidcFields
      : { current: oidcFields, deprecated: [] }

    if (disallowedOidcOverrides.includes(current)) {
      throw new TypeError(`@grantstreet/login: Cannot override OIDC field "${current}".`)
    }

    internalFieldMap[appField] = {
      current,
      deprecated: Array.isArray(deprecated) ? deprecated : [deprecated],
    }
  }

  return internalFieldMap
}

// TODO: PSC-22426 - Move to some utils somewhere
// Constructs a type with all keys of Type set to never
// type Never<Type> = {
//   [K in keyof Type]: never
// }

// Constructs a copy of Type with all keys exclusive to NeverType set to never
type Without<Type, NeverType> = {
  // Get only keys of NeverType that are not in Type, then mark them never
  [P in Exclude<keyof NeverType, keyof Type>]?: never
  // And add in Type
} & Type

// Constructs a type that is either T with all keys of U marked never, or U with
// all keys of T marked never.
type XOR<T, U> = (T | U) extends object
  ? Without<T, U> | Without<U, T>
  : T | U

export type SharedEventHandlers = {
  beforeLogin: () => void
  onUserLoaded: (user: User) => void
  onLoginFailure: (error?: Error) => void
  onUserSignedOut: () => void
}

export type SharedHelperOptions = {
  user: User
  userFieldMap?: UserFieldMap
} & SharedEventHandlers

export type SharedPluginUtilities = {
  user: User
} & {
  [Event in keyof SharedEventHandlers]: (callback: SharedEventHandlers[Event]) => void
}

export type SharedHelperUtilities = {
  initAuth: () => Promise<void>
  authPromise: Promise<void>
  logIn: (args?: LoginFunctionOptions) => Promise<void>
  logOut: (redirectUri?: string) => Promise<void>
  updateUserData: (args: {[key: string]: string | undefined}) => Promise<void>
}

type SharedPluginOptions = {
  user: User
  userFieldMap?: UserFieldMap
}

export type GsgFullLoginOptions =
  SharedPluginOptions &
  Without<
    FullHelperExclusiveOptions & { useExternalAuth?: false },
    ExternalHelperExclusiveOptions & { useExternalAuth: true }
  >

export type GsgExternalLoginOptions =
  SharedPluginOptions &
  Without<
    ExternalHelperExclusiveOptions & { useExternalAuth: true },
    FullHelperExclusiveOptions & { useExternalAuth?: false }
  >

// All of the public options for either version of login
export type GsgLoginOptions = GsgFullLoginOptions | GsgExternalLoginOptions

export type GsgLoginUtilities =
  SharedPluginUtilities &
  XOR<
    FullLoginHelpers,
    ExternalLoginHelpers
  >

export type GsgLogin = {
  install: (app: App, gsgLoginOptions: GsgLoginOptions) => void
  _app: App
  _utilities: GsgLoginUtilities
  _eventHandlers: {
    [Event in keyof SharedEventHandlers]: Array<SharedEventHandlers[Event]>
  }
}

// Used to inject the plugin instance for this module's own use. Please don't
// expose this elsewhere.
const GsgLoginSymbol = Symbol('@grantstreet/login')

/**
 * @function useLogin Returns utilities for a plugin instance. Within vue
 * components this will infer the current vue application and plugin instances.
 * In all other cases the plugin instance must be passed in.
 * @param  {GsgLogin | App} [context] - Optional plugin or vue app instance to
 * get utilities from.
 * @return {GsgLoginUtilities} - The utilities.
 */
export const useLogin = (context?: GsgLogin | App) => {
  // In vue contexts (inside components etc) the plugin instance will be
  // injected. Otherwise it will need to be passed in.

  let plugin: GsgLogin | undefined

  if (!context && dangerouslyGetCurrentInstance()) {
    // Using getCurrentInstance allows us to inject the vue app in vue contexts.
    // That means that the plugin instance data can be stored on the vue
    // instance rather than inside the login module. That in turn means each vue
    // instance can keep track of its own plugin across package boundaries
    // without the need to import anything from the application package.
    //
    // E.g. govhub-nav can useLogin() and get the correct plugin instance for
    // the govhub-vue application without the need to import anything directly
    // from govhub-vue, which it can't do.
    //
    // This is what allows @grantstreet/login to stick to its own concerns and
    // avoid managing instance data.
    plugin = inject(GsgLoginSymbol, undefined)
  }
  else if (context) {
    plugin = '_utilities' in context ? context : context.config.globalProperties.$gsgLogin
  }

  if (!plugin) {
    throw new TypeError('@grantstreet/login: useLogin was called but there was no active instance. Are you trying to useLogin before calling "app.use(createLogin())" or, calling useStore outside of a Vue application context without passing a plugin instance?')
  }
  if (!plugin._app) {
    throw new TypeError('@grantstreet/login: The plugin instance passed to useLogin has not yet been installed.')
  }

  return plugin._utilities
}

export const createLogin = () => {
  // This plugin object can't be a class instance. Vue will bind the install
  // method to it's own instance so `this` wouldn't be helpful. It's easier to
  // create the object in a closure and let it use the scoped reference to
  // itself.
  const plugin = markRaw({
    install (
      app: App,
      options: GsgLoginOptions,
    ) {
      plugin._app = app
      // In vue contexts (inside components etc) the plugin instance will be
      // injected. This is how useLogin works.
      app.provide(GsgLoginSymbol, plugin)

      app.config.globalProperties.$gsgLogin = plugin

      // These are only the utils specific to either helper
      let helperUtilities: XOR<FullLoginHelpers, ExternalLoginHelpers>

      const helperEventHandlers = {
        // Explicit not iterative definition results in better ts types
        beforeLogin: () => plugin._eventHandlers.beforeLogin.forEach(handler => handler()),
        onUserLoaded: user => plugin._eventHandlers.onUserLoaded.forEach(handler => handler(user)),
        onLoginFailure: error => plugin._eventHandlers.onLoginFailure.forEach(handler => handler(error)),
        onUserSignedOut: () => plugin._eventHandlers.onUserSignedOut.forEach(handler => handler()),
      }

      if (options.useExternalAuth) {
        helperUtilities = useExternalLogin({
          ...options,
          ...helperEventHandlers,
        })
        // This is obnoxious but if you try to do this outside of the logical
        // branches ts can't infer that the type of the options XOR matches the
        // type of the helper.
        // E.g.
        // (options.useExternalAuth ? useFullLogin : useExternalLogin)({
        //   // This could contain either helper options and ts can't be sure
        //   // the chirality matches the chirality of the helper
        //   ...options,
        //   ...helperEventHandlers,
        // })
      }
      else {
        helperUtilities = useFullLogin({
          ...options,
          ...helperEventHandlers,
        })
      }

      plugin._utilities = {
        user: options.user,
        ...helperUtilities,
        beforeLogin: handler => plugin._eventHandlers.beforeLogin.push(handler),
        onUserLoaded: handler => plugin._eventHandlers.onUserLoaded.push(handler),
        onLoginFailure: handler => plugin._eventHandlers.onLoginFailure.push(handler),
        onUserSignedOut: handler => plugin._eventHandlers.onUserSignedOut.push(handler),
      }
    },

    // It would be nice if these could all be Definite Assignment Assertions but
    // apparently ts doesn't support those in this syntax. They _are_ all
    // definitely assigned during install.
    _app: null as unknown as App,
    _utilities: {} as GsgLoginUtilities,
    _eventHandlers: {
      beforeLogin: [],
      onUserLoaded: [],
      onLoginFailure: [],
      onUserSignedOut: [],
    },
  } as GsgLogin)

  return plugin
}
