/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'taxi': {
    width: 16,
    height: 16,
    viewBox: '0 0 23.5 23.5',
    data: '<g transform="translate(.754 .75)" _fill="none" _stroke="#666" stroke-width="1.5"><path pid="0" d="M22 19c.1-2.8-.3-8.4-2.2-10H2.2C.3 10.6 0 16.2 0 19h22z" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M14.6 19H7.4v-3.9c0-1.2 1-2.1 2.2-2.1 0 0 0 0 0 0h2.9c1.2 0 2.2.9 2.2 2.1 0 0 0 0 0 0l-.1 3.9z" stroke-linecap="round" stroke-linejoin="round"/><circle pid="2" cx="3.7" cy="13.5" r="1"/><circle pid="3" cx="18.3" cy="13.5" r="1"/><path pid="4" d="M4.3 19v2c0 .6-.5 1-1 1h-1c-.6 0-1-.4-1-1v-2h3M17.7 19v2c0 .6.5 1 1 1h1c.6 0 1-.4 1-1v-2h-3M2.2 9l1-4C3.7 3.7 5 2.9 6.3 3h9.3c1.4-.1 2.6.7 3.1 2l1 4" stroke-linecap="round" stroke-linejoin="round"/><path pid="5" d="M8.4 0h5.3c.6 0 1 .4 1 1v2h0-7.3 0V1c0-.6.4-1 1-1z" stroke-miterlimit="10"/></g>'
  }
})
