/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share': {
    width: 16,
    height: 16,
    viewBox: '1595 1019.793 22.207 24.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1615.5 1033.5v8.261a1.739 1.739 0 01-1.739 1.739h-16.522a1.739 1.739 0 01-1.739-1.739v-16.522a1.739 1.739 0 011.739-1.739h8.261M1612.5 1020.5l4 4M1612.5 1028.5l4-4"/><path pid="1" d="M1604.5 1036.5l.2-3.49a8.742 8.742 0 018.827-8.51h2.973"/></g>'
  }
})
