<template>
  <b-modal
    ref="surveyModal"
    class="survey-modal"
    data-test="survey-modal"
    hide-header
    centered
    static
    visible
    hide-footer
    @hide="$emit('hide')"
  >

    <span class="d-flex flex-row justify-content-end align-items-end p-3">
      <a
        class="cursor-pointer"
        data-test="survey-x"
        @click="$refs.surveyModal.hide()"
      >
        <svgicon
          :fill="false"
          icon="x"
        />
      </a>
    </span>
    <iframe
      :src="typeformUrlWithParams"
      class="border-0"
      style="width: 100%; height: 300px"
    />
  </b-modal>
</template>

<script>
import queryString from 'query-string'

export default {
  emits: ['hide'],
  props: {
    typeformUrl: {
      type: String,
      required: true,
    },
    // Data that will be encoded into query parameters and passed to Typeform.
    // This does not support nested objects, so make sure to JSON.stringify
    // those before passing them here.
    queryData: {
      type: Object,
      default: null,
    },
  },

  computed: {
    typeformUrlWithParams () {
      let finalUrl = this.typeformUrl
      if (this.queryData) {
        finalUrl += '?' + queryString.stringify(this.queryData)
      }
      return finalUrl
    },
  },
}
</script>

<style lang="scss" scoped>

.survey-modal .modal-body {
  padding-right: 0px;
  padding-left: 0px;
}

</style>
