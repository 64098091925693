/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'globe': {
    width: 16,
    height: 16,
    viewBox: '1994 1220 24 24',
    data: '<g transform="translate(1994 1220)" _fill="none"><circle pid="0" cx="11.5" cy="11.5" r="11.5" transform="translate(.5 .5)" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M2.47 18.42c2.06-1.25 17-1.25 19.06 0M2.02 6.3C4 7.64 20 7.64 21.98 6.3" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><ellipse pid="2" cx="5.5" cy="11.5" rx="5.5" ry="11.5" transform="translate(6.5 .5)" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" d="M.5 12.5h23"/><path pid="4" d="M0 0h24v24H0z"/></g>'
  }
})
