/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-receipt': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g _stroke="#666" _fill="none"><g data-name="Ellipse 423"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g><g data-name="Group 2341" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1449" d="M11 9l2.087 2.087L15.174 9l2.087 2.087L19.348 9l2.087 2.087L23.522 9" stroke-linejoin="round"/><path pid="3" data-name="Path 1450" d="M23.522 25l-2.087-2.087L19.348 25l-2.087-2.087L15.174 25l-2.087-2.087L11 25" stroke-linejoin="round"/><path pid="4" data-name="Line 252" stroke-linecap="round" d="M11 25V9"/><path pid="5" data-name="Line 253" stroke-linecap="round" d="M23.522 25V9"/><path pid="6" data-name="Line 254" stroke-linecap="round" stroke-linejoin="round" d="M14.478 14.565h5.565"/><path pid="7" data-name="Line 255" stroke-linecap="round" stroke-linejoin="round" d="M14.478 17.348h5.565"/><path pid="8" data-name="Line 256" stroke-linecap="round" stroke-linejoin="round" d="M14.478 20.13h5.565"/></g></g>'
  }
})
