/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'device-watch': {
    width: 16,
    height: 16,
    viewBox: '1744 1320 24 24',
    data: '<g transform="translate(1744 1320)" _fill="none"><rect pid="0" width="12" height="13" rx="1" transform="translate(6 5.5)" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M17 5.5H7a7.62 7.62 0 001.915-4.164A.99.99 0 019.9.5h4.2a.991.991 0 01.983.848A7.148 7.148 0 0017 5.5zM7 18.5h10a7.62 7.62 0 00-1.915 4.165.99.99 0 01-.981.835H9.9a.991.991 0 01-.983-.848A7.148 7.148 0 007 18.5zM12 8.5v4M15 15.5l-3-3" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M0 0h24v24H0z"/></g>'
  }
})
