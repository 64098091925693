<template>
  <div>
    <b-alert
      show
      variant="warning"
    >
      <b-form-checkbox
        v-model="checked"
        :value="itemId"
        class="align-items-start mr-1"
        inline
        data-test="certificate-checkbox"
      >
        {{ confirmationMessage }}
      </b-form-checkbox>
    </b-alert>
  </div>
</template>

<script>

export default {
  props: {
    confirmationMessage: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
  },

  computed: {
    // This uses Vue's array-backed checkbox value-binding behavior:
    // https://vuejs.org/guide/essentials/forms.html#checkbox
    // The actual value is an array of item IDs whose boxes are checked.
    checked: {
      get () {
        return this.$store.state.Cart.confirmedItemIds
      },
      set (checked) {
        this.$store.commit('Cart/setConfirmedItemIds', checked)
      },
    },
  },
}
</script>
