/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-payment': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g _stroke="#666" _fill="none"><g data-name="Ellipse 420"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g><g data-name="Group 2316" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1451" d="M14 21s0 2 2.8 2c3.5 0 3.5-4.8 0-5.5s-3.5-5.5 0-5.5a2.027 2.027 0 012.2 2"/><path pid="3" data-name="Line 257" d="M17 10v2"/><path pid="4" data-name="Line 258" d="M17 23v2"/></g></g>'
  }
})
