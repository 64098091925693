/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 16,
    height: 16,
    viewBox: '1794.5 1070.5 23 23',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1806 1071v22M1817 1082h-22"/></g>'
  }
})
