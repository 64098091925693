/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'heart-outline': {
    width: 16,
    height: 16,
    viewBox: '1544.483 1171.601 23.034 20.783',
    data: '<path pid="0" d="M1549.37 1172.233a5.5 5.5 0 00-4.235 4.172 5.444 5.444 0 00.987 4.511h-.022l9.065 10.58a1.1 1.1 0 001.67 0l9.065-10.58h-.022a5.444 5.444 0 00.987-4.515 5.5 5.5 0 00-10.865 1.215 5.507 5.507 0 00-6.63-5.383z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
