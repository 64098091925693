/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'contact-info-circle': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<circle pid="0" cx="24" cy="24" r="23.5" _stroke="#666"/><path pid="1" d="M28.409 15.73h-7.837M31.347 15.73h2.938c.26 0 .51.102.693.285a.97.97 0 01.287.688v14.594a.97.97 0 01-.287.688.983.983 0 01-.693.285H13.714a.983.983 0 01-.693-.285.97.97 0 01-.287-.688V16.703a.97.97 0 01.287-.688.983.983 0 01.693-.285h3.918" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M19.592 24.486c1.082 0 1.96-.87 1.96-1.945a1.953 1.953 0 00-1.96-1.946c-1.082 0-1.96.87-1.96 1.946 0 1.074.878 1.945 1.96 1.945zM23.51 29.351h-7.836l.754-2.257a.984.984 0 01.98-.662h4.467a.984.984 0 01.98.662l.656 2.257zM27.428 20.595h4.898M27.428 24.486h4.898M27.428 28.378h4.898M20.572 15.73v.486c0 .387-.155.758-.43 1.032a1.474 1.474 0 01-2.079 0 1.454 1.454 0 01-.43-1.032v-.486M31.347 15.73v.486c0 .387-.155.758-.43 1.032a1.474 1.474 0 01-2.078 0 1.454 1.454 0 01-.43-1.032v-.486" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
