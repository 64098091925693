/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'device-phone': {
    width: 16,
    height: 16,
    viewBox: '1698.5 1320 15 24',
    data: '<g transform="translate(1699 1320.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><rect pid="0" width="14" height="23" rx="2"/><path pid="1" d="M0 19h14M6 21h2"/></g>'
  }
})
