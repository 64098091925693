/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'duplicate': {
    width: 17.5,
    height: 17.5,
    viewBox: '0 0 17.5 17.5',
    data: '<g transform="translate(-600.25 -797.25)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><rect pid="0" width="13.217" height="13.217" rx="1" transform="translate(601 798)"/><path pid="1" d="M615.609 800.783h.695a.7.7 0 01.7.7v11.826a.7.7 0 01-.7.7h-11.826a.7.7 0 01-.695-.7v-.7"/></g>'
  }
})
