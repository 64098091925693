/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'license-plate': {
    width: 16,
    height: 16,
    viewBox: '0 0 25.5 17.5',
    data: '<rect pid="0" x=".75" y=".75" width="24" height="16" rx="3" ry="3" _fill="none" _stroke="#666" stroke-miterlimit="10" stroke-width="1.5"/><circle pid="1" cx="3.75" cy="3.75" r="1" _fill="#666"/><g _fill="#666"><path pid="2" d="M10.19 10.85H9.11l-.39-1.09H6.93l-.38 1.09H5.5l1.88-4.9h.95l1.86 4.9zm-1.8-2.02l-.32-.89c-.1-.29-.2-.58-.25-.76-.05.18-.15.48-.25.76l-.32.89h1.12zM15.07 9.27c0 1.03-.69 1.58-1.74 1.58H11.1v-4.9h2.25c.95 0 1.55.46 1.55 1.29 0 .42-.2.76-.52.95.39.17.69.53.69 1.07zm-1.16-1.95c0-.31-.17-.47-.6-.47h-1.25v.93h1.3c.41 0 .55-.19.55-.46zm.15 1.96c0-.42-.22-.63-.7-.63h-1.3v1.27h1.3c.48 0 .7-.25.7-.64zM19.73 6.96l-.91.42c-.17-.34-.46-.53-.9-.53-.74 0-1.16.64-1.16 1.55s.43 1.55 1.16 1.55c.44 0 .7-.19.96-.62l.83.49c-.34.69-.92 1.11-1.81 1.11-1.28 0-2.14-.98-2.14-2.53s.9-2.53 2.18-2.53c.92 0 1.48.45 1.79 1.08z"/></g><circle pid="3" cx="3.75" cy="13.75" r="1" _fill="#666"/><circle pid="4" cx="21.75" cy="3.75" r="1" _fill="#666"/><circle pid="5" cx="21.75" cy="13.75" r="1" _fill="#666"/>'
  }
})
