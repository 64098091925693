export function canUseApplePay (sentryException) {
  try {
    // canMakePayments throws an exception when called from a cross-origin
    // iframe, or when not explicitly allowed via Feature-Policy. (And possibly
    // in other unknown circumstances.)
    return window.ApplePaySession?.canMakePayments?.()
  }
  catch (error) {
    sentryException(new Error('Apple Pay unavailable: ' + error))
  }

  return false
}
