/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-return': {
    width: 16,
    height: 16,
    viewBox: '1693.793 1022.793 24.207 18.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-miterlimit="10"><path pid="0" d="M1699.5 1023.5l-5 5M1699.5 1033.5l-5-5M1694.5 1028.5h17a6 6 0 016 6h0a6 6 0 01-6 6h-6"/></g>'
  }
})
