import { baseDVUrl } from '@grantstreet/psc-environment/environment.js'
import { joinUrl } from '@grantstreet/psc-js/utils/urls.js'

// TODO: Is this defunct?
/**
 * Guesses the E-Wallet microservice environment based on the passed JWT origin
 * URL. Returns 'test' if the environment cannot be guessed.
 */
export function eWalletServiceEnv (origin) {
  if (!origin) return 'test'

  // NOTE: These regexen are duplicated in
  // @grantstreet/e-wallet-ui/src/assets/js/data-vault.js. You MUST keep them in sync.
  if (origin.match(/(^|[^-])e-wallet.grantstreet.com/)) {
    return 'prod'
  }
  else {
    const matches = /([a-z]+)\d?-e-wallet.grantstreet.com/g.exec(origin)
    if (matches && matches.length === 2) {
      return matches[1]
    }
  }

  if (origin.match(/-dev\d.grantstreet.com/)) {
    return 'test'
  }

  return 'test'
}

// Keep in sync with baseDVUrl generation
export const getCardInputUrl = ({
  type,
  env,
  token,
}) => {
  // Trailing slash is critical atm. DV 301s from http:// no-trailing, to
  // http:// trailing. Which is a no-go for the iframe on our https pages
  // See the ticket DV-20
  const url = [baseDVUrl, '/card-input/']

  const params = new URLSearchParams()
  params.set('env', env)
  params.set('type', type)
  params.set('token', encodeURIComponent(token))
  params.set('v', '3')

  url.push('?' + params.toString())
  return new URL(joinUrl(url)).toString()
}
