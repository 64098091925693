/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'credit-card': {
    width: 16,
    height: 16,
    viewBox: '1444 1274 24 16',
    data: '<g transform="translate(1444.5 1274.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><rect pid="0" width="23" height="15" rx="1"/><path pid="1" d="M0 3h23v3H0zM15 12h5"/></g>'
  }
})
