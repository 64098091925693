/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'goto-xsmall': {
    width: 13.811,
    height: 13.811,
    viewBox: '0 0 13.811 13.811',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" d="M11.707 7.583v4.525a.953.953 0 01-.953.953H1.703a.953.953 0 01-.953-.953V3.061a.953.953 0 01.953-.953h4.525M9.62 1.061h3.13M12.75 4.191v-3.13M7.011 6.8l5.739-5.739"/></g>'
  }
})
