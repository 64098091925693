/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sheriff': {
    width: 21.5,
    height: 21.5,
    viewBox: '0 0 21.5 21.5',
    data: '<path pid="0" data-name="sheriff" d="M18.75 6.625a2 2 0 00-2 2h-3.876l-1.26-4.079a2 2 0 10-1.729 0l-1.26 4.079H4.75a2.007 2.007 0 10-.857 1.638l3.419 2.611-1.2 3.883a2 2 0 101.767.936l2.871-2.192 2.87 2.192a2 2 0 101.767-.936l-1.2-3.883 3.419-2.611a2 2 0 101.144-3.638z" _fill="none" _stroke="#666" stroke-miterlimit="10" stroke-width="1.5"/>'
  }
})
