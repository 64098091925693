<template>
  <div
    ref="cartItemDetails"
    tabindex="0"
  >
    <SignupModal
      v-if="useLogin"
      ref="signupModal"
      :aria-label="$t('schedpay.login_modal.aria_label')"
      data-test="schep-sign-up-modal"
      :signup-text="automaticPaymentsSignupText"
      :before-login="prepareToEnrollInAutoPay"
      :illustration="{
        src: schepSrc,
        alt: $t('intro.scheduled.payments.title'),
      }"
      @log-in="$emit('log-in', $event)"
    />
    <b-row
      class="border-bottom"
      :class="isChild ? '' : 'item py-3 py-md-4'"
    >
      <!-- Show the display_name above the description if the description
              exists. Otherwise show the display_name in-line with the amount. -->
      <b-col
        cols="12"
        md="6"
        lg="7"
        class="details my-2 d-flex align-items-center"
      >
        <b-row class="flex-grow-1">
          <b-col
            cols="12"
            :md="useDelivery && item.payable.requiresShipping ? 8 : 12"
            class="d-flex flex-column"
          >
            <div v-if="isChild">
              <strong>{{ item.payable.displayName }}</strong>
              <div v-if="item.payable.description !== ''">
                {{ item.payable.description }}
              </div>
            </div>
            <PayablesLoader
              v-else
              component="PayableDescription"
              :variation="item.payable.description ? '' : 'short'"
              :payable="item.payable"
              :read-only="!currentlyEditable"
            />

            <!-- For medium windows, we want the PayableLinks to be in the
                  same column as the display name-->
            <PayablesLoader
              v-if="receiptView"
              component="PayableActionLinks"
              :payable="item.payable"
              should-display-tag="receipt"
              new-tab
              class="d-none d-md-block"
            />

            <FreeformField
              v-for="(field, index) of freeformFields"
              ref="freeformField"
              :key="field.reportingKey || field.pexKey"
              :data-test="field.reportingKey || field.pexKey"
              :field="field"
              :read-only="!currentlyEditable"
              @field-update="updateFreeformField($event, $refs.freeformField[index].reset)"
            />
            <QuantityInput
              v-if="item.canModifyQuantityDirectly && !cart.locked"
              ref="quantityInput"
              :cart-item="item"
              :read-only="!currentlyEditable"
              @update-error="updateError(item.id, $event)"
            />
          </b-col>
          <b-col
            v-if="useDelivery && item.payable.requiresShipping && showDeliveryOptionDescription"
            cols="12"
            md="4"
            class="align-items-center d-flex py-2 py-md-0 justify-content-md-end"
          >
            {{ deliveryOptionDescription }}
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        md="6"
        lg="5"
      >
        <b-row class="h-100">
          <b-col
            v-if="!receiptView && showItemRemoveLink(item) && !readOnly && !cart.locked"
            :cols="itemHasLargeAmount(item) ? 5 : 6"
            :lg="itemHasLargeAmount(item) ? 6 : 7"
            class="d-flex align-items-center justify-content-md-end text-md-right"
          >
            <RemoveItemLink
              ref="removeItemLink"
              :cart-item="item"
              :disabled="!currentlyEditable"
              @click="$emit('item-removed')"
            />
          </b-col>
          <b-col
            v-else-if="receiptView"
            :cols="itemHasLargeAmount(item) ? 5 : 6"
            :lg="itemHasLargeAmount(item) ? 6 : 7"
            class="d-flex align-items-center"
          >
            <!-- For small windows, we want the PayableLinks to be in the
                    same row as the convenience fee -->
            <PayablesLoader
              component="PayableActionLinks"
              :payable="item.payable"
              should-display-tag="receipt"
              new-tab
              class="d-md-none"
            />
          </b-col>
          <b-col
            v-else
            :cols="itemHasLargeAmount(item) ? 5 : 6"
            :lg="itemHasLargeAmount(item) ? 6 : 7"
            class="d-flex"
          />

          <b-col
            :cols="itemHasLargeAmount(item) ? 7 : 6"
            :lg="itemHasLargeAmount(item) ? 6 : 5"
            class="d-flex align-items-end justify-content-center cart-amount flex-column"
            data-test="cart-item-amount"
          >
            <AmountInput
              ref="amountInput"
              :cart-item="item"
              :read-only="readOnly || !item.payable.isAmountModifiable || isSchedPayOnlyCart"
              @update-error="updateError(item.id, $event)"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col
        v-if="allowsAutopay && autopayEligible(item)"
        cols="12"
        class="mt-1"
      >
        <AutopayCheckbox
          v-if="showAutopayCheckbox"
          v-model="item.enrollInAutopay"
          :read-only="!currentlyEditable"
          :receipt="receiptView"
          :tender="getPayableSchedule(item.payable)?.tender || null"
          :user-has-active-plan="userHasActivePlan"
          :payable-has-active-plan="payableHasActivePlan"
          :selected-tender-supports-autopay="selectedTenderSupportsAutopay"
          :payable="item.payable"
          :is-already-enrolled-in-autopay="isAlreadyEnrolledInAutopay"
          @anonymous-selection="showSchepLoginModal"
        />
      </b-col>

      <b-col
        v-if="hasUpdateError"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
          class="justify-content-between px-3"
        >
          {{ $t('cart.server.error') }}
        </b-alert>
      </b-col>

      <b-col
        v-if="allPaymentMethodsRestricted"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
          class="justify-content-between px-3"
        >
          {{
            $t('cart.no_enabled_payment_methods', {
              lastFour: item.payable.getRestrictingConfirmationNumber(siteEnabledPaymentMethods).slice(-4),
            })
          }}
        </b-alert>
      </b-col>

      <b-col
        v-if="item.updatedPayable && updatedPayableAttribute === 'amount'"
        class="pt-3"
        cols="12"
      >
        <b-alert
          variant="danger"
          class="updated-payable-alert mb-0 d-flex flex-wrap flex-lg-nowrap align-items-center"
          show
        >
          <div
            v-dompurify-html="$t('cart.updated', {
              originalAmount: item.amount,
              newAmount: item.updatedPayable.amount,
            })"
            class="my-3"
          />
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              size="sm"
              variant="outline-danger"
              class="ml-3"
              @click="modifyItemPayable(item)"
            >
              {{ $t('cart.keep_item') }}
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              class="ml-3"
              @click="removeItem(item.id)"
            >
              {{ $t('remove.default') }}
            </b-button>
          </div>
        </b-alert>
      </b-col>

      <b-col
        v-if="item.payableNotFound ||
          !item.payable.isPayable ||
          (item.updatedPayable && updatedPayableAttribute === 'isPayable')"
        class="pt-3"
        cols="12"
      >
        <b-alert
          variant="danger"
          class="updated-payable-alert mb-0 d-flex flex-wrap flex-lg-nowrap align-items-center"
          show
        >
          <div
            v-dompurify-html="unpayableAlertMessage"
            class="my-3"
          />
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              size="sm"
              variant="danger"
              class="ml-3"
              @click="removeItem(item.id)"
            >
              {{ $t('remove.default') }}
            </b-button>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ConfirmationCheckbox
          v-if="isSelectionView && item.payable.requiresConfirmation"
          :item-id="item.id"
          :confirmation-message="item.payable.requiresConfirmationMessage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import QuantityInput from './QuantityInput.vue'
import AutopayCheckbox from './AutopayCheckbox.vue'
import AmountInput from './AmountInput.vue'
import FreeformField from '@grantstreet/psc-vue/components/FreeformField.vue'
import RemoveItemLink from './RemoveItemLink.vue'
import ConfirmationCheckbox from './ConfirmationCheckbox.vue'
import {
  PayablesLoader,
  getPayableSource,
  mapFreeformFields,
} from '@grantstreet/payables'
import { showItemRemoveLink, itemHasLargeAmount } from '../store/helpers.js'
import cloneDeep from 'lodash/cloneDeep.js'
import { mapActions, mapGetters } from 'vuex'
import { mapConfigGetters } from '@grantstreet/psc-config'
import SignupModal from '@grantstreet/psc-vue/components/SignupModal.vue'
// We can't list @grantstreet/govhub-vue as a package.json dependency to avoid a circular
// dependency. See PSC-9153.
// eslint-disable-next-line node/no-extraneous-import, import/no-extraneous-dependencies
import schepSrc from '@grantstreet/govhub-vue/src/assets/schep-illustration.png'
import { useCallbackActions } from '@grantstreet/callback-actions'
import { useGsgUser } from '@grantstreet/user'

export default {
  emits: ['update-error', 'item-removed', 'log-in'],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    allowsAutopay: {
      type: Boolean,
      default: false,
    },
    receiptView: {
      type: Boolean,
      default: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
    isSelectionView: {
      type: Boolean,
      default: false,
    },
    showDeliveryOptionDescription: {
      type: Boolean,
      default: true,
    },
    selectedTenderSupportsAutopay: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PayablesLoader,
    QuantityInput,
    AutopayCheckbox,
    AmountInput,
    RemoveItemLink,
    FreeformField,
    ConfirmationCheckbox,
    SignupModal,
  },

  setup: () => ({
    callbackActions: useCallbackActions(),
    user: useGsgUser().user,
  }),

  data: () => ({
    hasUpdateError: false,
    schepSrc,

    // Data about the disabled status of the autopay checkbox (if the item is
    // already enrolled)
    payableHasActivePlan: false,
  }),

  async mounted () {
    // If the allowedTenderTypes is updated, we can update the payable without alerting the user
    if (this.updatedPayableAttribute === 'allowedTenderTypes') {
      this.modifyItemPayable(this.item)
    }

    // Determine the disabled status of the autopay checkbox if necessary
    if (this.mustPreventDuplicatePlans && !this.userHasActivePlan) {
      this.payableHasActivePlan = await this.checkForExistingSchedules(
        this.item?.payable?.isYearlyAutopayEligible
          ? this.item?.payable?.savePath
          : this.item?.payable?.path,
      )
    }
  },

  computed: {
    showAutopayCheckbox () {
      if (!this.useLogin) return false
      if (!this.useSchedPay) return false

      // If the checkbox can be modified, we should display the checkbox
      if (!this.readOnly) return true

      // If this payable is already enrolled in autopay, the autopay checkbox
      // will display text informing the user this.
      if (this.isAlreadyEnrolledInAutopay) return true

      // If this item is currently enrolled in autopay, always display the
      // autopay component. (this will display info text rather than a checkbox)
      if (this.hasEnrolledInAutopay(this.item)) return true

      // Display checkbox if we are enrolling the item in autopay, and we are
      // not yet on the receipt page
      if (this.item.enrollInAutopay && !this.receiptView) return true

      // Once we are on the receipt page, if a schep plan doesn't exist for the
      // item, the info text will not be displayed
      return false
    },
    currentlyEditable () {
      return !this.readOnly && !this.$wait.is(`modifying.${this.item.id}`)
    },

    freeformFields () {
      const fieldMap = (template) => {
        // Make sure not to modify the template
        const field = cloneDeep(template)
        // Grab value from user's cart
        const key = template.reportingKey || template.pexKey
        field.value = this.item.userParameters?.[key] || ''
        return field
      }

      return mapFreeformFields({ payable: this.item.payable, fieldMap })
    },

    deliveryOptionDescription () {
      const isPickUp = this.deliveryOption === 'pickUp' ||
                       this.item.payable.path.includes('pickup') ||
                       this.item.payable.path.includes('pick_up')

      if (isPickUp) {
        return this.$t('delivery.pick_up')
      }
      if (this.deliveryOption === 'shipping') {
        return this.$t('delivery.mail')
      }

      return ''
    },

    unpayableAlertMessage () {
      if (this.item.notFound) {
        return this.$t('cart.item_not_found')
      }
      else if (!this.item.payable.isPayable || !this.item.updatedPayable.isPayable) {
        let unpayableMessage = this.$t('cart.item_not_payable')
        const isPayableMessage = this.item.payable.isPayableMessageDisplay || this.item.updatedPayable.isPayableMessageDisplay

        // Trim off the last period from the default error message and add the
        // isPayableMessage if there is one
        if (isPayableMessage) {
          unpayableMessage = `${unpayableMessage.slice(0, -1)}: ${isPayableMessage}`
        }

        return unpayableMessage
      }

      return ''
    },

    // returns true if every site enabled payable method is restricted
    allPaymentMethodsRestricted () {
      return this.siteEnabledPaymentMethods.every(method => this.item.payable.isTenderRestricted(method) === true)
    },

    automaticPaymentsSignupText () {
      if (this.item.payable.canScheduleOneTimePayment) {
        return this.$t('schedpay.login_modal.html.scheduled_payments')
      }

      return this.$t('schedpay.login_modal.html.automatic_payments')
    },

    updatedPayableAttribute () {
      // The updated payable amount is sometimes a float, so let's guarantee
      // that both amounts compared are the same type
      if (this.item.updatedPayable) {
        if (this.item.amount.toString() !== this.item.updatedPayable.amount.toString()) {
          return 'amount'
        }
        else if (!this.item.updatedPayable.isPayable) {
          return 'isPayable'
        }
        else if (this.item.payable.allowedTenderTypes !== this.item.updatedPayable.allowedTenderTypes) {
          return 'allowedTenderTypes'
        }
      }

      return ''
    },

    userHasActivePlan () {
      return Boolean(this.getPayableSchedule(this.item.payable))
    },

    mustPreventDuplicatePlans () {
      return this.item?.payable?.scheduledPaymentsConfig?.denyDuplicatePlans
    },

    isAlreadyEnrolledInAutopay () {
      return this.userHasActivePlan || (this.mustPreventDuplicatePlans && this.payableHasActivePlan)
    },

    ...mapConfigGetters([
      'useDelivery',
      'useSchedPay',
      'useLogin',
      'isSchedPayOnlyCart',
    ]),

    ...mapGetters('PayHub', [
      'localizePayableSource',
    ]),

    ...mapGetters('Cart', [
      'deliveryOption',
      'siteEnabledPaymentMethods',
      'cart',
    ]),

    ...mapGetters('SchedPay', [
      'schedules',
    ]),
  },

  methods: {

    showItemRemoveLink,

    itemHasLargeAmount,

    // Building out a custom "freeform field" object for the blind payable
    // displayName input field
    buildDisplayNameField () {
      const {
        blindDisplayNameLabel,
        blindDisplayNamePlaceholder,
      } = this.localizePayableSource(getPayableSource(this.item.payable.configDisplayType.name))

      return {
        label: blindDisplayNameLabel,
        placeholder: blindDisplayNamePlaceholder,
        value: this.item.payable.displayName,
        pexKey: 'display_name',
        required: true,
      }
    },

    updateError (id, event) {
      this.hasUpdateError = event
      this.$emit('update-error', { id, event })
    },

    getPayableSchedule (payable) {
      return this.schedules[payable?.isYearlyAutopayEligible ? payable.savePath : payable.path]
    },

    hasEnrolledInAutopay (item) {
      // this.schedules may be undefined if the schedpay vuex store has not been
      // initialized, which is the case in embedded checkout pages.
      if (!this.schedules) {
        return false
      }
      if (this.getPayableSchedule(item.payable)) {
        const payable = item.payable
        return payable.isAutopayEligible || payable.isYearlyAutopayEligible
      }

      return false
    },

    autopayEligible (item) {
      return this.hasEnrolledInAutopay(item) || item.payable.isAutopayEligible || item.payable.isYearlyAutopayEligible
    },

    prepareToEnrollInAutoPay () {
      this.callbackActions.queueAction('enrollInAutoPay', { payablePath: this.item.payable.path })
    },

    async updateFreeformField ({ reportingKey, pexKey, value }, reset) {
      this.$wait.start(`modifying.${this.item.id}`)
      try {
        await this.modifyItemFreeformField({ item: this.item, reportingKey, pexKey, value })
        this.updateError(this.item.id, false)
      }
      catch (error) {
        this.updateError(this.item.id, true)
        // Clears internal model for the input, resetting it to the field.value
        reset()
      }
      finally {
        this.$wait.end(`modifying.${this.item.id}`)
      }
    },

    async removeItem (itemId) {
      await this.$refs.removeItemLink.removeItem()
    },

    async modifyItemPayable (item) {
      await this.$store.dispatch('Cart/modifyItemPayable', { item, payable: item.updatedPayable })
    },

    validate () {
      let result = true

      const amountInputRef = this.$refs.amountInput
      if (amountInputRef && !amountInputRef.validate()) {
        result = false
      }

      const freeformFieldRefs = this.$refs.freeformField
      if (freeformFieldRefs && freeformFieldRefs.length) {
        for (const field of freeformFieldRefs) {
          if (!field.validate()) {
            result = false
          }
        }
      }

      return result
    },

    showSchepLoginModal () {
      this.$refs.signupModal.show()
    },

    ...mapActions('Cart', [
      'modifyItemFreeformField',
    ]),

    ...mapActions('SchedPay', [
      'checkForExistingSchedules',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
