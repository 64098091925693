/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location-marker-map': {
    width: 16,
    height: 16,
    viewBox: '1344 1270.012 24 23.975',
    data: '<g _fill="none" _stroke="#666"><path pid="0" d="M1347.5 1279.488h-1l-2 14 6-3 5.5 2.438 5.5-2.438 6 3-2-14h-1" stroke-linecap="round" stroke-linejoin="round"/><g stroke-miterlimit="10"><path pid="1" d="M1362.829 1277.342c0 4.928-6.829 11.8-6.829 11.8s-6.838-6.841-6.829-11.8a6.829 6.829 0 1113.657 0z"/><path pid="2" d="M1359.1 1277.112a3.1 3.1 0 11-3.1-3.1 3.1 3.1 0 013.1 3.1z"/></g></g>'
  }
})
