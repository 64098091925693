<template>
  <div class="d-xl-flex">
    <ContactForm
      class="flex-grow-1 border-bottom border-xl-bottom-0 border-xl-right pb-5 pb-xl-0 pr-xl-5"
      :default-contact-us-info="defaultContactUsInfo"
    />
    <SiteFeedback class="site-feedback mt-5 mt-xl-0 ml-xl-5" />
  </div>
</template>

<script>
import ContactForm from './ContactForm.vue'
import SiteFeedback from './SiteFeedback.vue'

export default {
  components: {
    ContactForm,
    SiteFeedback,
  },

  props: {
    defaultContactUsInfo: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>

.site-feedback {
  max-width: 25rem;
}

</style>
