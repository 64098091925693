/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'line-graph': {
    width: 16,
    height: 16,
    viewBox: '1394 1320 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1417.5 1322.5l-7 11-6-2-10 12M1394.5 1320.5v23M1417.5 1343.5h-23"/></g>'
  }
})
