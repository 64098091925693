/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus-circle': {
    width: 16,
    height: 16,
    viewBox: '1994 1070 24 24',
    data: '<g transform="translate(1994.5 1070.5)" _fill="none" _stroke="#666" stroke-linejoin="round"><circle pid="0" cx="11.5" cy="11.5" r="11.5"/><path pid="1" stroke-linecap="round" d="M6 11.5h11M11.5 6v11"/></g>'
  }
})
