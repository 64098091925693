/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trash': {
    width: 16,
    height: 16,
    viewBox: '1846 1070 20 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1846.5 1073.5h19M1854.309 1070.5h3.381a2.309 2.309 0 012.31 2.309v.691h-8v-.691a2.309 2.309 0 012.309-2.309zM1856 1077.5v12M1852 1077.5v12M1860 1077.5v12"/><path pid="1" d="M1860.146 1093.5h-8.292a3 3 0 01-3-2.85l-.854-17.15h16l-.858 17.15a3 3 0 01-3 2.85z"/></g>'
  }
})
