/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clipboard': {
    width: 18.5,
    height: 24.5,
    viewBox: '0 0 18.5 24.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" data-name="Layer 2"><path pid="0" data-name="Path 6827" d="M6.75.75h5a2 2 0 012 2v1h-9v-1a2 2 0 012-2z"/><path pid="1" data-name="Path 6828" d="M15.75 2.75h2v21h-17v-21h2"/><path pid="2" data-name="Path 6829" d="M2.75 9.75l2 2 3-3"/><path pid="3" data-name="Line 5321" d="M10.75 10.75h4"/><path pid="4" data-name="Path 6830" d="M2.75 15.75l2 2 3-3"/><path pid="5" data-name="Line 5322" d="M10.75 16.75h4"/></g>'
  }
})
