/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'progress-bar-double-check': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g _stroke="#666" _fill="none"><g data-name="Ellipse 483"><circle pid="0" cx="17" cy="17" r="17" _stroke="none"/><circle pid="1" cx="17" cy="17" r="16.5"/></g><g data-name="Group 2954" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="2" data-name="Path 1530" d="M17.2 19.5l1.8 1.2 6-7.7"/><path pid="3" data-name="Path 1531" d="M9 18.3l3.5 2.9 5.9-7.7"/></g></g>'
  }
})
