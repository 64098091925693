/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'send': {
    width: 16,
    height: 16,
    viewBox: '1844.762 1220.443 22.488 23.092',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1861.624 1241.698l5.081-19.481a1 1 0 00-1.43-1.139l-19.476 10.163a1 1 0 00-.081 1.726l14.4 9.316a1 1 0 001.506-.585z"/><path pid="1" d="M1856.275 1240.034l-4 3v-6M1852.411 1236.96l9.864-10.926"/></g>'
  }
})
