/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'courthouse': {
    width: 16,
    height: 16,
    viewBox: '1794 1270.028 24 23.942',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1817.5 1278.469h-23v-1.228a1 1 0 01.748-.968l10.418-5.686a1 1 0 01.667 0l10.419 5.687a1 1 0 01.748.968zM1799.5 1278.469v11.5M1796.5 1278.469v11.5M1815.5 1278.469v11.5M1812.5 1278.469v11.5M1807.5 1278.469v11.5M1804.5 1278.469v11.5M1794.5 1290.469h23v2a1 1 0 01-1 1h-21a1 1 0 01-1-1z"/></g>'
  }
})
