<template>
  <img
    class="logo"
    :src="clientLogo"
    :alt="clientTitle + ' ' + $t('home.logo.payhub')"
  >
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('PayHub', [
      'clientLogo',
      'clientTitle',
    ]),
  },
}
</script>

<style lang="scss">
.logo {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>
