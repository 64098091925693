<!-- This component should be used via ConvenienceFee.vue, not by itself. -->
<template>
  <div
    v-if="$store.state.PayHub"
    class="d-inline-block"
  >
    <a
      v-b-modal="modalId"
      href="#"
      class="font-weight-bold"
      @click.prevent="handleModalShown"
    >
      {{ $t('fee_tooltip.text', { feeName }) }}
    </a>
    <b-modal
      :id="modalId"
      :title="$t('fee_tooltip.text', { feeName })"
      class="font-weight-normal"
      ok-only
      centered
      static
      lazy
    >
      <!-- List each fee translatedFeeKey in its own paragraph (there could be multiple
      if there items with different item_categories in the cart) -->
      <p
        v-for="translatedFeeKey in translatedFeeKeys"
        :key="translatedFeeKey"
      >
        {{ $t(translatedFeeKey, { feeName }) }}
      </p>
      <p>
        {{ $t('fee_tooltip.tooltip_text', {
          feeName,
          clientTitle,
        }) }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    translatedFeeKeys: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    modalId () {
      return `fee-modal-${this._uid}`
    },

    feeName () {
      return this.$t('fee.name')
    },

    // TODO: Should this be a dep??? I don't think so
    ...mapGetters('PayHub', ['clientTitle']),
  },

  methods: {
    handleModalShown () {
      this.$gtag.event(
        // Log that the user viewed the modal to Google Analytics
        'Fee Information Modal Opened.',
        {
          'event_category': 'E-Wallet',
        },
      )
    },
  },
}
</script>
