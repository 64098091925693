<template>
  <div v-if="useCart">
    <Dropdown
      ref="popper"
      :class="{big, 'h-100': big }"
      class="d-inline"
      popper-class="cart-icon-popper"
      :placement="placement"
      :distance="parseInt(distance, 10)"
    >
      <b-link
        :class="{ 'cart-icon': !inline, 'h-100': big }"
        class="position-relative"
        :aria-label="$t('cart.default')"
      >
        <svgicon
          :width="big ? '2.25rem' : '1rem'"
          :height="big ? '2.25rem' : '1rem'"
          :fill="false"
          :class="{
            'white': !inline,
            'mr-2': inline && count >= 100 && count < 1000,
            'mr-3': inline && count >= 1000
          }"
          icon="shopping-cart"
        />

        <template v-if="count > 0">
          <!-- Transitions for the whole badge (nesting badge and count
              transitions does not seem to work, possibly because of the
              duplicate key binding): -->
          <transition
            v-if="transitionType === 'bump-badge'"
            name="bump-down"
            mode="out-in"
          >
            <span
              :key="count"
              class="badge badge-pill badge-danger"
              data-test="cart-quantity"
            >
              {{ count }}
            </span>
          </transition>

          <!-- Transitions for just the count number: -->
          <span
            v-else-if="transitionType === 'fade-count'"
            class="badge badge-pill badge-danger"
          >
            <transition
              name="fade-delay"
              mode="out-in"
            >
              <span
                :key="count"
                data-test="cart-quantity"
              >
                {{ count }}
              </span>
            </transition>
          </span>
        </template>
      </b-link>
      <template #popper>
        <div
          class="popper"
          :class="popperContentClass"
        >
          <MiniCart
            v-if="count > 0"
            @quantity-update="preventClose()"
            @payable-removed="preventClose()"
            @continue="onContinue()"
          />
          <div
            v-else
            class="p-3"
          >
            {{ $t('cart.empty.message') }}
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { Dropdown } from 'floating-vue'
import { mapGetters } from 'vuex'
import MiniCart from './MiniCart.vue'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  emits: ['continue'],
  components: {
    Dropdown,
    MiniCart,
  },

  props: {
    placement: {
      type: String,
      default: null,
    },
    distance: {
      type: [String, Number],
      default: null,
      validator: value => !isNaN(value),
    },
    big: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    transitionType: {
      type: String,
      default: 'fade-count',
    },
    transitionBumpBadge: {
      type: Boolean,
      default: false,
    },
    transitionFadeCount: {
      type: Boolean,
      default: false,
    },
    popperContentClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('Cart', ['count']),
    ...mapConfigGetters(['useCart']),
  },

  watch: {
    count (newCount) {
      if (newCount === 0) {
        this.$refs.popper.hide()
      }
    },
  },

  methods: {
    onContinue () {
      this.$refs.popper.hide()
      this.$emit('continue')
    },
  },
}
</script>

<style lang="scss" scoped>
:global(.v-popper__popper.cart-icon-popper) {
  border-radius: 5px;
  box-shadow: 0 0 1.25rem rgba($black, .30);

  @include media-breakpoint-up(sm) {
    width: 34rem;
  }
}
.popper {
  font-size: 1rem;
  color: $body-color;
}

.cart-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  left: .4rem;
  position: absolute;
  top: -.1rem;
  padding: 0.15em .5em
}

.big {
  .badge {
    bottom: auto;
    left: auto;
    right: .625rem;
    top: .625rem;
    padding: 0.25em .6em
  }
}

.cursor-pointer {
  cursor: pointer
}

.bump-down-enter-active {
  animation: bump-down .4s ease-out;
}
.bump-down-leave-active {
  // Wait until the flier reaches the cart icon
  transition: all .35s ease-in;
}
@keyframes bump-down {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

// Also in NotificationPopper.vue
.fade-delay-enter-active {
  transition: all .4s ease-out;
}
.fade-delay-leave-active {
  transition: all .35s ease-in;
}
.fade-delay-enter-from,
.fade-delay-leave-to {
  opacity: 0;
}
.white {
  color: white
}
</style>
