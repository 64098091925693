/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet': {
    width: 16,
    height: 16,
    viewBox: '1644 1171 24 22',
    data: '<g data-name="Group 1694" transform="translate(1644.5 1171.5)" _fill="none" _stroke="#666"><path pid="0" data-name="Path 1113" d="M0 4v15a2.006 2.006 0 002 2h19a2.006 2.006 0 002-2V5a1 1 0 00-1-1H2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" data-name="Line 1169" stroke-linecap="round" stroke-linejoin="round" d="M0 6V2"/><path pid="2" data-name="Path 1114" d="M17 15h6V9h-6a3 3 0 00-3 3h0a3 3 0 003 3z" stroke-linecap="round" stroke-linejoin="round"/><circle pid="3" data-name="Ellipse 378" cx="1" cy="1" r="1" transform="translate(16 11)" stroke-miterlimit="10"/><path pid="4" data-name="Path 1115" d="M2 0a2 2 0 000 4" stroke-linecap="round" stroke-linejoin="round"/><path pid="5" data-name="Path 1116" d="M18 4V1a1 1 0 00-1-1H2" stroke-linecap="round" stroke-linejoin="round"/></g>'
  }
})
