<template>
  <div v-if="!image"><slot /></div>
  <div
    v-else
    class="d-flex flex-column"
    :class="{'flex-md-row': left}"
  >
    <div
      class="layout-image w-100 mx-auto mb-4"
      :class="{
        left,
        'nav-aware-top': left,
        'nav-aware-h': left,
        'mb-md-0': left,
      }"
      :style="{backgroundImage: `url('${image}')`}"
    />
    <div class="flex-grow-1 flex-shrink-1">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    meta () {
      return this.$route.meta
    },

    image () {
      return this.meta.pageImageTop || this.meta.pageImageLeft
    },

    left () {
      return this.meta.pageImageLeft && !this.meta.pageImageTop
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 2560px;

  @include media-breakpoint-down(xs) {
    height: 140px;
  }

  @include media-breakpoint-down(sm) {
    height: 200px;
  }

  @include media-breakpoint-up(md) {
    &:not(.left) {
      height: 280px;
    }

    &.left {
      position: sticky;
      flex: 0 0 calc(100% / 3);
    }
  }
}

</style>
