import Vue from 'vue'
import Vuex from 'vuex'
import Address from './models/Address.ts'
import AddressesApi from './api/addresses-api.js'
import Office from './models/Office.ts'
import { withTimeout, isTimeout } from '@grantstreet/psc-js/utils/timeout.js'
import type { User } from '@grantstreet/user'
import { sentryException } from './sentry.ts'
import { searchPayables } from '@grantstreet/payables'

Vue.use(Vuex)

type PickupField = {
  pickupField: {
    en: string
    es: string
  }
}

export type InstallParams = {
  user: User
  client: string
  site: string

  enableRExHubPickUp: boolean
  usesRenewExpress: boolean
  disableCustomAddress: boolean
  enablePermanentAddressChange: boolean
  additionalRExHubPickUpFields: PickupField[]
  pickupInstructions: string
  requireClickToShowAddress: boolean
  contactPhone: string
}

class State {
  loadDataPromise: Promise<void> | undefined
  user: User | undefined
  client = ''
  site = ''

  enableRExHubPickUp = false
  usesRenewExpress = false
  disableCustomAddress = false
  enablePermanentAddressChange = false
  additionalRExHubPickUpFields: PickupField[] = []
  pickupInstructions = ''
  requireClickToShowAddress = false
  contactPhone = ''

  addresses: Address[] = []
  tempAddresses: Address[] = []
  validations: object = {}
  selected = ''
  // ID of last-added address
  lastAdded: string | null = null
  addressesApi: AddressesApi | null = null
  getUser: (() => object) | null = null
  deliveryOption = 'none'
}

export default new Vuex.Store({
  state: new State(),

  mutations: {
    initialize (state, {
      user,
      client,
      site,
      enableRExHubPickUp,
      usesRenewExpress,
      disableCustomAddress,
      enablePermanentAddressChange,
      additionalRExHubPickUpFields,
      pickupInstructions,
      requireClickToShowAddress,
      contactPhone,
    }: InstallParams) {
      state.user = user
      state.client = client
      state.site = site
      state.enableRExHubPickUp = enableRExHubPickUp
      state.usesRenewExpress = usesRenewExpress
      state.disableCustomAddress = disableCustomAddress
      state.enablePermanentAddressChange = enablePermanentAddressChange
      state.additionalRExHubPickUpFields = additionalRExHubPickUpFields
      state.pickupInstructions = pickupInstructions
      state.requireClickToShowAddress = requireClickToShowAddress
      state.contactPhone = contactPhone

      state.addressesApi = new AddressesApi({
        getJwt: () => user.getAccessToken(),
        exceptionLogger: sentryException,
      })
    },

    setGetUser (state, getUser) {
      state.getUser = getUser
    },

    setAddresses (state, addresses) {
      state.addresses = addresses
    },

    updateAddress (state, { index, address } = {}) {
      if (typeof index !== 'undefined' && address) {
        state.addresses = [
          ...state.addresses.slice(0, index),
          address,
          ...state.addresses.slice(index + 1),
        ]
      }
    },

    addTempAddress (state, address) {
      state.tempAddresses.push(address)
    },

    setTempAddresses (state, addresses) {
      state.tempAddresses = addresses
    },

    setValidAddress (state, key) {
      state.validations[key] = true
    },

    setInvalidAddress (state, key) {
      state.validations[key] = false
    },

    setValidations (state, validations) {
      state.validations = validations
    },

    setLastSelected (state, selected) {
      state.selected = selected
    },

    setLastAdded (state, id) {
      state.lastAdded = id
    },
  },

  getters: {
    api: (state) => {
      if (!state.addressesApi) {
        throw new Error('API not initialized')
      }
      return state.addressesApi
    },
  },

  actions: {
    async loadData ({ state, getters, commit }) {
      state.loadDataPromise ||= (async () => {
        if (!state.user?.loggedIn) {
          return
        }

        const apiAddressesResponse = await getters.api.getAddresses()
        const apiAddresses = apiAddressesResponse.data || []

        if (apiAddresses) {
          commit('setAddresses', apiAddresses.map((address) => {
            address.isSaved = true
            return new Address(address)
          }))
        }
        else {
          throw new Error('Server Error')
        }
      })()

      await state.loadDataPromise
    },

    addAddress: async ({ commit, state, getters }, address) => {
      const res = await getters.api.addAddress(address)

      if (res && res.data && res.data.id) {
        address.isSaved = true
        address.justSaved = true // we use this information to keep this sorted
        state.addresses.push(address)
        address.id = res.data.id
        commit('setAddresses', state.addresses)
      }
      else {
        throw new Error('Server Error')
      }
    },

    removeAddress: async ({ commit, state, getters }, addressId) => {
      const ret = await getters.api.removeAddress(addressId)

      if (ret) {
        const index = state.addresses.findIndex(add => add.id === addressId)
        if (index !== -1) {
          commit('setAddresses', [
            ...state.addresses.slice(0, index),
            ...state.addresses.slice(index + 1),
          ])
        }
      }
      else {
        throw new Error('Server Error')
      }
    },

    updateAddress: async ({ commit, state, getters }, address) => {
      const ret = await getters.api.updateAddress(address)

      if (ret) {
        const index = state.addresses.findIndex((add) => {
          return add.id === address.id
        })
        if (index !== -1) {
          commit('updateAddress', {
            index,
            address,
          })
        }
      }
      else {
        throw new Error('Server Error')
      }
    },

    updateLastUsedAddress: async ({ commit, state, getters }, addressId) => {
      const res = await getters.api.updateLastUsedAddress(addressId)
      const apiAddress = res.data

      if (apiAddress) {
        const updatedAddress = new Address(apiAddress)
        updatedAddress.justSaved = false // redundant, since the API won't note this

        const index = state.addresses.findIndex((add) => {
          return add.id === updatedAddress.id
        })
        if (index !== -1) {
          commit('updateAddress', {
            index,
            address: updatedAddress,
          })
        }
      }
      else {
        throw new Error('Server Error')
      }
    },

    resetTempAddresses: async ({ commit }) => {
      commit('setTempAddresses', [])
      commit('setValidations', {})
      commit('setLastSelected', 'billing')
      commit('setLastAdded', null)
    },

    // Gets a dummy payable that contains the Taxsys pickup offices details in the
    // custom parameters. This is an intentionally incorrect usage of payables here
    // as a temporary fix to avoid accessing the Taxsys internal API directly from
    // the frontend.
    // TODO: PSC-12694 to remove this misuse of payables when there is a better solution
    // to accessing Taxsys internal APIs.
    getOffices: async ({ state }, payablesAdaptor) => {
      const data = {
        query: {
          client: state.client,
          type: 'offices',
        },
      }
      const { payables: results } = await searchPayables({
        payablesAdaptor,
        data,
      })

      return { data: results[0]?.customParameters?.offices || [] }
    },

    getOfficeLocations: async ({ dispatch }, payablesAdaptor) => {
      let res
      try {
        const officePromise = dispatch('getOffices', payablesAdaptor)
        res = await withTimeout(
          officePromise,
          5 * 1000,
        )
      }
      catch (error) {
        if (isTimeout(error)) {
          throw new Error('Timed out communicating with payables: ' + error)
        }
        else {
          throw new Error('An error occurred communicating with payables: ' + error)
        }
      }

      if (!res || !res.data) {
        throw new Error('Unable to get office locations from payables: ' + JSON.stringify(res))
      }
      const data = res.data
      const locations = data.map(office => new Office(office))

      return locations
    },
  },
})
