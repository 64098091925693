/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calculator': {
    width: 16,
    height: 16,
    viewBox: '1895.441 1269.941 21.118 24.118',
    data: '<g transform="translate(1896 1270.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><rect pid="0" width="20" height="23" rx="1.118" stroke-width="1.118"/><path pid="1" stroke-width="1.095" d="M3 3h14v6H3z"/><path pid="2" d="M4 12h2v2M9 12h2v2M14 12h2v2M4 18h2v2M9 18h2v2M14 18h2v2"/></g>'
  }
})
