import Vue from 'vue'
import MyPayment from './models/MyPayment.js'
import { inflateDisplayType } from '@grantstreet/payables'

export default ({ user }) => {
  const state = {
    // Metadata about receipts (total records, number of pages)
    paginationMeta: {},

    // Cache of receipt data
    receipts: {},
  }

  const getters = {
    paginationMeta: state => state.paginationMeta,
  }

  const mutations = {}

  const actions = {
    async loadMyPayments ({ state, rootGetters }, { page, perPage }) {
      const { data } = await rootGetters['API/myPayments'].getPayments(page, perPage)
      const results = data.results
      const payments = await parsePayments({ data: results })

      Vue.set(state.paginationMeta, 'total', data.total)
      Vue.set(state.paginationMeta, 'totalPages', data.totalPages)

      // Could consider storing a per-page cache here. Would need to invalidate
      // when new receipts are created.
      return payments
    },

    async getReceipt ({ rootGetters, state }, receiptId) {
      const isInState = Boolean(state.receipts[receiptId])

      if (!isInState && !user.loggedIn) {
        throw new Error('Receipt not found')
      }

      if (!isInState) {
        const { data } = await rootGetters['API/myPayments'].getPayment(receiptId)
        state.receipts[receiptId] = data
      }

      const receipt = state.receipts[receiptId]
      if (receipt) {
        return receipt
      }
      else {
        throw new Error('Receipt not found')
      }
    },
  }

  return {
    namespaced: true,

    state,
    getters,
    mutations,
    actions,

    strict: process.env.NODE_ENV !== 'production',
  }
}

// Helper function to turn a list of backend payments into our frontend payment
// model
async function parsePayments ({ data }) {
  if (!data?.length) {
    return []
  }

  return data.map(({
    client,
    site,
    billing,
    confirmationNumber,
    transactions,
    postedDatetime,
    immediateProcessorDetails,
    tender,
    userId,
    id,
  }) => {
    const myPayment = new MyPayment({
      client,
      site,
      billing,
      confirmationNumber,
      transactions,
      postedDatetime,
      immediateProcessorDetails,
      tender,
      userId,
      id,
    })

    for (const transaction of myPayment.transactions) {
      for (const item of transaction.items) {
        item.configDisplayType = inflateDisplayType({ displayType: item.displayType })
      }
    }

    return myPayment
  })
}
