<template>

  <div>
    <div class="gh-page-padding pb-4 mb-n2 mb-md-n3">
      <div
        v-if="$wait.is('loading cart') || $wait.is('loading e-wallet')"
        class="bg-white rounded-xl p-4"
      >
        <LoadingBars>{{ isRedirect ? $t('redirect.loading') : $t('loading.default') }}</LoadingBars>
      </div>

      <div
        v-else-if="initFailure"
        class="text-danger text-center"
      >{{ $t('cart.initialization_error') }}</div>

      <div v-else>
        <slot />
      </div>
    </div>

    <b-container fluid>
      <template v-if="useExternalCheckout && items.length > 0">
        <section
          :aria-label="title || $t('checkout.payment')"
          class="bg-white rounded-xl p-4 mb-2 mb-md-3"
        >
          <h2>{{ $t('checkout.payment') }}</h2>
          <b-row class="payment-info-footer pt-3">
            <b-col
              sm="12"
              md="12"
              lg="5"
              class="d-flex align-items-center flex-column overflow-wrap"
            >
              <strong
                class="text-body font-weight-bold ml-sm-5 w-100"
              >
                <span
                  v-dompurify-html="$t(`policy_agreement.notice`, { buttonText })"
                />
                <p>
                  {{ $t('outbound_redirect_notice') }}
                </p>
              </strong>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="7"
              class="d-flex justify-content-end"
            >
              <OutboundRedirectButton />
            </b-col>
          </b-row>
        </section>
      </template>
    </b-container>
    <div class="gh-page-padding d-flex align-content-end justify-content-end">
      <b-button
        v-if="showCancelEmitButton"
        variant="outline-primary"
        class="mb-4"
        data-test="cancel-cart-button"
        @click="$emit('cancel-cart')"
      >
        {{ $t('common.cancel') }}
      </b-button>
      <template v-else-if="!hideCancelUrlButton">
        <CancelCartButton
          v-if="!useReturnButton"
          class="mb-4"
        />
        <HomepageLink
          v-else
          class="mb-4"
        />
      </template>
    </div>
  </div>
</template>

<script>
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import CancelCartButton from './CancelCartButton.vue'
import OutboundRedirectButton from './OutboundRedirectButton.vue'
import HomepageLink from './HomepageLink.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import { mapGetters } from 'vuex'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  emits: ['cancel-cart'],
  components: {
    CancelCartButton,
    OutboundRedirectButton,
    LoadingBars,
    HomepageLink,
  },

  mixins: [scrollToMixin],

  props: {
    // If this is false and the cart redirect data included a cancel URL, a
    // cancel button linking to that URL will be displayed. If this is true,
    // that cancel button will be hidden no matter if there was a cancel URL
    // (though a cancel button can still be shown that simply emits a "cancel"
    // event - see showCancelEmitButton).
    hideCancelUrlButton: {
      type: Boolean,
      default: false,
    },
    // If this is true, a "Cancel" button will be shown that emits a cancel
    // event. This overrides hideCancelUrlButton (if that is false this emit
    // button will still be used instead).
    showCancelEmitButton: {
      type: Boolean,
      default: false,
    },
    initFailure: {
      type: Boolean,
      default: false,
    },
    isRedirect: {
      type: Boolean,
      default: false,
    },
    useReturnButton: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('Cart', [
      'items',
    ]),
    ...mapConfigGetters([
      'useExternalCheckout',
    ]),

    buttonText () {
      return this.$t('continue_to_payment.label')
    },
  },
}
</script>

<style lang="scss">

.payment-info-footer {
  .custom-control {
    z-index: inherit;
  }

.section-heading {
  background: $light-2;
  font-weight: normal;
  padding: 0.625rem 1rem;
}
}
</style>
