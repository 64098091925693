/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dropdown-up-down-arrows': {
    width: 16,
    height: 16,
    viewBox: '0 0 10 12',
    data: '<path pid="0" d="M0 7.85h10L5.09 12zm10-3.7H0L4.91 0z"/>'
  }
})
