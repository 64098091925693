/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'broken-link-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#be2c37" d="M12.439 17.693a.897.897 0 00-.898.896v3.514a.897.897 0 00.898.897.897.897 0 00.896-.897V18.59a.897.897 0 00-.896-.896zm6.15-6.15a.897.897 0 00-.898.896.897.897 0 00.898.899h3.514a.897.897 0 00.897-.899.897.897 0 00-.897-.896zm-2.636 3.513a.897.897 0 00-.635.262.897.897 0 000 1.27l3.516 3.513a.897.897 0 001.267 0 .897.897 0 000-1.267l-3.514-3.516a.897.897 0 00-.634-.262zM1.896 11.543a.897.897 0 00-.897.896.897.897 0 00.897.899H5.41a.897.897 0 00.897-.899.897.897 0 00-.897-.896zm2.636-7.909a.897.897 0 00-.634.264.897.897 0 000 1.268L7.41 8.68a.897.897 0 001.27 0 .897.897 0 000-1.27L5.165 3.899a.897.897 0 00-.633-.264zM11.56 1a.897.897 0 00-.897.896v3.516a.897.897 0 00.897.896.897.897 0 00.896-.896V1.896A.897.897 0 0011.56 1zm-.684 12.138a3.405 3.405 0 00-4.797 0L2.155 17.06a3.406 3.406 0 00-.001 4.8 3.407 3.407 0 004.799-.002l3.923-3.924a3.405 3.405 0 000-4.797zM9.61 14.405a1.577 1.577 0 010 2.263L5.685 20.59a1.577 1.577 0 01-2.262 0 1.577 1.577 0 010-2.262l3.923-3.924a1.577 1.577 0 012.263 0zm11.81-11.81a3.405 3.405 0 00-4.797 0l-3.925 3.924a3.405 3.405 0 000 4.798 3.405 3.405 0 004.798 0l3.925-3.925a3.405 3.405 0 000-4.798zm-1.267 1.267a1.577 1.577 0 010 2.262l-3.925 3.925a1.577 1.577 0 01-2.262 0 1.577 1.577 0 010-2.262l3.925-3.925a1.577 1.577 0 012.262 0z"/>'
  }
})
