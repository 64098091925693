/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tourist-tax': {
    width: 16,
    height: 16,
    viewBox: '0 0 19.54 21.537',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"><path pid="0" data-name="Path 1313" d="M.75 8.466c4.4-2.64 6.16-1.76 8.8-1.76-2.64-3.52-7.04-3.52-8.8 1.76z"/><path pid="1" data-name="Path 1314" d="M18.35 8.466c-4.4-2.64-6.16-1.76-8.8-1.76 2.64-3.52 7.04-3.52 8.8 1.76z"/><path pid="2" data-name="Path 1315" d="M9.904 6.355c-.264-4.4 2.992-7.3 7.832-4.4a12.654 12.654 0 00-3.784 1.232"/><path pid="3" data-name="Path 1316" d="M10.87 18.147a18.345 18.345 0 01-1.32-11.44h.88c0 5.28.88 7.04 3.872 11.7 2.64.44 4.488 1.32 4.488 2.376H2.95c0-1.408 3.52-2.64 7.92-2.64z"/></g>'
  }
})
