import {
  App,
  markRaw,
  // *DO NOT* use getCurrentInstance outside of plugin development. It's an
  // intentionally undocumented api. It's not intended for application
  // development but is exposed and widely used for plugin development. This is
  // exactly how pinia and other libraries work
  getCurrentInstance as dangerouslyGetCurrentInstance,
  inject,
} from 'vue'
import storage from '@grantstreet/psc-js/utils/safe-local-storage.js'

export type SerializableType = string | number | boolean | null | undefined

export type SerializableObject = {
  [key: string]: SerializableType | SerializableObject | Array<SerializableObject | SerializableType>
}

export type Callback = (SerializableObject?: SerializableObject) => void

export type CallbackMap = {[key: string]: Callback}

export type ActionEntry = [string, SerializableObject?]

export type GsgCallbackActionsOptions = {
  applicationId: string
  entries?: CallbackMap
}

export type GsgCallbackActionsUtilities = {
  registerCallback: (key: string, callback: Callback) => void
  registerCallbacks: (entries: CallbackMap) => void
  queueAction: (...args: ActionEntry) => void
  queueActions: (entries: Array<ActionEntry>) => void
  runCallbacks: () => ReturnType<typeof Promise.allSettled>
  // Consumers should dictate when callbacks run in their definition. E.g. If an
  // action shouldn't run until after authentication has finished or a module
  // has loaded then it should await those inside the action. The parent app
  // should runCallbacks as soon as the minimum viable environment is
  // established and registering entities can determine for themselves any
  // additional requirements. (This is why callbacks run run all at once.
  // They're not event bus handlers responding to current events.)
}

export type GsgCallbackActions = {
  install: (app: App, gsgCallbackActionsOptions: GsgCallbackActionsOptions) => void
  _app: App
  _applicationId: string
  _utilities: GsgCallbackActionsUtilities
  _callbacks: {[key: string]: Callback}
  _futureActionQueue: Array<ActionEntry>
  _toRun: Array<ActionEntry>
}

// Used to inject the plugin instance for this module's own use. Please don't
// expose this elsewhere.
const GsgCallbackActionsSymbol = Symbol('@grantstreet/callback-actions')

/**
 * @function useCallbackActions Returns utilities for a plugin instance. Within
 * vue components this will infer the current vue application and plugin
 * instances. In all other cases the plugin instance must be passed in.
 * @param  {GsgCallbackActions | App} [context] - Optional plugin or vue app
 * instance to get utilities from.
 * @return {GsgCallbackActionsUtilities} - The utilities.
 */
export const useCallbackActions = (context?: GsgCallbackActions | App) => {
  // In vue contexts (inside components etc) the plugin instance will be
  // injected. Otherwise it will need to be passed in.

  let plugin: GsgCallbackActions | undefined

  if (!context && dangerouslyGetCurrentInstance()) {
    // Using getCurrentInstance allows us to inject the vue app in vue contexts.
    // That means that the plugin instance data can be stored on the vue
    // instance rather than inside this package. That in turn means each vue
    // instance can keep track of its own plugin across package boundaries
    // without the need to import anything from the application package.
    //
    // E.g. cart-vue can useCallbackActions() and get the correct plugin
    // instance for the govhub-vue application without the need to import
    // anything directly from govhub-vue, which it can't do.
    //
    // This is what allows @grantstreet/callback-actions to stick to its own
    // concerns and avoid managing the access of instance data directly.
    plugin = inject(GsgCallbackActionsSymbol, undefined)
  }
  else if (context) {
    plugin = '_utilities' in context ? context : context.config.globalProperties.$gsgCallbackActions
  }

  if (!plugin) {
    throw new TypeError('@grantstreet/callback-actions: useCallbackActions was called but there was no active instance. Are you trying to useCallbackActions before calling "app.use(createCallbackActions())" or, calling useCallbackActions outside of a Vue application context without passing a plugin instance?')
  }
  if (!plugin._app) {
    throw new TypeError('@grantstreet/callback-actions: The plugin instance passed to useCallbackActions has not yet been installed.')
  }

  return plugin._utilities
}

/**
 * @function createCallbackActions
 * This creates a in instance of the vue plugin which should then be
 * app.use()ed.
 * @return  {GsgCallbackActions} - The plugin instance
 */
export const createCallbackActions = (): GsgCallbackActions => {
  // This plugin object can't be a class instance. Vue will bind the install
  // method to it's own instance so `this` wouldn't be helpful. It's easier to
  // create the object in a closure and let it use the scoped reference to
  // itself.
  const plugin = markRaw({
    install (
      app: App,
      {
        applicationId,
        entries,
      }: GsgCallbackActionsOptions,
    ) {
      plugin._app = app
      // This should be unique to the application to prevent localStorage
      // collisions
      plugin._applicationId = applicationId
      // In vue contexts (inside components etc) the plugin instance will be
      // injected. This is how useCallbackActions works.
      app.provide(GsgCallbackActionsSymbol, plugin)
      app.config.globalProperties.$gsgCallbackActions = plugin

      const localStorageKey = `${GsgCallbackActionsSymbol.toString()}_${plugin._applicationId}`

      // These are what is returned from useCallbackActions
      plugin._utilities = {
        registerCallback: (key: string, callback: Callback) => {
          if (plugin._callbacks[key]) {
            throw new TypeError(`@grantstreet/callback-actions: Callback "${key}" is already registered.`)
          }
          plugin._callbacks[key] = callback
        },

        registerCallbacks: (entries: CallbackMap) =>
          Object.entries(entries).forEach(entry => plugin._utilities.registerCallback(...entry)),

        queueAction: (...[key, data]: ActionEntry) => {
          if (!plugin._callbacks[key]) {
            console.warn(`@grantstreet/callback-actions: "${key}" is not a registered callback action at this time and therefore may not run at a future date. This may be an error.`)
          }
          plugin._futureActionQueue.push([key, data])

          // Multiple instances can't manage the same applicationId. We could
          // change that but it seems safer not to risk double running actions
          // if there was ever a bug where two instances weren't supposed to
          // coexist and did.
          storage.setItem(localStorageKey, JSON.stringify(plugin._futureActionQueue))
        },

        queueActions: (entries: Array<ActionEntry>) =>
          entries.forEach(entry => plugin._utilities.queueAction(...entry)),

        runCallbacks: () => Promise.allSettled(
          plugin._toRun.map(([key, data]) =>
            plugin._callbacks[key]
              ? plugin._callbacks[key](data)
              : Promise.reject(new TypeError(`@grantstreet/callback-actions: The stashed action "${key}" does not have a registered callback at this time and therefore cannot be completed.`)),
          ),
        ),
      }

      // Register any passed entries after basic setup is complete
      if (entries) {
        plugin._utilities.registerCallbacks(entries)
      }

      // Hydrate any stashed actions from a previous instance. This must be done
      // immediately so that the previous stash can't be overridden.
      const toRun = storage.getItem(localStorageKey)
      if (toRun) {
        try {
          plugin._toRun = JSON.parse(toRun)
        }
        catch {
          console.error(`@grantstreet/callback-actions: Callback actions were not able to be hydrated from the ${localStorageKey} stash. Actions will not be run.`)
        }
      }
      // Reset the cache so that future instances don't repeat actions if
      // nothing new is queued by this instance
      storage.setItem(localStorageKey, JSON.stringify([]))
    },

    // It would be nice if this could be a Definite Assignment Assertion but
    // apparently ts doesn't support those in this syntax. It _is_ definitely
    // assigned during install.
    _app: null as unknown as App,
    _applicationId: '',
    _utilities: {} as GsgCallbackActionsUtilities,
    _callbacks: {},
    _futureActionQueue: [],
    _toRun: [],
  } as GsgCallbackActions)

  return plugin
}
