/**
 * Client for the My Things service API
 */

import GenericApiClient from '@grantstreet/api-client'
import { baseGovHubUrl } from '@grantstreet/psc-environment'

// Unfortunately we have to deep-import this, because the Payables index imports
// a Vue component and Playwright dies if it encounters a Vue component.
//
// TODO PSC-17563: Once the Payables exports are exported from separate files,
// we can update this to only import from the util file.
//
// eslint-disable-next-line import/no-internal-modules
import { encodePayablePath } from '@grantstreet/payables/src/utils.ts'

export default class Client extends GenericApiClient {
  constructor (opts) {
    super(opts)

    this.axios.defaults.headers.post['Content-Type'] = 'application/json'

    // TODO: move this to `${baseGovHubUrl}/svc/myitems/api/v2`
    this.baseUrl = process.env?.GSG_MYTHINGS_SERVICE || `${baseGovHubUrl}/svc/mythings`
  }

  ping () {
    return this.get('/api/v2/ping')
  }

  getSavedItems (client, site) {
    return this.get(`/api/v2/items/${client}/${site}`)
  }

  saveItem (client, site, payableUrl) {
    return this.put(`/api/v2/items/${client}/${site}/${encodePayablePath(payableUrl)}`)
  }

  unsaveItem (client, site, payableUrl) {
    return this.delete(`/api/v2/items/${client}/${site}/${encodePayablePath(payableUrl)}`)
  }
}
