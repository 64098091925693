import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  schemaValidationException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // TODO: Rename to e-wallet-vue
    // e-wallet-ui-prod and -nonprod projects.
    prod: 'https://ded410a97a884358bf82242a9ee20565@o168195.ingest.sentry.io/1242931',
    nonprod: 'https://014231ea63c54e45bcc80889e7a75913@o168195.ingest.sentry.io/5827659',
  },

  clientOptions: {
    whitelistUrls: [/e-wallet\.grantstreet\.com\/.*\.js/],
  },
})
