import { Payable } from '@grantstreet/payables'

export default class {
  // TODO PSC-18566 other fields are definitely here
  // See eg. https://bitbucket.grantstreet.com/projects/UX/repos/gsg-payhub-ui-mono/browse/%40grantstreet/cart-vue/src/models/Transaction.js?at=06518416f396fbd7e42f26d4e8d159f75c8d519f#5-13
  payable: Payable
  userParameters?: object

  constructor (payable: Payable, item: object) {
    Object.assign(this, item)
    this.payable = payable
  }

  // Return a boolean indicating whether an item in cart
  // should have its quantity be editable
  get canModifyQuantityDirectly () :boolean {
    if (!this.payable.isQuantityModifiable) {
      return false
    }

    // payables with freeform fields cannot have quantity modified
    return this.userParameters === undefined
  }
}
