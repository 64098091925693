/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fullscreen-enter': {
    width: 16,
    height: 16,
    viewBox: '1543.793 1119.793 24.414 24.414',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1544.5 1129.5v-9h9M1567.5 1134.5v9h-9M1558.5 1122.5h7v7M1553.5 1141.5h-7v-7M1544.5 1120.5l8 8M1559.5 1135.5l8 8"/></g>'
  }
})
