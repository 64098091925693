/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'login-icon-loggedin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 1a7 7 0 100 14A7 7 0 008 1zM6.119 5.83A2.03 2.03 0 017.65 3.888a1.881 1.881 0 012.231 1.854v.753A1.881 1.881 0 017.65 8.341 2.03 2.03 0 016.119 6.4V5.83zM8 13.381a5.38 5.38 0 01-4.506-2.441 7.114 7.114 0 019.012 0A5.382 5.382 0 018 13.381z" _fill="#0077B3"/><defs><clipPath id="clip0_318_2361"><path pid="1" _fill="#fff" transform="translate(19 6)" d="M0 0h8v5H0z"/></clipPath></defs>'
  }
})
