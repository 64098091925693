import { pushRouteWithState } from '@grantstreet/psc-js/utils/routing.js'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'

export default {
  methods: {
    async checkOut ({
      // Vue "this"
      context,

      // Original checkout arguments
      tender,
      extraFields,
      address,

      // Additional checkout arguments
      user,

      // Functions used for checking out
      addAddress,
      updateLastUsedAddress,
      resetTempAddresses,
      sentryException,

      // EventBus
      eventBus,
    }) {
      context.$wait.start('checking out')

      let payment
      let scheduleMessages
      let deliveryMethod

      if (context.cart.needsDelivery) {
        if (context.useRenewExpress) {
          // For REx, on-file and pick up do not accept a delivery address from the user
          if (context.selectedDeliveryOption === 'onFile' || context.deliveryOption === 'pickUp') {
            address = null
          }

          // For REx, address changes and on-file delivery are shipping
          if (address !== null || context.selectedDeliveryOption === 'onFile') {
            deliveryMethod = 'shipping'
          }

          if (context.deliveryOption === 'pickUp') {
            deliveryMethod = 'pick_up'
          }
        }
        else {
          deliveryMethod = 'shipping'
        }
      }

      try {
        const hasSchedpayEnrollments = context.useSchedPay && context.cart.enrollInAutopay
        const enrolledItems = hasSchedpayEnrollments
          ? context.cart.items.filter(({ enrollInAutopay, scheduledPaymentAgreement }) => enrollInAutopay && scheduledPaymentAgreement)
          : []

        const paymentOptions = {
          tender,
          extraFields,
          delivery: address,
          deliveryMethod,
        }

        if (hasSchedpayEnrollments) {
          const scheduledPaymentEnrollments = await context.$store.dispatch('SchedPay/buildCheckOutScheduleEnrollments', {
            items: enrolledItems,
          })

          paymentOptions.scheduledPaymentEnrollments = scheduledPaymentEnrollments
        }

        // What would we do here if there was no payment data, but also no error?
        payment = (await context.$store.dispatch('Cart/checkOut', paymentOptions))?.data || {}

        // Don't attempt unless the checkout succeeds
        // Ensure that schedpay is installed
        if (hasSchedpayEnrollments) {
          // Search schedules for each autopay item and report errors
          scheduleMessages = await context.$store.dispatch('SchedPay/getCheckOutScheduleErrors', {
            items: enrolledItems,
          })
          scheduleMessages = scheduleMessages.map(({ success, displayName }) => {
            return {
              success,
              message: context.$t(
                success ? 'autopay.success' : 'autopay.error',
                { displayName },
              ),
            }
          })
          // TODO: Send emails about failures PSC-6179
        }
      }
      catch (error) {
        context?.handleError(error)
        context.$wait.end('checking out')
        return
      }

      let deliverySaveError = false
      if (address) {
        if (address.billingSave) {
          try {
            await addAddress(address)
          }
          catch (error) {
            deliverySaveError = true
            sentryException(error)
          }
        }
        else if (address.isSaved) {
          try {
            await updateLastUsedAddress(address.id)
          }
          catch (error) {
            // This isn't a huge deal, actually.
            // It's just possible we won't sort their addresses correctly
            sentryException(error)
          }
        }

        await resetTempAddresses()
        await context.$store.commit('Cart/setDeliveryOption', '') // reset delivery option
      }

      eventBus.$emit('cart.checkout')

      if (context.emitInsteadOfRoute) {
        context.$emit('payment-complete')
        return
      }

      context.$wait.end('checking out')

      // Create a copy of the items array that will survive after the
      // cart is emptied so we can display them on the receipt
      const items = context.cart.items.slice()

      showReceipt({
        context,
        items,
        extraFields,
        tender,
        payment,
        address,
        scheduleMessages,
        deliverySaveError,
      })
      // We retire the old cart and load a new one. See action.
      await context.$store.dispatch('Cart/retireCart')
    },
    // This workflow is for checking out with immediate Paypal from the confirmation page
    // The orderID is passed from the Paypal window to here to complete checkout
    async checkOutPaypalOrderId ({
      context,
      orderId,
      contactPreference,
      loggedIn,
      eventBus,
    }) {
      context.$wait.start('checking out')
      const payment = (await context.$store.dispatch('Cart/approvePaypalOrder', { orderId, contactPreference, loggedIn }))

      const id = payment.confirmation_number

      eventBus.$emit('cart.checkout')

      // We do this check on the confirmation page as well.
      // This is for clients that use the checkout widget but
      // want to display their own receipt.
      if (context.config.checkoutWidget?.showReceipt === false) {
        context.$emit('payment-complete')
      }
      else if (context.urls.receipt) {
        context.$router.push({
          name: 'receipt-redirect',
          params: {
            ...context.$route.params,
            receiptId: id,
          },
        })
      }
      else {
        pushRouteWithState(context.$router, {
          name: 'receipt',
          params: {
            ...context.$route.params,
            receiptId: id,
          },
          state: {
            // The payment response comes from the onApprove callback
            payment,
            // TODO: context.chosenTender does not have enough data to render
            // anything useful in the receipt for the tender. Later,
            // when the Cart service returns the PayHist receipt, we
            // can use that information here.
            tender: context.chosenTender,
            showSurvey: true,
            urls: context.urls,
          },
        }).catch(error => {
          // Check to see if this is an informational error about how your
          // router push was redirected somewhere else. In this case, it's
          // because of the if block in the router beforeEach that adds site
          // to named routes. See:
          // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
          // for more info. If not, throw the original error, otherwise
          // proceed as usual.
          if (!isNavigationFailure(error, NavigationFailureType.redirected)) {
            throw error
          }
        })
      }
      context.$wait.end('checking out')
      // We retire the old cart and load a new one. See action.
      await context.$store.dispatch('Cart/retireCart')
    },
  },
}

async function showReceipt ({
  context,
  payment,
  tender,
  extraFields,
  items,
  scheduleMessages,
  deliverySaveError = false,
}) {
  const receiptId = payment.confirmation_number

  // If the client provided a url to redirect back to then redirect to the
  // receipt-redirect page and send along the receipt id so the
  // receipt-redirect page can redirect them where they need to go without
  // showing them an empty cart in the meantime.
  if (context.urls.receipt) {
    context.$router.push({
      name: 'receipt-redirect',
      params: {
        ...context.$route.params,
        receiptId,
      },
    })
    return
  }

  pushRouteWithState(context.$router, {
    name: 'receipt',
    params: {
      ...context.$route.params,
      receiptId,
    },
    state: {
      extraFields,
      payment,
      items,
      tender,
      showSurvey: true,
      scheduleMessages,
      deliverySaveError,
      urls: context.urls,
      oldUserId: context.userId,
    },
  })
}
