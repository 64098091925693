<template>
  <!-- This is critical to prevent a pre-expanded NotificationPopper from closing
  on click -->
  <div
    @click="stopPropagation"
  >

    <b-modal
      v-if="renderModal"
      ref="introModal"
      hide-footer
      :size="modalSize"
      hide-header
      body-class="intro"
      data-test="intro-modal"
      static
      @hide="delayDismantle"
    >

      <span class="d-flex flex-row justify-content-end align-items-end pt-3 pr-3">
        <a
          class="cursor-pointer"
          @click="hide"
        >
          <svgicon
            :fill="false"
            data-test="modal-header-close"
            icon="x"
          />
        </a>
      </span>

      <div class="px-5 pt-3 pb-4 d-flex flex-column align-items-center">
        <strong class="title-font mb-2">
          {{ config.welcome.title[$i18n.locale] }}
        </strong>
        <div class="text-center">
          {{ config.welcome.description[$i18n.locale] }}
        </div>
        <div
          v-if="payableSources.length > 1"
          class="text-center"
        >
          <small>{{ $t('welcome.payable_sources_disclaimer') }}</small>
        </div>
        <b-row class="my-4 w-100 justify-content-center">
          <b-col
            v-for="(feature, index) in featureList"
            :key="feature.key"
            :cols="12"
            :md="12 / featureList.length"
            class="py-2 my-2 d-flex flex-column align-items-center"
            :class="{ 'border-left' : index > 0 && windowSize >= sizes.MD,
                      'border-top' : index > 0 && windowSize < sizes.MD }"
          >
            <img
              :src="feature.image"
              :alt="$t(feature.title)"
              class="d-none d-md-block"
              height="100"
              width="100"
            >
            <h4 class="mt-4 mb-2">{{ $t(feature.title) }}</h4>
            {{ $t(feature.description) }}
          </b-col>
        </b-row>
        <button
          class="btn btn-primary"
          data-test="intro-modal-button"
          type="button"
          @click="() => {
            hide()
            delayDismantle()
          }"
        >
          {{ $t("intro.button") }}
        </button>
      </div>

    </b-modal>
    <span v-else />

  </div>
</template>

<script>
import { mapConfigState, mapConfigGetters } from '@grantstreet/psc-config'
import featureDetails from './FeatureDetails.js'
import Vue from 'vue'

export default {
  data () {
    return {
      Vue,
      enabled: false,
      renderModal: false,
      window: {
        width: 0,
      },
      sizes: {
        XS: 0,
        SM: 1,
        MD: 2,
        LG: 3,
        XL: 4,
      },
    }
  },

  computed: {
    windowSize () {
      if (this.window.width >= 1200) {
        return this.sizes.XL
      }
      else if (this.window.width >= 992) {
        return this.sizes.LG
      }
      else if (this.window.width >= 768) {
        return this.sizes.MD
      }
      else if (this.window.width >= 576) {
        return this.sizes.SM
      }
      else return this.sizes.XS
    },

    modalSize () {
      if (this.windowSize >= this.sizes.MD) {
        return this.featureList.length > 2 ? 'xl' : this.featureList > 1 ? 'lg' : 'md'
      }
      else {
        return 'md'
      }
    },

    featureList () {
      // up to 3 features may be shown at once
      return featureDetails.filter((feature) =>
        this.config.welcome.features.includes(feature.key),
      ).slice(0, 3)
    },

    ...mapConfigState(['config']),
    ...mapConfigGetters(['payableSources']),
  },

  mounted () {
    // The error pages opt out of the modal.
    if (this.$route.meta.skipModal) return

    if (this.config.welcome && this.config.welcome.display) {
      // whenever we show the intro, we store the array of features shown in localStorage
      // when determining whether to show the intro, we lookup this array and see whether they've
      // changed (or don't exist)
      let lastFeaturesSeen
      try {
        lastFeaturesSeen = window.localStorage.getItem('payhub-intro-last-features-seen')
      }
      catch (error) {
        console.warn('Cannot access local storage due to incognito window')
      }
      const currentFeatures = String(this.config.welcome.features)
      if (lastFeaturesSeen !== currentFeatures) {
        this.enabled = true
        try {
          window.localStorage.setItem('payhub-intro-last-features-seen', currentFeatures)
        }
        catch (error) {
          console.warn('Cannot access local storage due to incognito window')
        }
      }

      // keep track of window size
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
      this.render()

      if (this.config.welcome.timeLimit) {
        setTimeout(this.hide, 20 * 1000)
      }
    }
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    async render () {
      this.renderModal = true
      await this.$nextTick()
      this.show()
    },

    dismantle () {
      this.renderModal = false
    },

    // Give it time to animate out
    delayDismantle () {
      setTimeout(this.dismantle, 300)
    },

    show () {
      if (this.config.welcome.display && this.enabled) {
        this.$refs.introModal.show()
      }
      else {
        // If we try to show the modal when we shouldn't, remove it from the DOM
        this.dismantle()
      }
    },

    hide () {
      if (!this.$refs.introModal) return
      this.$refs.introModal.hide()
    },

    handleResize () {
      this.window.width = window.innerWidth
    },

    // This is critical to prevent a pre-expanded NotificationPopper from
    // closing on click
    stopPropagation (event) {
      event.stopPropagation()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .intro.modal-header {
  .modal-title {
    // Make title push content down revealing header-close, even when empty
    height: 0.75em;
  }
}
::v-deep .intro.modal-body {
  padding: 0;
}

.title-font {
  font-size: 2rem;
  color: black;
}
</style>
