/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'inbox': {
    width: 16,
    height: 16,
    viewBox: '1794 1170 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1794.5 1185.5h6.13a1.031 1.031 0 01.977.8 3 3 0 002.893 2.2h3a3 3 0 002.893-2.2 1.031 1.031 0 01.977-.8h6.13M1798.5 1182.5v-12h11.969l3.031 3.063v8.937M1803 1176h6M1803 1180h6"/><path pid="1" d="M1816.5 1176.5l1 9v6a2 2 0 01-2 2h-19a2 2 0 01-2-2v-6l1-9"/></g>'
  }
})
