import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'

import { createApp, h, type App } from 'vue'
import { createVueWait } from 'vue-wait'
import LoadFailure from './views/LoadFailure.vue'
import createApplicationStore from './store/index.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import '@grantstreet/bootstrap/icons/js/index.js'
import VueGtag, { event as logAnalyticsEvent } from 'vue-gtag'
import { createHead } from '@unhead/vue'
import { sentryException, vueErrorHandler } from './sentry.js'
import { initRouter } from './router.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import AppComponent from './components/App.vue'
import { installModule } from '@grantstreet/psc-vue/utils/install-utils.js'

// PSP widgets
import eWallet from '@grantstreet/e-wallet-vue'
import { initEWalletHelpers, useEWalletHelpers } from './e-wallet-helpers.js'
import MyItems from '@grantstreet/my-things-vue'
import MyPayments from '@grantstreet/my-payments-vue'
import Announcements from '@grantstreet/announcements-vue'
import Help from '@grantstreet/help-vue'
import Cart, { createCallbackActions as createCartCallbackActions } from '@grantstreet/cart-vue'
import SchedPay, { createCallbackActions as createSchedPayCallbackActions } from '@grantstreet/schedpay-vue'
import { installEBillingPublic } from '@grantstreet/e-billing-public'
import { installUserVerification } from '@grantstreet/user-verification-public'
import { installDeliveryMethod } from '@grantstreet/delivery-method'
import { getPayableSource, installPayables, searchPayablesPath } from '@grantstreet/payables'
import { installFormsPublic } from '@grantstreet/forms-public-legacy'
import { installDonations } from '@grantstreet/donations'
import installIndexSearch from '@grantstreet/index-search'
import installRouterSync from '@grantstreet/router-sync'
import { installConfig, getClientsAndSites, loadConfig, configState, configGetters, setLDMetadata } from '@grantstreet/psc-config'
import { CallbackMap, createCallbackActions, useCallbackActions } from '@grantstreet/callback-actions'
import {
  createUserPlugin,
  useGsgUser,
  type User,
} from '@grantstreet/user'
import {
  GsgLoginOptions,
  type GsgLoginUtilities,
  createLogin,
  handleOidcCallbackPage,
  useLogin as useLoginUtils,
} from '@grantstreet/login'
import { initLoginHelpers, stubLoginHelpers } from './login-helpers.js'

import './styles/index.js'

// APIs
import EnvironmentApi from '@grantstreet/psc-environment-api/api-client.js'
import AnnouncementsApi from '@grantstreet/announcements-vue/src/api-client.js'
import CartApi from '@grantstreet/cart-vue/src/api-client.js'
import ContactApi from '@grantstreet/help-vue/src/api-client.js'
import MyPaymentsApi from '@grantstreet/my-payments-vue/src/api-client.js'
import MyItemsApi from '@grantstreet/my-things-vue/src/api-client.js'
import LoginApi from '@grantstreet/login-api'
import RequestApi from '@grantstreet/request-api'
import SchedPayApi from '@grantstreet/schedpay-vue/src/api-client.js'

import type { GoogleAnalytics } from './embedded-demo/EmbeddedDemoUtils.js'
import { loadTranslations, i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { setEnvironment, setLocalTaxSysSandboxUrl, setRootTaxSysIframeUrl } from '@grantstreet/psc-environment/environment.js'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { NavigationFailureType } from 'vue-router'
import VueSignaturePad from 'vue-signature-pad'

import { plugin as formKitPlugin } from '@formkit/vue'
import createFormKitConfig from '../formkit.config.js'
import type { Store } from 'vuex'

declare let window: Window & {
  __VUE_DEVTOOLS_GLOBAL_HOOK__
}

// This state type isn't good because createApplicationStore is still js. When
// we convert it to TS this can be defined internally.
type ApplicationState = ReturnType<typeof createApplicationStore>
type ApplicationStore = Store<ApplicationState>

// This has to be untyped or else ts will complain at a conditional that this
// will always be defined
let logDiagnostics

const logBootTime = () => {
  // Log how long it took to install GovHub (window.performance.now() gives the
  // number of milliseconds since timeOrigin, which is when page load started)
  if (window.performance?.now && logDiagnostics) {
    logDiagnostics({
      event: 'timing-install-finished',
      duration: window.performance.now(),
    })
  }
}

// Sets the user's language and loads PayHub's translations. If the user already
// has a locale saved in localStorage this uses that. If the user doesn't have
// a locale saved it will attempt to use the browsers default languages
const initI18n = () => {
  const defaultLocale = safeLocalStorage.getItem('payhubDefaultLocale') ||
    // Loop through browsers languages but default to en
    window.navigator.languages.find(lang => {
      const code = lang.slice(0, 2)
      return code === 'es' || code === 'en'
    })?.slice(0, 2) || 'en'

  if (defaultLocale) {
    i18n.global.locale.value = defaultLocale
  }
  loadTranslations(sentryException)
}

type embeddedAttachPayload = {
  googleAnalytics: GoogleAnalytics
  language: string
  localTaxSysUrl: string
  rootTaxSysIframeUrl: string
}
let embeddedParentWhisperer
// This embeddedAttachPromise will be resolved by the embeddedParentWhisperer
// when the attach message is received.
let resolveEmbeddedAttachPromise: (value: embeddedAttachPayload) => void
const embeddedAttachPromise: Promise<embeddedAttachPayload> = new Promise(resolve => {
  resolveEmbeddedAttachPromise = resolve
})

let loginSucceeded: boolean

// Do not add blocking requests to this method. Everything should be loaded
// asynchronously so that the page skeleton can be rendered ASAP.
export default async function install (Vue) {
  try {
    // Init translations, and bootstrap first thing so we can show a localized
    // error page if anything goes wrong.
    // XXX: Is there any reason not to move this into the installing
    // application?
    // XXX: I think it would be smart to try and make this instance of the error
    // page not depend on the i18n plugin and its deps.
    // (Sentry is already initialized.)
    initI18n()

    initBootstrapVue(Vue)

    // Create an iframe whisperer. If this is a "full" govhub installation, this
    // whisperer will not send or receive any messages.
    // If this is an "embedded" govhub installation, this whisperer will be used
    // to communicate with the parent wrapper script.
    // see @grantstreet/govhub-ui/src/main-embedded-wrapper.ts
    // This parent whisperer must be created before the iframe load event is
    // fired, otherwise the wrapper attach message will get dropped.
    embeddedParentWhisperer = new iframeWhisperer.ToParent({
      // autoHeight must be false since this is a standalone whisperer for full
      // govhub installations
      autoHeight: false,
      messageSource: 'embedded-public-site',
      actions: {
        // The attach message is sent from the wrapper script. Currently, it
        // does nothing. It will include some required information such as
        // google analytics information in the future
        'embeddedPublicSite.attach': (payload: embeddedAttachPayload) => {
          resolveEmbeddedAttachPromise(payload)
        },
      },
    })

    await initializeEnvironment(EnvironmentApi)

    // Set up store right away:
    // eslint-disable-next-line vue/one-component-per-file
    const app = createApp({
      render: createElement => createElement(AppComponent),
    })

    // This is required by a lot of things so it needs to be initialized first
    const gsgUserPlugin = createUserPlugin()
    app.use(gsgUserPlugin, {})
    const { user } = useGsgUser(gsgUserPlugin)

    const store = createApplicationStore({
      user,
      updateUserData: data => useLoginUtils(app).updateUserData?.(data),
    } as {
      user: User
      updateUserData: NonNullable<GsgLoginUtilities['updateUserData']>
    })
    // Set locale from i18n
    store.commit('PayHub/setLocaleLight', i18n.global.locale.value)

    // Must be set for diagnostics logging to work
    store.commit('API/setRequestApi', RequestApi)
    logDiagnostics = (data: object) => store?.dispatch('PayHub/logDiagnostics', data)

    // Other things need these helpers so set them up right away
    initEWalletHelpers(store)

    installConfig({
      logDiagnostics: data => store.dispatch('PayHub/logDiagnostics', data),
    })

    // Before anything else, check if we're on a login callback URL (e.g.,
    // govhub.com/callback), and resolve that if so. This is done before
    // everything because the callbacks handler may need to redirect to the path
    // the login process started from.
    loginSucceeded = await handleOidcCallbackPage({ oidcClient: 'payhub-spa' })
    // Remember: This handler may redirect 👆🏻

    store.commit('API/setLoginApi', LoginApi)

    Vue.use(VueDOMPurifyHTML, sanitizeConfig)
    Vue.use(VueSVGIcon, { isStroke: true })
    Vue.use(VueSignaturePad)

    const { client, site } = clientAndSiteFromUrl()

    // LD flags can be used to enable a few basic announcements
    // Set this listener before the configLoading process starts
    EventBus.$on('config.flagsChanged', async flags => {
      // setAnnouncement depends on the config
      store.dispatch('PayHub/setAnnouncement', { name: flags.announcement })
    })

    await getClientsAndSites()
    await loadConfig({ client, site })
    // If EPS is ever put into a separate build this can be determined from some
    // other source which would allow us to install login right away
    const embeddedMode = configGetters?.useEmbeddedPublicSite

    await (embeddedMode ? installEmbedded : installFull)(Vue, { app, store })

    // Indicate success
    return true
  }
  catch (error) {
    console.error(error)
    sentryException(error as Error)
    // eslint-disable-next-line vue/one-component-per-file
    createApp({
      render: () => h(LoadFailure),
    })
      .use(vueErrorHandler)
      .use(i18n)
      .mount('#app')

    logBootTime()
  }
}

/**
 * installEmbedded installs govhub with a bunch of the optional modules
 * disabled. This is the 'embedded' mode.
 * @see {@link ../README.md} for more information on the embedded public site
 */
async function installEmbedded (Vue, {
  app,
  store,
}: {
  app: App
  store: ApplicationStore
}) {
  // verify that this exists within an iframe
  // This value is passed to the installShared function. Once the vue router has
  // been constructed, if this evaluates to true, the user will be redirected to
  // the load-failure page
  if (window.self === window.top) {
    throw new Error('The embedded public site can only be installed in an iframe. No iframe was detected.')
  }

  // Start handling height changes to prevent a double scrollbar in the iframe
  // containing the public site.
  embeddedParentWhisperer.startHandlingHeight()

  // Before the embedded public site can be installed, the embedded wrapping
  // script must whisper an 'attach' message that includes google analytics
  // data in its payload. This message should already have been sent by this
  // point. The installer will wait a maximum of 10 seconds before giving up
  // and mounting the load failure page.
  const attachTimeout = new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new Error('For govhub to install as an embedded public site, the installer must receive an attach event from the embedded wrapper script. The installer did not receive any events after 30 seconds.'))
    }, 30_000)
  })
  const { googleAnalytics, localTaxSysUrl, language, rootTaxSysIframeUrl } = await Promise.race([embeddedAttachPromise, attachTimeout]) as embeddedAttachPayload
  setLocalTaxSysSandboxUrl(localTaxSysUrl)
  setRootTaxSysIframeUrl(rootTaxSysIframeUrl)

  // Set the language. This will be overridden by the installShared function if
  // a user is logged in - with their last used language.
  store.dispatch('PayHub/setLocale', {
    locale: language,
    $i18n: i18n,
    updateUser: false,
  })

  // The router will emit this event after every route change. The new path must
  // then be whispered to the attach script, which will provide the new path to
  // the embedding parent site.
  EventBus.$on('routeChange', newRoute => {
    embeddedParentWhisperer.notify({
      action: 'routeChange',
      payload: newRoute.fullPath,
    })
  })

  // Provide the whisperer for components to use for propagating child
  // iframe messages to the parent iframe when needed.
  // This is used by the TaxSysIframe to bubble up the request to scroll
  // the page to a given #fragment.
  app.provide('parentWhisperer', embeddedParentWhisperer)

  // Install govhub with limited modules
  return installShared(Vue, {
    app,
    store,
    enableHeader: false,
    enableFooter: false,
    showAnnouncements: false,
    enableMyForms: false,
    enableHomePage: false,
    enableFloatingCart: false,
    ...googleAnalytics,
    useExternalAuth: true,
    enableMySettingsModification: false,
  })
}

/**
 * installFull simply passes the parameters received in the install function
 * through to the installShared function, with all the modules enabled. This is
 * the 'full' govhub mode.
 */
const installFull = (Vue, {
  app,
  store,
}: {
  app: App
  store: ApplicationStore
}) => {
  // destroy the standalone parent whisperer that is not needed
  embeddedParentWhisperer.destroy()
  // Set up linking to a TaxSys sandbox, if needed
  if (process.env?.GSG_TAX_CBS_SERVICE) {
    setRootTaxSysIframeUrl(process.env.GSG_TAX_CBS_SERVICE)
  }
  // proceed with installation
  installShared(Vue, {
    app,
    store,
  })
}

/**
 * installShared finishes the public site installation.
 * @param Vue
 * @param installParams - installation data to configure the public site installation.
 */
async function installShared (Vue, {
  app,
  store,
  enableHeader = true,
  enableFooter = true,
  showAnnouncements = true,
  enableMyForms = true,
  enableHomePage = true,
  enableFloatingCart = true,
  googleTagId = '',
  userId = '',
  useExternalAuth = false,
  enableMySettingsModification = true,
}: {
  app: App
  store: ApplicationStore
  enableHeader?: boolean
  enableFooter?: boolean
  enableMyForms?: boolean
  enableHomePage?: boolean
  enableFloatingCart?: boolean
  useExternalAuth?: boolean
  enableMySettingsModification?: boolean
  showAnnouncements?: boolean
  googleTagId?: string
  userId?: string
}) {
  // Store install flags in the payhub store
  store.dispatch('PayHub/setInstallFlags', {
    enableHeader,
    enableFooter,
    showAnnouncements,
    enableMyForms,
    enableHomePage,
    enableFloatingCart,
    enableMySettingsModification,
  })

  const { client, site } = clientAndSiteFromUrl()
  const { useEbilling, useUserVerification, useDelivery, useForms, useLogin } = configGetters
  const config = configState.config

  // Can't show the app until the router is loaded.
  const router = await initRouter(store)

  // Init google analytics (before eWallet or anywhere else)
  if (Vue.$gtag) {
    sentryException(new Error('Vue Google Analytics already initialized in PayHub'))
  }

  // Install PSC widgets

  // Only install login if it's configured
  const { user } = useGsgUser(app)
  if (configGetters.useLogin) {
    const gsgLogin = createLogin()
    const loginOptions: GsgLoginOptions = {
      user,

      ...(useExternalAuth ? {
        useExternalAuth,
        getExternalJwt: () => embeddedParentWhisperer.message({
          action: 'gsgPublicSite.getJwt',
        }),
        handleLogin: () => embeddedParentWhisperer.notify({
          action: 'gsgPublicSite.handleLogin',
        }),
        userFieldMap: {
          name: 'name',
          givenName: 'given_name',
          familyName: 'family_name',
          phone: 'phone',
        },
      } : {
        useExternalAuth,
        oidcClient: 'payhub-spa',
        getAuthQueryParams: () => {
          // Pass client data for display on the login screen
          const {
            client,
            site,
            payHub: {
              clientTitle: clientDisplay = '',
              landingPageTitle: siteDisplay = '',
              clientLogo = '',
            } = {},
          } = configState.config

          // Discover the user's language preference
          const locale = safeLocalStorage.getItem('payhubDefaultLocale')

          return {
            'client_site': `${client}/${site}`,
            'client_display': clientDisplay,
            'site_display': siteDisplay,
            'display_logo_url': clientLogo,
            ...(locale ? { 'ui_locales': locale } : {}),
          }
        },
        userFieldMap: {
          name: 'name',
          givenName: 'given_name',
          familyName: 'family_name',
          phone: {
            current: 'https://govhub.com/phone',
            deprecated: 'https://pay-hub.net/phone',
          },
          contactPreference: 'contact_preference',
          language: {
            current: 'https://govhub.com/language',
            deprecated: 'https://pay-hub.net/language',
          },
        },
      }),

    }
    app.use(gsgLogin, loginOptions)

    const {
      initAuth,
      authPromise,
      beforeLogin,
      onUserSignedOut,
      onLoginFailure,
    } = useLoginUtils(gsgLogin)

    store.commit('PayHub/setAuthPromise', authPromise)

    beforeLogin(
      () => logAnalyticsEvent('Login Attempt', { 'event_category': 'PayHub' }),
    )

    onUserSignedOut(
      () => logAnalyticsEvent('Logout', { 'event_category': 'Logout' }),
    )

    onLoginFailure(error => {
      if (error) {
        logAnalyticsEvent(error.message, { 'event_category': 'Login Failure' })
      }
    })

    initAuth()

    initLoginHelpers(gsgLogin)
  }
  else {
    stubLoginHelpers()
    store.commit('PayHub/setAuthPromise', Promise.resolve())
  }

  const callbackActionsPlugin = createCallbackActions()
  app.use(callbackActionsPlugin, {
    // Persistent id unique to the application
    applicationId: 'GovHub-afeb442e-a413-4bfe-ba0c-fd84610ca047',
  })
  const {
    registerCallbacks,
    runCallbacks,
  } = useCallbackActions(callbackActionsPlugin)

  installModule(
    installRouterSync,
    {
      store,
      router,
    },
    'Router Sync',
  )

  if (showAnnouncements) {
    Vue.use(Announcements, {
      store,
      api: AnnouncementsApi,
      bus: EventBus,
    })
  }

  app
    .use(MyPayments, {
      store,
      api: MyPaymentsApi,
      sentryException,
    })
    .use(MyItems, {
      store,
      router,
      api: MyItemsApi,
      bus: EventBus,
      sentryException,
    })

  Vue.use(Help, {
    store,
    api: ContactApi,
    bus: EventBus,
    sentryException,
  })

  app.use(SchedPay, {
    store,
    api: SchedPayApi,
    bus: EventBus,
    isAdmin: false,
  })

  registerCallbacks(createSchedPayCallbackActions({ store, user }) as CallbackMap)

  installModule(
    installIndexSearch,
    {
      store,
    },
    'Index Search',
  )

  installPayables({
    payablesConfig: {
      client: config.client,
      site: config.site,
      payableSources: config.payableSources?.payableSources || [],
      contactCountyText: config.renewexpress?.contactCountyText,
      useDelivery: Boolean(config.delivery?.meta?.enabled),
      enableRExHubPickUp: Boolean(config.delivery?.enableRExHubPickUp),
      renewalServiceFee: config.renewexpress?.renewalServiceFee,
      extendVehicleRegistrationSearch: config.renewexpress?.extendVehicleRegistrationSearch,
      useInlineInsuranceAffidavits: config.renewexpress?.useInlineInsuranceAffidavits,
      insuranceCommercialVehicleHelp: config.renewexpress?.insuranceCommercialVehicleHelp,
      insuranceMilitaryPersonnelHelp: config.renewexpress?.insuranceMilitaryPersonnelHelp,
      insuranceVIN: config.renewexpress?.insuranceVIN,
      insuranceSignature: config.renewexpress?.insuranceSignature,
      allowMilitaryRenewals: config.renewexpress?.allowMilitaryRenewals,
    },
    eventBus: EventBus,
    logDiagnostics: data => {
      store.dispatch('PayHub/logDiagnostics', data)
    },
  })

  if (useEbilling) {
    installEBillingPublic({
      user,
      client: config.client,
      site: config.site,
      logRequest: (...args) => {
        store.dispatch('PayHub/logRequest', ...args)
      },
    })
  }

  if (useUserVerification) {
    installUserVerification({
      user,
      client: config.client,
      site: config.site,
      logRequest: (...args) => store.dispatch('PayHub/logRequest', ...args),
    })
  }

  if (useDelivery) {
    installDeliveryMethod({
      user,
      client: config.client,
      site: config.site,

      enableRExHubPickUp: Boolean(config.delivery?.enableRExHubPickUp),
      usesRenewExpress: Boolean(config.renewexpress?.meta?.enabled),
      disableCustomAddress: Boolean(config.delivery?.disableCustomAddress),
      enablePermanentAddressChange:
        Boolean(config.delivery?.enableRExHubPermanentAddressChange),
      additionalRExHubPickUpFields:
        config.delivery?.additionalRExHubPickUpFields || [],
      pickupInstructions: config.delivery?.pickupInstructions,
      requireClickToShowAddress: Boolean(config.delivery?.requireClickToShowAddress),
      contactPhone: getPayableSource('rex-vehicle-registration')?.contactPhone,
    })
  }

  if (useForms) {
    installFormsPublic({
      formConfig: config.forms,
      user,
    })
  }

  if (config.renewexpress?.enableCharitableDonations) {
    installDonations({
      client: config.client,
      site: config.site,
      charitableDonations: config.renewexpress?.charitableDonations,
    })
  }

  if (configGetters.useLogin) {
    useLoginUtils(app).onUserLoaded((user: User) => {
      if (user.language) {
        store.dispatch('PayHub/setLocale', {
          locale: user.language,
          updateUser: false,
        })
      }

      if (user?.id) {
        logAnalyticsEvent(user.id, { 'event_category': 'Login Success' })
      }

      // Used for LG flag loading
      setLDMetadata(user)
    })
  }

  // "Global" GovHub callbackActions can go here.
  registerCallbacks({
    // This one is associated with MSI, but since it is concerned with navigation,
    // it seems to make more sense to live with the "main app" in
    // @grantstreet/govhub-vue.
    savePayable: async ({ payablePath } = {}) => {
      if (!user || !payablePath) {
        return
      }
      if (!user.loggedIn) {
        console.warn('Cannot complete savePayable action. User is not logged in.')
        return
      }

      // Search payable paths can throw an exception, but since this is being
      // called in the callback action, we can reasonably expect that the payable
      // should exist. If we don't receive the payable, we can't add it to MSI
      // anyway.
      const payable = await searchPayablesPath({ path: payablePath })

      if (!payable) {
        sentryException(new Error(`Couldn't find payable for "savePayable" action. Path was: ${payablePath}`))
        return
      }

      try {
        // Loading the user's saved items can fail if the request times out, or
        // if the payable paths saved are no longer valid. This will result in a
        // thrown exception, that we should catch. This will ensure that the
        // new payable is successfully saved to MSI
        await store.getters['MyItems/loadPromise']
      }
      catch (error) {
        // We expect to hit these exceptions while SBC is undergoing data
        // migration. There would be no actionable response to this error, so we
        // will silently swallow this error
      }

      await store.dispatch('MyItems/addToMyItems', { payable })

      // Note: This triggers an uncaught exception in vue-router, when it tries
      // to invoke our "beforeEach" guard. This doesn't appear to prevent
      // "replace" from doing what it's supposed to do. This occurs because the
      // beforeEach guard modifies the resulting destination url, helpfully
      // filling in the missing 'site' parameter (due to the order of route
      // matching). Since the end url doesn't match the expected end url,
      // vue-router errors.
      router.replace({ name: 'my-dashboard' }).catch((error) => {
        // @ts-expect-error This is broken but I haven't had a chance to fix
        // yet. Silencing TS so I can push for now
        if (error?.type !== NavigationFailureType.redirected) {
          // If this navigation error is not a redirection error, re-throw
          throw error
        }
      })
    },
  })

  EventBus.$on('ewallet.authProbeFailed', () => {
    router.push({ name: 'networkError' })
  })

  const redirectCartIdPromise = cartIdFromRedirectUrl(store)
  registerCallbacks(createCartCallbackActions({ store, user }) as CallbackMap)

  app
    .use(vueErrorHandler)
    .use(store)
    .use(i18n)
    .use(router)
    .use(createHead())
    .use(
      VueGtag,
      getGoogleAnalyticsConfig({ googleTagId, userId }),
      router,
    )
    .use(formKitPlugin, createFormKitConfig(i18n.global.locale))
    .use(createVueWait({ useVuex: true }))
    .use(Cart, {
      store,
      cartId: await redirectCartIdPromise,
      api: CartApi,
      bus: EventBus,
    })
    .use(eWallet, {
      store,
      bus: EventBus,
      requestApi: undefined,
      supportsLogin: useLogin,
      initializeModule: useEWalletHelpers().initializeEWallet,
    })
    .mount('#app')

  // Must be done after cart is installed because the helpers use the Cart store
  store.dispatch('eWallet/initialize')

  logBootTime()

  await runCallbacks()

  if (!loginSucceeded) {
    router.replace({
      name: 'login-error',
      params: {
        client,
        site,
      },
    })
  }

  // If we've been redirected to a pre-filled cart, jump to
  // the checkout page
  if (await redirectCartIdPromise) {
    // Per the docs on clientAndSiteFromUrl(), `site` is sometimes a bogus
    // value: for instance, for redirects on client-only sites, it's
    // `redirect`. Rather than adding special handing to the router for this
    // case, simply don't pass the bogus site.
    const params: {
      isRedirect: boolean
      client: string | undefined
      site?: string
    } = {
      isRedirect: true,
      client,
    }

    // Do pass the site on redirect if it is real though
    if (!(config.useClientOnlyUrl && config.site !== site)) {
      params.site = site
    }

    router.replace({
      name: 'checkout',
      params,
    })
  }
}

async function initializeEnvironment (EnvironmentApi) {
  const response = await (new EnvironmentApi({ exceptionLogger: sentryException })).getEnvironment()
  setEnvironment(response?.data?.environment)
}

// Parses the cartId from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted.
async function cartIdFromRedirectUrl (store) {
  // We await the auth promise incase we're in the process of sending a newly
  // signed up user to a redirect from their confirmation email
  try {
    await store.getters['PayHub/authPromise']
  }
  catch (error) {}
  const urlForRedirectCartId = window.history.state.redirectCartId || window.location.pathname
  // Pull id from /:client/:site/redirect/:id or /:client/redirect/:id
  const match = urlForRedirectCartId.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
  return match ? match[1] : undefined
}

// Parses the client and site from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted. The `site` returned
// might be undefined or not actually a site if this is a client-only-url site
// (e.g., /sacramento or /sacramento/utilities-search).
function clientAndSiteFromUrl () {
  let path: string | null = window.location.pathname

  if (path === '/') {
    // Try to get to the last-used client/site and hydrate the url
    path = safeLocalStorage.getItem('lastPayHubPath')
    if (path) {
      window.history.replaceState(null, '', path)
    }
  }

  // We don't have anything to return
  const parts = window.location.pathname.split('/')
  if (parts.length < 2) {
    return {}
  }

  // Stash the client/site and return it
  safeLocalStorage.setItem('lastPayHubPath', path)

  // Keep in sync with @grantstreet/psc-js/utils/routing.js>formatParams()
  return {
    client: (parts[1] || '').toLowerCase(),
    site: (parts[2] || '').toLowerCase(),
  }
}

function getGoogleAnalyticsConfig ({ googleTagId, userId }: GoogleAnalytics) {
  type GaTag = {
    id: string
    params?: {
      user_id: string
    }
  }
  type GaConfig = {
    config: GaTag
    includes?: GaTag[]
    disableScriptLoad: boolean
  }

  const config: GaConfig = {
    config: { id: getPayHubGaId() },
    disableScriptLoad: process.env.NODE_ENV === 'development',
  }

  // Log to secondary google analytics account if a tag is received
  if (googleTagId) {
    config.includes = [{
      id: googleTagId,
    }]

    // Log all GA events using the provided userId
    if (userId) {
      config.includes[0].params = {
        'user_id': userId,
      }
      config.config.params = {
        'user_id': userId,
      }
    }
  }

  return config
}
