<template>
  <b-modal
    ref="disabledModal"
    class="login-disabled-modal-outer"
    modal-class="login-disabled-modal"
    :title="$t('login_modal.disabled.title')"
    ok-only
    :ok-title="$t('login_modal.disabled.button')"
    centered
    data-test="login-disabled-modal"
  >
    <template #modal-header-close>
      <svgicon
        :fill="false"
        icon="x"
      />
    </template>

    <p class="pt-2">
      {{ $t('login_modal.disabled.description') }}
    </p>
  </b-modal>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/x.js'

export default {
  methods: {
    show () {
      this.$refs.disabledModal.show()
    },

    hide () {
      this.$refs.disabledModal.hide()
    },
  },
}
</script>
