/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cut': {
    width: 16,
    height: 16,
    viewBox: '1495 1219.808 22 24.192',
    data: '<g transform="translate(1495.5 1220.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M19 0L6 20M19 0c-.4 5.877-2.34 11.742-8 13M2 0c.4 5.877 2.34 11.742 8 13"/><circle pid="1" cx="3" cy="3" r="3" transform="translate(0 17)"/><path pid="2" d="M2 0l13 20"/><circle pid="3" cx="3" cy="3" r="3" transform="translate(15 17)"/></g>'
  }
})
