/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'person-circle': {
    width: 16,
    height: 16,
    viewBox: '1944 1120 24 24',
    data: '<g transform="translate(1944.5 1120.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M15 7.7a3.5 3.5 0 01-4.152 3.441A3.8 3.8 0 018 7.529V6.471a3.8 3.8 0 012.848-3.612A3.5 3.5 0 0115 6.3z"/><circle pid="1" cx="11.5" cy="11.5" r="11.5"/><path pid="2" d="M3.66 19.91A8.522 8.522 0 0111.5 15a8.522 8.522 0 017.84 4.91"/></g>'
  }
})
