import { initialize } from '@grantstreet/e-wallet-vue'
import { sentryException } from './sentry.js'

let store

/**
 * Sets up the E-Wallet helpers.
 */
export const initEWalletHelpers = applicationStore => {
  store = applicationStore
  return useEWalletHelpers()
}

/**
 * Returns helpers for initializing and rerendering E-Wallet.
 */
export const useEWalletHelpers = () => {
  if (!store) {
    throw new TypeError('E-Wallet helpers must first be set up with initEWalletHelpers.')
  }

  return helpers
}

const helpers = {

  /**
   * Performs the E-Wallet handshake and gets the DV token.
   * You should probably let the govhub installer do this and use
   * eWallet/loadPromise
   */
  async initializeEWallet () {
    store.dispatch('wait/start', 'loading e-wallet', { root: true })

    let jwt
    try {
      await store.state.Cart.cartLoadPromise
      const data = await store.dispatch('Cart/getTokens')
      jwt = data.jwt
    }
    catch (error) {
      console.error(error)
      sentryException(error)
    }
    await initialize(store, {
      jwt,
      refreshJwt: async callback => {
        const { jwt } = await store.dispatch('Cart/getTokens')
        callback(jwt)
      },
    })

    // This increments a key which forces eWallet to rerender
    store.commit('PayHub/rerenderEWallet')

    store.dispatch('wait/end', 'loading e-wallet', { root: true })
  },

  /**
   * Schedpay admin uses this to initialize e-wallet sessions for a user other
   * than admin actually entering information.
   * If you're using this you might want to pass it to eWallet/reinitialize.
   */
  async initializeEWalletForUser (userId) {
    store.dispatch('wait/start', 'loading e-wallet', { root: true })
    await store.state.Cart.cartLoadPromise
    const { jwt } = await store.dispatch('Cart/getTokensForUser', { userId })
    await initialize(store, {
      jwt,
      refreshJwt: async callback => {
        const { jwt } = await store.dispatch('Cart/getTokensForUser', { userId })
        callback(jwt)
      },
    })

    store.commit('PayHub/rerenderEWallet')

    store.dispatch('wait/end', 'loading e-wallet', { root: true })
  },

  /**
   * Updates E-Wallet with the JWT for a new session and triggers a rerender.
   * This should be called if you've created a new E-Wallet session out of band
   * of the regular jwt refreshes handled in E-Wallet's own initialize function.
   * Useful if the cart changes.
   */
  async updateAndRerenderEWallet (jwt) {
    if (!jwt) {
      sentryException('E-Wallet re-initialized without a jwt')
    }
    if (Object.keys(store.getters['eWallet/jwt']).length === 0) {
      sentryException('E-Wallet re-initialized without first being initialized')
    }

    store.dispatch('wait/start', 'loading e-wallet', { root: true })

    await store.dispatch('eWallet/updateJwt', jwt)

    store.commit('PayHub/rerenderEWallet')

    store.dispatch('wait/end', 'loading e-wallet', { root: true })
  },

}
