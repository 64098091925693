/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 16,
    height: 16,
    viewBox: '1694.5 1120 23 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1717 1131.5l-11-11-11 11"/><path pid="1" d="M1697 1129.5v14h6v-9h6v9h6v-14"/></g>'
  }
})
