/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fire': {
    width: 17.983,
    height: 24.5,
    viewBox: '0 0 17.983 24.5',
    data: '<g data-name="Layer 2"><path pid="0" data-name="Path 6821" d="M7.138.75c4.64 3.16 17 18 5 23 2-2.05 2.72-3.82 2.19-6.55a15 15 0 00-4.47-7c.23 3.43-.32 6-1.72 7.56a5.14 5.14 0 00-1.85-3.31c-1 2.15-2 4.38-1.89 5.5.06 1.64 1.51 2.53 2.78 3.8-2.34-.58-4.83-1.21-6-4-2.77-6.63 5.96-14 5.96-19z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g>'
  }
})
