/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'external-link': {
    width: 16,
    height: 16,
    viewBox: '1894 1019.793 24.207 24.207',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1915.5 1033v8.674a1.826 1.826 0 01-1.826 1.826h-17.348a1.826 1.826 0 01-1.826-1.826v-17.348a1.826 1.826 0 011.826-1.826H1905M1911.5 1020.5h6M1917.5 1026.5v-6M1906.5 1031.5l11-11"/></g>'
  }
})
