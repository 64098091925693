<template>
  <div
    id="footer-bar"
    :class="{ 'pb-2': isClosed }"
    class="d-print-none mb-4 px-0 pt-3-25 pb-2 bg-white rounded-xl"
  >
    <template v-if="!loaded">
      <LoadingBars class="py-6" />
    </template>
    <b-tabs
      v-else
      ref="tabs"
      v-model="activeTab"
      :no-nav-style="isClosed"
      data-test="footer-bar"
      nav-wrapper-class="scrollableTabs"
      @input="scrollToTop"
    >

      <b-tab
        v-if="tabEnabled('help')"
        :title="$t('help.label')"
        title-link-class="scrollableTabTitle"
      >
        <div class="mx-5">
          <div class="text-right my-3">
            <span class="cursor-pointer">
              <svgicon
                icon="x"
                width="1.1rem"
                height="1.1rem"
                @click="closeTab()"
              />
            </span>
          </div>
          <span
            v-dompurify-html="$t('help.html')"
            class="corrected-anchor-scroll"
          />
        </div>
      </b-tab>

      <b-tab
        v-if="tabEnabled('contact')"
        :title-link-class="'scrollableTabTitle'"
        :title="$t('contact.label')"
      >
        <div class="mx-5 mb-5">
          <div class="text-right my-3">
            <span class="cursor-pointer">
              <svgicon
                icon="x"
                width="1.1rem"
                height="1.1rem"
                @click="closeTab()"
              />
            </span>
          </div>
          <ContactUs :default-contact-us-info="defaultContactUsInfo" />
        </div>
      </b-tab>

      <b-tab
        v-if="tabEnabled('terms')"
        title-link-class="scrollableTabTitle"
        @click="openTOU()"
      >
        <template #title>
          <a
            :href="touUrl"
            target="_blank"
            class="d-flex align-items-center"
          >
            {{ $t('tou.label') }}
            <svgicon
              icon="goto-xsmall"
              class="ml-1"
            />
          </a>
        </template>
      </b-tab>

      <b-tab
        v-if="tabEnabled('home')"
        title-link-class="scrollableTabTitle"
        @click="openHome()"
      >
        <template #title>
          <span class="d-flex align-items-center">
            {{ homeText }}
            <svgicon
              icon="goto-xsmall"
              class="ml-1"
            />
          </span>
        </template>
      </b-tab>

      <b-tab
        v-if="tabEnabled('announcements')"
        data-test="footer-widget"
        :title-link-class="'scrollableTabTitle footer-title'"
        :title="footer.localizedTitle"
      >
        <div class="mx-5">
          <div class="text-right my-3">
            <svgicon
              data-test="footer-close-button"
              icon="x"
              width="1.1rem"
              height="1.1rem"
              @click="closeTab()"
            />
          </div>
          <FooterContent />
        </div>
      </b-tab>

      <!-- We need this empty tab because bootstrap-vue doesn't have a great way
      to show tab titles but have them all hidden. We could probably figure out
      a better way to do this. -->
      <b-tab title-link-class="d-none">
        <template #title>
          <span class="d-none">
            {{ $t('emptytab.label') }}
          </span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ContactUs from './ContactUs.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FooterContent from '@grantstreet/announcements-vue/src/components/public/FooterContent.vue'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import { mapConfigState, mapConfigGetters } from '@grantstreet/psc-config'

export default {
  mixins: [
    scrollToMixin,
  ],
  components: {
    ContactUs,
    FooterContent,
    LoadingBars,
  },

  props: {
    defaultContactUsInfo: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      activeTab: 0, // This will be set correctly on `created`
      loaded: false,
      announcementsError: false,
    }
  },

  computed: {
    enabledTabs () {
      const tabs = []
      if (this.useHelp) {
        if (this.config.help.showHelp) {
          tabs.push('help')
        }
        if (this.config.help.showContactUs) {
          tabs.push('contact')
        }
      }
      if (this.homeUrl && this.homeText) {
        tabs.push('home')
      }
      if (this.useAnnouncementsFooters && this.footer && !this.announcementsError) {
        tabs.push('announcements')
      }
      tabs.push('terms')
      return tabs
    },

    emptyTabIndex () {
      return this.enabledTabs.length
    },

    homeText () {
      // Check that the footer.home translation exists
      return this.$te('footer.home') && this.$t('footer.home')
    },

    homeUrl () {
      return this.config.payHub?.returnHomeUrl
    },

    touUrl () {
      let append = ''
      if (this.$i18n.locale === 'es') {
        append = '#avisolegal'
      }
      return 'https://www.grantstreet.com/legal' + append
    },

    isClosed () {
      return this.activeTab === this.emptyTabIndex
    },

    ...mapConfigState(['config']),

    ...mapConfigGetters([
      'useHelp',
      'useAnnouncementsFooters',
      'useAnnouncementsFooters',
      'preExpandAnnouncementsFooter',
    ]),

    ...mapGetters('Announcements', [
      'footer',
    ]),
  },

  created () {
    this.activeTab = this.emptyTabIndex
  },

  async mounted () {
    try {
      await this.$store.getters['Announcements/loadPromise']
    }
    catch (error) {
      this.announcementsError = true
    }
    if (this.useAnnouncementsFooters && this.preExpandAnnouncementsFooter && this.footer && !this.announcementsError) {
      this.activeTab = this.enabledTabs.findIndex(tab => tab === 'announcements') + 1
    }
    else {
      this.closeTab()
    }

    this.loaded = true
  },

  methods: {
    tabEnabled (tabName) {
      return this.enabledTabs.find(tab => tab === tabName)
    },

    closeTab () {
      this.activeTab = this.emptyTabIndex
    },

    scrollToTop (index) {
      if (!this.isClosed) {
        this.scrollTo('#footer-bar', { speed: 400, offset: 0 })
      }
    },

    openTOU () {
      window.open(this.touUrl)
      Vue.nextTick(() => this.closeTab())
    },

    openHome () {
      window.open(this.homeUrl)
      Vue.nextTick(() => this.closeTab())
    },
  },

}
</script>

<style lang="scss" scoped>

.pt-3-25 {
  padding-top: 1.25rem !important
}

// This corrects scrolling behavior on anchor tags within long help divs
// When browsers scroll to anchor tags, they scroll to the top most position where
// the tagged element is visible. The main content div scrolls beneath the navbar,
// and elements underneath the navbar are considered visible.
::v-deep .corrected-anchor-scroll {
  *:target {
    margin-top: -120px;
    padding-top: 120px;
  }
}

::v-deep h4 {
  margin-bottom: 0;
}

// This pads the tab bar internally so as not to mess up the border
::v-deep .scrollableTabs ul.nav {
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-breakpoint-down(sm) {
    .nav-item {
      width: 100%;
    }
  }
}

</style>
