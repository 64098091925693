/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'notebook': {
    width: 16,
    height: 16,
    viewBox: '1894.5 1220 23 24',
    data: '<g _fill="none" _stroke="#666" stroke-miterlimit="10"><path pid="0" d="M1898 1220.5h17a2 2 0 012 2v19a2 2 0 01-2 2h-17a1 1 0 01-1-1v-21a1 1 0 011-1zM1901 1220.5v23"/><path pid="1" stroke-linecap="round" d="M1895 1224.5h4M1895 1229.5h4M1895 1234.5h4M1895 1239.5h4"/></g>'
  }
})
