/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shield': {
    width: 19.5,
    height: 24.5,
    viewBox: '0 0 19.5 24.5',
    data: '<g data-name="Layer 2"><path pid="0" data-name="Path 6831" d="M9.75.75c-3 2-6 3-9 2v11c0 4 6 7 9 10 3-3 9-6 9-10v-11c-3 1-6 0-9-2z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g>'
  }
})
