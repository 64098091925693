<template>
  <div>
    <form
      ref="redirectForm"
      :action="getUrl"
      method="post"
    >
      <input
        id="id"
        type="hidden"
        name="id"
        :value="getCartId"
      >
    </form>

    <ExternalRedirectModal
      v-model="shouldShowModal"
      :modal-url="modalUrl"
    />

    <b-button
      variant="outline-primary"
      class="mb-4"
      data-test="outbound-redirect-button"
      @click="showModalandSubmitForm"
    >
      {{ $t('continue_to_payment.label') }}
    </b-button>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapConfigState } from '@grantstreet/psc-config'
import { baseGovHubUrl } from '@grantstreet/psc-environment'
import ExternalRedirectModal from '@grantstreet/psc-vue/components/ExternalRedirectModal.vue'

export default {

  data () {
    return {
      shouldShowModal: false,
    }
  },
  methods: {
    showModalandSubmitForm () {
      this.shouldShowModal = true
      this.$refs.redirectForm.submit()
    },
  },

  components: {
    ExternalRedirectModal,
  },

  computed: {

    ...mapGetters('Cart', [
      'cart',
    ]),

    ...mapConfigState([
      'config',
    ]),

    getCartId () {
      return this.cart.id
    },

    getUrl () {
      const client = this.config.client
      const site = client === 'sunshine'
        ? 'demo'
        : this.config.site
      return `${baseGovHubUrl}/svc/cart-redirect/v2/${client}/${site}/outbound/passthrough`
    },

    // This needs to be hardcoded because the redirect urls are stored in the Cart Redirect adaptors.
    modalUrl () {
      if (this.config.client === 'ca-sanfrancisco') {
        return 'pay.sfgov.org'
      }
      else {
        return baseGovHubUrl
      }
    },

  },
}

</script>
