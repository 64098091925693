import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'

import { checkRequiredOpts } from '@grantstreet/psc-vue/utils/install-utils.js'

import searchStore from './store/index.js'

sanitizeConfig.namedConfigurations = {
  confidential: {
    USE_PROFILES: {
      html: true,
      svg: true,
    },
  },
}
sanitizeConfig.default.ALLOWED_TAGS.push('img')

const moduleName = 'Index Search'

export default function install (Vue, opts) {
  checkRequiredOpts({
    opts,
    required: [
      'store',
    ],
    moduleName,
  })

  const {
    store,
  } = opts

  initBootstrapVue(Vue)

  Vue.use(VueDOMPurifyHTML, sanitizeConfig)

  if (!store.hasModule('IndexSearch')) {
    store.registerModule('IndexSearch', searchStore)
  }

  return true
}
