import routerStore from './store/index.js'

export default async function install (Vue, {
  store,
  router,
}) {
  if (!store.hasModule('router')) {
    store.registerModule('router', routerStore)
  }

  store.commit('router/setRouter', router)

  return true
}
