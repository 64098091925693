/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delivery-truck': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.408 18.092h5.837V5.566a.893.893 0 00-.898-.895H2.57a.9.9 0 00-.898.895v12.526h2.245" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M20.98 18.092h1.347v-2.63a.892.892 0 00-.342-.698l-2.352-1.146-.404-2.782a.893.893 0 00-.898-.796h-4.095v8.052h2.245" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M18.735 20.329a2.24 2.24 0 002.245-2.237 2.24 2.24 0 00-2.245-2.237 2.24 2.24 0 00-2.245 2.237 2.24 2.24 0 002.245 2.237zM6.163 20.329a2.24 2.24 0 002.245-2.237 2.24 2.24 0 00-2.245-2.237 2.24 2.24 0 00-2.245 2.237 2.24 2.24 0 002.245 2.237z" _stroke="#666" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>'
  }
})
