/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pause': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" data-name="Rectangle 1178" _fill="#fff" d="M4 4h2v6H4z"/><path pid="1" data-name="Rectangle 1179" _fill="#fff" d="M8 4h2v6H8z"/><path pid="2" data-name="Path 4341" d="M7 0a7 7 0 107 7 7 7 0 00-7-7zM6 10H4V4h2zm4 0H8V4h2z" _fill="#ab6100"/>'
  }
})
