/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cash-multiple': {
    width: 16,
    height: 16,
    viewBox: '1494 1274 24 16',
    data: '<g transform="translate(1494.5 1274.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><rect pid="0" width="20" height="12" rx="1" transform="translate(0 3)"/><path pid="1" d="M20 12h2a1 1 0 001-1V1a1 1 0 00-1-1H4a1 1 0 00-1 1v2"/><circle pid="2" cx="3" cy="3" r="3" transform="translate(7 6)"/></g>'
  }
})
