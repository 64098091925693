import CartItem from '@grantstreet/cart-vue/src/models/CartItem.ts'
import { i18n as i18nType } from '@grantstreet/psc-vue/utils/i18n.ts'
import { isoToLocalDate, formatDate } from '@grantstreet/psc-js/utils/date.js'

type FrequencyRule = {
  type: string
  date?: string
  startDate?: string
  daysBeforeDue?: string
  endDate?: string
  interval?: string
  dayOfWeek?: string
}

/**
 * This function creates any additional language that will be displayed in
 * Messaging template data when enrolling items into scheduled payments. This
 * is fairly business-specific, hence it is placed into its own special utility
 * file.
 *
 * Currently, this provides additional language for enrolling ODMV renewals into
 * scheduled payments.
 *
 * @returns A string of additional enrollment language for Messaging templates,
 *     or an empty string by default
 */
export const createAdditionalMessagingText = ({ item, frequencyRule, i18n }: {
  /** The CartItem being enrolled */
  item: CartItem

  /** The frequency rule of the item being enrolled */
  frequencyRule: FrequencyRule

  /** The psc-vue/utils/i18n object used for translations */
  i18n: typeof i18nType
}): string => {
  const displayType = item.payable?.displayType

  // ODMV renewals
  if (displayType === 'rex-vehicle-registration') {
    if (!frequencyRule.startDate) {
      return ''
    }

    const startDateObject = isoToLocalDate(frequencyRule.startDate)
    const startDate = formatDate(startDateObject, false, i18n.global.locale.value)

    return i18n.global.t('schedpay.additional_text.renewal', { startDate })
  }

  return ''
}
