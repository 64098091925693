/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'list': {
    width: 16,
    height: 16,
    viewBox: '1644 1122.5 24 19',
    data: '<g transform="translate(1644.5 1123)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M7 2h16"/><circle pid="1" cx="2" cy="2" r="2"/><path pid="2" d="M7 9h16"/><circle pid="3" cx="2" cy="2" r="2" transform="translate(0 7)"/><path pid="4" d="M7 16h16"/><circle pid="5" cx="2" cy="2" r="2" transform="translate(0 14)"/></g>'
  }
})
