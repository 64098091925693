<template>
  <b-modal
    id="renewalAutopayModal"
    size="md"
    centered
    @hidden="$emit('hidden')"
  >
    <div>
      <b-row>
        <b-col>
          <h2
            v-dompurify-html="$t('autopay_renewal_modal.title')"
            class="mb-6"
          />

        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="auto"
          sm="8"
        >
          <p
            v-dompurify-html="$t('autopay_renewal_modal.body')"
            class="mr-4"
          />
        </b-col>
        <b-col
          class="d-none d-sm-block"
          cols="0"
          sm="4"
        >
          <svgicon
            class="no-stroke"
            :fill="none"
            :original="true"
            icon="schep-calendar"
            width="8rem"
            height="8rem"
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row class="w-100 justify-content-end">
        <b-col
          class="d-flex justify-content-center"
          cols="12"
          sm="auto"
        >
          <!-- "No thanks" link -->
          <b-button
            data-test="autopay-enrollment-reject"
            class="small"
            variant="link"
            @click="decline"
          >{{ $t('autopay_renewal_modal.decline') }}</b-button>
        </b-col>
        <b-col
          class="d-flex justify-content-center"
          cols="12"
          sm="auto"
        >
          <!-- Accept Autopay Button -->
          <b-button
            v-if="user.loggedIn"
            data-test="autopay-enrollment-accept"
            variant="primary"
            @click="accept"
          >
            {{ $t('autopay_renewal_modal.agree') }}
          </b-button>
          <!-- Create Account&Accept autopay button -->
          <LoginButton
            v-else-if="useLogin"
            data-test="autopay-enrollment-login"
            class="w-100"
            size="md"
            :before-login="autoEnrollRenewals"
            @log-in="$emit('log-in', $event)"
          >
            {{ $t('autopay_renewal_modal.create_account') }}
          </LoginButton>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/schep-calendar.js'
import { useGsgUser } from '@grantstreet/user'
import LoginButton from '@grantstreet/psc-vue/components/LoginButton.vue'
import { useCallbackActions } from '@grantstreet/callback-actions'
import { mapConfigGetters } from '@grantstreet/psc-config'

export default {
  emits: ['hidden', 'accepted', 'log-in'],

  components: {
    LoginButton,
  },

  setup: () => ({
    callbackActions: useCallbackActions(),
    user: useGsgUser().user,
  }),

  computed: {
    ...mapConfigGetters(['useLogin']),
  },

  methods: {
    accept () {
      this.$emit('accepted')
      this.$bvModal.hide('renewalAutopayModal')
    },

    decline () {
      this.$bvModal.hide('renewalAutopayModal')
    },

    autoEnrollRenewals () {
      this.callbackActions.queueAction('autoEnrollRenewals')
    },
  },
}
</script>

<style scoped>
.no-stroke {
  stroke: none;
}
.small {
  font-size: 1em
}
</style>
