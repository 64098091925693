/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pie-chart': {
    width: 16,
    height: 16,
    viewBox: '1294 1320 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1304.5 1323.5a10 10 0 1010 10h-10z"/><path pid="1" d="M1307.5 1330.5h10a10 10 0 00-10-10z"/></g>'
  }
})
