import MyPaymentItem from './MyPaymentItem.js'
import { parseNumber } from '@grantstreet/psc-js/utils/numbers.js'

// Note: the MyPayment and MyPaymentItem classes are only used on the dashboard
// MyPayments tab. The Receipt vue component will fetch receipts in addition to
// those already fetched, and will handle the returned data without inflating
// instances of these classes.

export default class MyPayment {
  constructor ({
    client,
    site,
    billing,
    confirmationNumber,
    transactions,
    postedDatetime,
    immediateProcessorDetails,
    tender,
    userId,
    id,
  }) {
    // TODO PSC-522 we'll need to track more of this for the receipt
    this.id = id
    this.client = client
    this.site = site
    this.transactions = transactions
    this.baseAmount = 0
    this.feeAmount = 0
    this.itemCount = 0

    this.transactions?.forEach(
      txn => {
        this.itemCount += txn.item_count
        txn.items = txn.items.map(i => new MyPaymentItem(i))
        this.feeAmount += txn.fee = parseNumber(txn.fee)
        this.baseAmount += txn.total = parseNumber(txn.base_amount)
      },
    )
    this.confirmationNumber = confirmationNumber

    this.postedDatetime = new Date(postedDatetime)
    this.userId = userId

    this.totalAmount = this.baseAmount + this.feeAmount
  }

  /*****************************************************************************
   * PAYMENT FUNCTIONS
   *
   * These are primarily used in MyPayments and PastPaymentRow to observe information
   * About the payment and its items in a user-friendly format
   */

  get isMultiItem () {
    return this.transactions.length > 1 || this.transactions[0].items.length > 1
  }
}
