<template>
  <b-link
    v-if="icon"
    class="position-relative cursor-pointer"
    :aria-label="$t('topnav.login')"
    @click="loginWithProgress"
  >
    <svgicon
      :class="signingIn ? 'text-muted': ''"
      icon="login-icon-guest"
      width="1rem"
      height="1rem"
    />
  </b-link>

  <ProgressButton
    v-else
    data-test="login-button"
    :size="size"
    :waiting="signingIn"
    :disabled="signingIn"
    :variant="variant"
    :inline="true"
    @click="loginWithProgress"
  >
    <slot />
  </ProgressButton>
</template>

<script>
import ProgressButton from './ProgressButton.vue'
import '@grantstreet/bootstrap/icons/js/login-icon-guest.js'

export default {
  emits: ['showLoginDisabledModal', 'log-in'],
  components: {
    ProgressButton,
  },

  props: {
    beforeLogin: {
      type: Function,
      default: undefined,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    variant: {
      type: String,
      default: 'primary',
    },

  },

  data () {
    return {
      signingIn: false,
    }
  },

  methods: {
    async loginWithProgress () {
      if (this.signingIn) {
        return
      }
      this.signingIn = true

      this.$emit('log-in', {
        beforeLogin: this.beforeLogin,
        onError: () => {
          this.$emit('showLoginDisabledModal')
          this.signingIn = false
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  min-width: 13.5rem;
}
</style>
