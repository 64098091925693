/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location-marker': {
    width: 16,
    height: 16,
    viewBox: '1297 1270.188 18 23.624',
    data: '<g transform="translate(1297.5 1270.688)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M17 8.5c0 4.99-5.716 11.592-7.774 13.806a.992.992 0 01-1.455 0C5.711 20.101-.01 13.519 0 8.5a8.5 8.5 0 1117 0z"/><circle pid="1" cx="4" cy="4" r="4" transform="translate(4.5 4.634)"/></g>'
  }
})
