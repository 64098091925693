/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mail-circle': {
    width: 120,
    height: 120,
    viewBox: '0 0 120 120',
    data: '<circle pid="0" cx="60" cy="60" r="59.5" _stroke="#666"/><path pid="1" d="M86.875 39.375v35a5 5 0 01-5 5h-45a5 5 0 01-5-5v-35" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M86.875 39.375l-24.55 20.35a5 5 0 01-5.9 0l-24.55-20.35h55zM31.875 39.375h55" _stroke="#666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
