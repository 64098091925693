import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'
import { watchForExpiration } from '@grantstreet/psc-js/utils/refresh-jwt.js'

import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'

import eWalletStore from './src/store/index.js'

import { loadTranslations } from '@grantstreet/psc-vue/utils/i18n.ts'

import RequestApi from '@grantstreet/request-api'
import { sentryException } from './sentry.js'

/**
 * TODO: Should we move this into ./src to normalize with other packages?
 */

/**
 * Install installs E-Wallet as a vue module and sets up core dependencies.
 *
 * Initialize sets up jwt handling, the api client, and may fetch tenders.
 *
 * Parent applications will likely need to inject dependencies into the
 * initialization process (govhub-vue does this) so, install() requires an
 * initialization function which will be used to set eWallet/loadPromise.
 *
 * If you don't need to inject any deps (currently embedded E-Wallet doesn't)
 * then you can pass () => initialize(store, { jwt, refreshJwt }) straight
 * through as the initialization function.
 *
 * Initialization can be triggered manually with eWallet/initialize. Otherwise
 * it will be automatically triggered the first time the eWallet/loadPromise
 * getter is accessed.
 *
 * If necessary you can force reinitialization with an optional custom
 * loadPromise via eWallet/reinitialize.
 */

/**
 * Vue plugin to install E-Wallet's store and translations, etc.
 */
export default function install (Vue, {
  store,
  bus,
  requestApi,
  supportsLogin = false,
  initializeModule,
}) {
  if (requestApi || !store.getters['API/requestService']) {
    // We need to log ACH authorizations to Kibana so the requestService is now
    // required to use e-wallet. If an api is not provided, use the same one
    // that's used in payhub
    store.commit('API/setRequestApi', requestApi || new RequestApi({ exceptionLogger: sentryException }), { root: true })
  }
  if (typeof initializeModule !== 'function') {
    throw new Error('You must pass an initializeModule function.')
  }

  Vue.use(VueDOMPurifyHTML, sanitizeConfig)

  initBootstrapVue(Vue)

  // Register the E-Wallet Vuex store
  if (!store.state.eWallet) {
    store.registerModule('eWallet', eWalletStore)
  }
  store.commit('eWallet/setSupportsLogin', supportsLogin)

  loadTranslations(sentryException)

  store.commit('eWallet/setEventBus', bus)

  // This is used to inject dependencies so that the loadPromise can be
  // initialized/retrieved from anywhere.
  store.commit('eWallet/setInitializationFunction', initializeModule)

  return true
}

/**
 * Initializes E-Wallet and DataVault.
 *
 * @returns {Promise<Boolean>} true on success, false if there is an
 * unrecoverable error.
 */
export async function initialize (store, {
  jwt,
  refreshJwt,
}) {
  if (!jwt) {
    console.error('E-Wallet Error: You must pass a `jwt`')
    return false
  }

  const updateJwt = jwt => {
    store.dispatch('eWallet/updateJwt', jwt)
  }

  // Set up initial jwt
  updateJwt(jwt)

  // Set up refreshing/notification of failure.
  const checkJwt = watchForExpiration({
    refreshJwt,
    updateJwt,
    getExpiry: () => store.getters['eWallet/jwt'].exp,
    notifyOfExpiration: () => store.commit('eWallet/setJwtFailure', true),
    sentryException,
  })

  // Check now, just in case. The "await" is so that if the token is already
  // expired, we don't continue until it has had a chance to refresh.
  await checkJwt()

  // If tenders haven't been retrieved get them
  if (!store.getters['eWallet/savedTenders'].length) {
    if (store.getters['eWallet/userId']) {
      await store.dispatch('eWallet/retrieveTenders')
    }
    else {
      store.commit('eWallet/setTenders', [])
    }
  }

  return true
}
