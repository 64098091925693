/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dog-license': {
    width: 16,
    height: 16,
    viewBox: '0 0 22.317 21.5',
    data: '<path pid="0" d="M21.379 7.025l-2.537 1.664a.567.567 0 01-.624 0l-.5-.457a.445.445 0 00-.582.042l-.457.457a.4.4 0 01-.582 0l-3.036-3.286a.4.4 0 010-.582l3.789-3.785c.374-.416.749-.457.749 0v1.206a.745.745 0 00.166.582l3.7 3.66a.3.3 0 01-.086.499zM14.85 10.02l-3.079-3.282a.4.4 0 00-.582 0 1.17 1.17 0 01-1 .374H4.285a2.414 2.414 0 01-1.081-.291c-.5-.291-1.788-1.248-1.788-1.248-.208-.083-.374-.166-.541 0a.4.4 0 00.042.582l1.539 2a.453.453 0 01.083.582L1 10.852a1.45 1.45 0 00-.25.707v8.36a.79.79 0 00.79.79h.79a.79.79 0 00.79-.79v-4.035l1.664-2.953a.9.9 0 01.707-.208l6.859 1.456a.421.421 0 01.333.457v5.324a.79.79 0 00.79.79h.915a.79.79 0 00.79-.79v-9.275a1.027 1.027 0 00-.328-.665z" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>'
  }
})
