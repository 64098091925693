/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-bell': {
    width: 16,
    height: 16,
    viewBox: '1745.5 1119.998 21 24.004',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1756 1139.502h9a1 1 0 001-1v-2.13a1.031 1.031 0 00-.787-.974c-2.307-.66-2.759-3.407-3.213-8.9-.247-2.99-2-6-6-6s-5.753 3.01-6 6c-.454 5.488-.906 8.235-3.213 8.9a1.031 1.031 0 00-.787.974v2.13a1 1 0 001 1zM1756 1143.502h0a2 2 0 01-2-2v-2h4v2a2 2 0 01-2 2z"/></g>'
  }
})
