<!--
This component cannot reference configs because it is displayed when the configs
fail to load.
-->

<template>
  <section class="text-center pt-8 px-3">
    <b-img
      :src="errorImgSrc"
      fluid
    />

    <h1 class="mt-4 mb-3">{{ $t(`error.loadFailure.title`) }}</h1>

    <p>{{ $t(`error.loadFailure.desc`) }}</p>
  </section>
</template>

<script>
import errorImgSrc from '../assets/error-page-illustration.svg'

export default {
  data () {
    return {
      errorImgSrc,
    }
  },
}
</script>
