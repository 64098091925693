<template>
  <div
    class="d-flex justify-content-center align-items-center"
  >
    <svgicon
      :icon="completed ? 'progress-bar-checkmark' : 'progress-bar-outline'"
      :class="{ completed }"
      :fill="completed"
      class="thin"
      height="2rem"
      width="2rem"
    />
    <span
      v-if="!completed"
      class="position-absolute"
    >{{ number }}</span>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.completed {
  fill: $success;
  stroke: white;
}
</style>
