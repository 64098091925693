<template>
  <div
    v-if="routes.length"
    class="d-flex w-100"
  >
    <Hamburger
      v-if="!showLinks"
      v-model="hamburgerActive"
      data-test="top-nav-menu-toggle"
      class=" p-3 px-lg-5 border-0 rounded-0 flex-shrink-0"
      :label="$t('menu')"
    />

    <!-- Here, we're forcing the nav to be itself and not shrink or grow based
    on what other elements think of it. That way we can accurately calculate
    whether or not it's too wide to show all of it's links without overflowing
    or bullying other elements. If there's not enough space for the links, show
    the hamburger, otherwise show the links. When we're showing the hamburger,
    we still want the links to be rendered so we can still calculate the width
    when the browser window resizes, so we just hide it, take it out of the dom
    flow and kill pointer-events. -->
    <nav
      class="nav d-none d-lg-flex flex-nowrap flex-grow-0 flex-shrink-0 pl-3"
      :class="{
        'position-absolute': !showLinks,
        'pe-none': !showLinks,
        'invisible opacity-0': !showLinks,
      }"
    >
      <div
        v-for="{id, group} of routeGroups"
        :key="id"
        class="route-group"
      >

        <NavLink
          v-for="route in group"
          :key="route.key"
          :route="route"
          class="smart-burger-link d-inline-flex flex-nowrap flex-shrink-0 flex-grow-0 align-items-center pl-3 mr-3 mb-3"
          :should-redirect="shouldAllowRedirect"
          @redirect-click="$emit('redirect-click', $event)"
        >
          <template #icon-before>
            <svgicon
              :name="route.meta.navIcon"
              color="#666"
              height="1rem"
              width="1rem"
              class="mr-3"
            />
          </template>
          <template #icon-after="{isRedirect}">
            <svgicon
              v-if="isRedirect"
              name="goto-xsmall"
              color="#666"
              height="0.7rem"
              width="0.7rem"
              class="ml-2"
            />
          </template>
        </NavLink>

      </div>
    </nav>

  </div>
</template>

<script>
import Hamburger from '@grantstreet/psc-vue/components/Hamburger.vue'
import NavLink from './NavLink.vue'
import { v4 as uuid } from 'uuid'

const isRoute = ({ name, meta: { navIcon, title, link } = {} }) => (name || link) && navIcon && title

export default {
  emits: ['redirect-click', 'input'],
  components: {
    Hamburger,
    NavLink,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    routes: {
      type: Array,
      required: true,
      validator: routes => routes.every(
        route => Array.isArray(route) ? route.every(isRoute) : isRoute(route),
      ),
    },
    shouldAllowRedirect: {
      type: Function,
      default: () => true,
    },
  },

  data: () => ({
    elWidth: null,
    navWidth: null,
    rafHandle: null,
  }),

  computed: {
    hamburgerActive: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    showLinks () {
      /*
        Adding a buffer to the required width for links to be visible prevents
        jankiness when the browser displays/hides the scrollbar.
        This buffer just has to be wider than the scrollbar, in testing this was
        ~15 pixels.
      */
      const buffer = 30
      return this.elWidth && this.navWidth && this.navWidth + buffer <= this.elWidth
    },

    routeGroups () {
      const groups = Array.isArray(this.routes[0]) ? this.routes : [this.routes]
      return groups
        .reduce((effectiveGroups, group) => {
          if (group.length) {
            effectiveGroups.push(group)
          }
          return effectiveGroups
        }, [])
        .map(group => ({ id: uuid(), group }))
    },
  },

  watch: {
    showLinks (showLinks) {
      if (showLinks) {
        this.hamburgerActive = false
      }
    },
  },

  methods: {
    calculateWidths () {
      this.elWidth = this.$el.clientWidth
      this.navWidth = this.$el?.querySelector?.('.nav').clientWidth
      this.rafHandle = requestAnimationFrame(this.calculateWidths)
    },
  },

  mounted () {
    this.rafHandle = requestAnimationFrame(this.calculateWidths)
  },

  unmounted () {
    cancelAnimationFrame(this.rafHandle)
  },
}
</script>

<style lang="scss" scoped>

.route-group:not(:last-of-type) {
  position: relative;

  &:after {
    content: ' ';
    display: block;
    background-color: $input-disabled-border-color;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - #{map_get($spacers, 3)});
    width: 1px;
  }
}

</style>
