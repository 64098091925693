/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag': {
    width: 16,
    height: 16,
    viewBox: '1595.51 1270.33 20.856 23.464',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width=".84"><path pid="0" d="M1615.946 1279.359c-3.682 5.2-12.21.688-15.892 5.891-1.118-2.87-1.882-5.13-3-8 3.682-5.2 11.855-1.3 15.537-6.5zM1596.054 1275.25l7 18"/></g>'
  }
})
