import { v4 as uuid } from 'uuid'

export default class Address {
  name: string
  address1: string
  address2: string
  city: string
  state: string
  country: string
  postalCode: string
  nickname: string
  addressType: string
  save: boolean
  isBilling: boolean
  isSaved: boolean
  justSaved: boolean
  id: string
  lastUsed: Date
  lobDeliveryString: string

  constructor ({
    name = '',
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    country = '',
    postalCode = '',
    nickname = '',
    addressType = '',
    save = true,
    isBilling = false,
    isSaved = false,
    justSaved = false,
    id = uuid(),
    lastUsed = new Date(),
    lobDeliveryString = '',
  }) {
    this.name = name
    this.address1 = address1
    this.address2 = address2
    this.city = city
    this.state = state
    this.country = country
    this.postalCode = postalCode
    this.nickname = nickname
    this.addressType = addressType
    this.save = save
    this.isBilling = isBilling
    this.isSaved = isSaved
    this.justSaved = justSaved
    this.id = id
    this.lastUsed = lastUsed
    // See https://docs.lob.com/node#us_verifications_object for a list of what deliverability can be
    this.lobDeliveryString = lobDeliveryString
  }

  get apiParams () {
    const { save, isBilling, isSaved, justSaved, id, lobDeliveryString, ...apiParams } = this
    return apiParams
  }

  get isDeliverable () {
    return this.lobDeliveryString !== 'undeliverable'
  }

  get isInternational () {
    return this.country && this.country !== 'US'
  }

  // used to check that an already-contstructed address is valid
  // useful for sanity-checking lob-client.js returns
  get isValid () {
    if (this.name && this.address1 && this.city && this.state && this.postalCode && this.country) {
      return true
    }
    return false
  }

  get oneLineFormat () {
    let name = this.name ? this.name : ''
    let line1 = this.address1 ? this.address1 : ''
    const line2 = this.address2 ? this.address2 + ', ' : ''
    if (line1 || line2) {
      if (name) name += ', '

      line1 += line2 ? ' ' : ', '
    }

    const city = this.city ? this.city + ', ' : ''
    const state = this.state ? this.state + ' ' : ''
    const postal = this.postalCode ? this.postalCode : ''
    return name + line1 + line2 + city + state + postal
  }

  get multiLineFormat () {
    const name = this.name ? this.name + '\n' : ''
    const line1 = this.address1 ? this.address1 + '\n' : ''
    const line2 = this.address2 ? this.address2 + '\n' : ''
    const city = this.city ? this.city + ', ' : ''
    const state = this.state ? this.state + ' ' : ''
    const postal = this.postalCode ? this.postalCode : ''
    return name + line1 + line2 + city + state + postal
  }

  isLocationChanged (other) {
    if (other.city !== this.city || other.state !== this.state || other.postalCode !== this.postalCode) {
      return true
    }

    // Note that if Lob moves things between primary_line and secondary_line
    // we don't worry about that
    // This will help users avoid running into our 49 character limits on these fields
    let otherAddressLines = other.address1
    if (other.address2) {
      otherAddressLines = otherAddressLines + ' ' + other.address2
    }
    let thisAddressLines = this.address1
    if (this.address2) {
      thisAddressLines = thisAddressLines + ' ' + this.address2
    }
    return otherAddressLines !== thisAddressLines
  }
}
