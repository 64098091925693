/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-exclamation': {
    width: 16,
    height: 16,
    viewBox: '1353.5 1170 5 24',
    data: '<g transform="translate(1354 1170.5)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><circle pid="0" cx="2" cy="2" r="2" transform="translate(0 19)"/><path pid="1" d="M2 16L.141 1.124A1 1 0 011.133 0h1.734a1 1 0 01.992 1.124z"/></g>'
  }
})
