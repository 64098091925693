import jwtDecode from 'jwt-decode'
import LoginApi from '@grantstreet/login-api'
import { sentryException } from '../sentry.js'
import {
  normalizeFieldMap,
  type SharedHelperOptions,
  type SharedHelperUtilities,
} from '../plugin.ts'

type Auth0Jwt = {
  iss: string
  email: string
  phone?: string
  family_name?: string
  given_name?: string
  name?: string
  locale?: 'en' | 'es'
}

type InternalJwt = {
  sub: string
}

const loginApi = new LoginApi({ exceptionLogger: sentryException })

const exchangeTokens = async (
  externalJwt: string,
  decodedExternalJwt: Auth0Jwt,
): Promise<string> => {
  let clientId
  if (decodedExternalJwt.iss === 'https://test-grantstreet.auth0.com/') {
    clientId = 'sandbox-token-exchange'
  }
  // TODO: PSC-17884 - Remove hardcoded value and integrate with Site Settings
  // API to grab the issuer and the client ID.
  else if (decodedExternalJwt.iss === 'https://dev-atcsbcounty2.us.auth0.com/' || decodedExternalJwt.iss === 'https://authdev.sbcountyatc.gov/' || decodedExternalJwt.iss === 'https://authqa.sbcountyatc.gov/') {
    clientId = 'sbc-token-exchange'
  }
  else {
    const error = new Error(`@grantstreet/login: Authorization is not yet set up for issuer '${decodedExternalJwt.iss}'.`)
    sentryException(error)
    throw error
  }

  const internalJwt: string | undefined = (
    await loginApi.exchangeToken({
      grantType: 'urn:ietf:params:oauth:grant-type:token-exchange',
      clientId,
      audience: 'https://pay-hub.net',
      scope: 'openid email profile',
      subjectToken: externalJwt,
      subjectTokenType: 'urn:ietf:params:oauth:token-type:id-token',
      requestedTokenType: 'urn:ietf:params:oauth:token-type:access-token',
    })
  )?.data?.access_token

  if (!internalJwt) {
    throw new Error('@grantstreet/login: Unexpected exchangeToken response format.')
  }

  // Saving this token is purely for e2e tests, so we don't need to log an error
  // if it fails. We can just let the test fail.
  try {
    localStorage.setItem('loginSandboxToken', internalJwt)
  }
  catch {}

  return internalJwt
}

export type ExternalHelperExclusiveOptions = {
  getExternalJwt: () => Promise<string>
  // Returns a promise so it's convenient to await or Promise.race the reload
  handleLogin: () => Promise<void>
}

export type ExternalHelperOptions = SharedHelperOptions & ExternalHelperExclusiveOptions

// *Do not* add any properties to this local type that are shared with
// FullLoginHelpers. Those need to go in SharedHelperUtilities.
export type ExternalLoginHelpers = SharedHelperUtilities

// TODO: PSC-22424 - Update this documentation
export default ({
  getExternalJwt,
  handleLogin,
  user,
  beforeLogin,
  onUserLoaded,
  onLoginFailure,
  // onUserSignedOut is unused because we don't currently support logOut. I
  // don't love that it's part of the options type for this but that seems
  // cleaner than the alternative typing and I wouldn't be shocked if we did
  // support it in the near future.
  userFieldMap = {},
}: ExternalHelperOptions): ExternalLoginHelpers => {
  // Format the structure for consistency
  const normalizedFieldMap = normalizeFieldMap(userFieldMap)

  // Update the public user object
  const updateUserData = (data: {[key:string]: string | undefined}) => {
    for (const [appField, { current, deprecated }] of Object.entries(normalizedFieldMap)) {
      // Fallback to deprecated fields if the current field name isn't set
      let newValue
      for (const field of [current, ...deprecated]) {
        if (data[field]) {
          newValue = data[field]
          break
        }
      }
      // Unsets local field if no value passed
      user[appField] = newValue
    }
  }

  let resolveAuthPromise
  const authPromise = new Promise<void>(resolve => {
    resolveAuthPromise = resolve
  })

  return {
    /**
     * This only happens when login is set up because currently the flow
     * requires the parent page to reload when handleLogin is called. The new
     * page will set up a new plugin instance which will get the updated jwt so
     * there's no reason to expose or reuse this function (currently).
     */
    initAuth: async () => {
      const newExternalJwt = await getExternalJwt()

      // If this function is ever meant to be called more than once we could
      // cache the old externalJwt, check if the new one is different, then skip
      // refresh if that is the case.

      if (!newExternalJwt) {
        user.reset()
        resolveAuthPromise()
        return
      }
      const decodedExternalJwt = jwtDecode<Auth0Jwt>(newExternalJwt)
      if (!decodedExternalJwt.email) {
        sentryException(new Error('@grantstreet/login: Expected user JWT to include `email`.'))
      }
      let internalJwt
      try {
        internalJwt = await exchangeTokens(newExternalJwt, decodedExternalJwt)
      }
      catch (error) {
        onLoginFailure(error as Error)
        resolveAuthPromise()
        throw error
      }
      const decodedInternalJwt = jwtDecode<InternalJwt>(internalJwt)

      updateUserData(decodedExternalJwt)

      user.id = decodedInternalJwt.sub
      user.email = decodedExternalJwt.email
      user.getAccessToken = () => internalJwt

      onUserLoaded(user)

      resolveAuthPromise()
    },

    authPromise,

    logIn: () => {
      beforeLogin()
      return handleLogin()
    },

    logOut: async () => {
      throw new Error('@grantstreet/login: Externalized Login does not support logout.')
    },

    updateUserData: async (data: Parameters<typeof updateUserData>[0]) => updateUserData(data),
  }
}
