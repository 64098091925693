import { parseNumber, decimalFormat } from '@grantstreet/psc-js/utils/numbers.js'
import { camelCase } from '@grantstreet/psc-js/utils/cases.js'
import { IsPayableMessage } from './Payable.ts'

/**
 * payable.customParameters field types for RenewExpress payables
 */

type FeeVariations = {
  raw: string
  number: number
  display: string
}

/**
 * Vehicle renewal fee types
 */

type RawRenewalAnnualFees = {
  duration: number
  mailFeeAmount: string
  totalFees: string
  delivery: {
    shipping: string
    pick_up: string
  }
}

export type RawRenewalFees = {
  annual: RawRenewalAnnualFees
  biennial?: RawRenewalAnnualFees
  delinquentFeeAmount?: number
  oata_fee?: number
}

type FormattedRenewalAnnualFees = {
  duration: number
  mailFeeAmount: FeeVariations
  totalFees: FeeVariations
  delivery: {
    shipping: FeeVariations
    pickUp: FeeVariations
  }
  delinquentFee?: number
  oataFee?: FeeVariations
}

export type FormattedRenewalFees = {
  annual: FormattedRenewalAnnualFees
  biennial?: FormattedRenewalAnnualFees
}

/**
 * Plate data types
 */

// I know it's tempting to dedupe these types further but when I did it was
// worse. Much less obvious what the structure actually was and potentially a
// lot more code :/

type RawPlateAnnualFees = {
  annual_fee: string
  annual_processing_fee: string
  mail_fee_addl: string
  new_plate_replacement_fee: string
  total_addl: string
}

export type RawPlateFees = {
  annual: RawPlateAnnualFees
  biennial: RawPlateAnnualFees
}

export type RawPlateData = {
  fees: RawPlateFees
  new_plate_code: string
  new_plate_description: string
  old_plate_code: string
}

type FormattedPlateDescription = {
  newPlateCode: string
  newPlateDescription: string
  oldPlateCode: string
}
type FormattedPlateAnnualFees = {
  annualFee: FeeVariations
  annualProcessingFee: FeeVariations
  mailFeeAddl: FeeVariations
  newPlateReplacementFee: FeeVariations
  totalAddl: FeeVariations
}

export type FormattedPlateData = {
  fees: {
    annual: FormattedPlateAnnualFees
    biennial: FormattedPlateAnnualFees
  }
} & FormattedPlateDescription

export type FormattedPlateFees = FormattedPlateData['fees']

/**
 * Type narrowing utilities
 */

const isRawPlateFees = (data: RawRenewalFees | RawPlateFees): data is RawPlateFees => {
  return typeof (data as RawPlateFees).annual?.new_plate_replacement_fee === 'string'
}
const isRawRenewalFees = (data: RawRenewalFees | RawPlateFees): data is RawRenewalFees => {
  return typeof (data as RawRenewalFees).annual?.duration === 'number'
}

/**
 * Custom params
 */
export type RawRExHubCustomParameters = {
  fees?: RawRenewalFees | RawPlateFees
  plate_change?: RawPlateData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra_items?: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra_items_error_fallback: any
}

export type FormattedRExHubCustomParameters = {
  fees?: FormattedRenewalFees | FormattedPlateFees
  plateChange?: FormattedPlateData
  multiplier?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraItems?: any[]
  extraItemsErrorFallback?: IsPayableMessage | undefined
}

const formatOtherKeys = (data: {[key: string]: unknown}) => {
  const newData = {}
  for (const [key, value] of Object.entries(data)) {
    newData[camelCase(key)] = value
  }
  return newData
}

export const formatFeeVariation = raw => {
  const number = parseNumber(raw)
  return {
    raw,
    number,
    display: `${number < 0 ? '- ' : ''}$${decimalFormat(Math.abs(number))}`,
  }
}

const recognizedFeeKeys = {
  mailFeeAmount: true,
  totalFees: true,
  shipping: true,
  pickUp: true,
  annualFee: true,
  annualProcessingFee: true,
  mailFeeAddl: true,
  newPlateReplacementFee: true,
  totalAddl: true,
}

const formatFeeObject = (data, seen = new Map()) => {
  // Recursion shouldn't be possible since this is was received as stringified
  // JSON, but let's guard just in case someone misuses this.
  if (seen.has(data)) {
    return '[Circular]'
  }
  seen.set(data, true)

  const newData = {}
  for (const [key, value] of Object.entries(data)) {
    let formatted
    const recasedKey = camelCase(key)
    if (value && typeof value === 'object') {
      formatted = formatFeeObject(value, seen)
    }
    else if (recognizedFeeKeys[recasedKey] && typeof value === 'string') {
      formatted = formatFeeVariation(value)
    }
    else {
      formatted = value
    }
    newData[recasedKey] = formatted
  }
  return newData
}

const renewalPeriodsToRenewalDurations = new Map([
  ['annual', '12'],
  ['biennial', '24'],
])

export const formatAnnualFeeData = (data: RawPlateFees | RawRenewalFees) => {
  const formatted = {}
  for (const [period, duration] of renewalPeriodsToRenewalDurations.entries()) {
    const fees = data[period]
    const formattedAnnualData = fees ? formatFeeObject(fees) : fees

    formatted[period] = formattedAnnualData
    // Set new convenience aliases for duration in months
    formatted[duration] = formattedAnnualData

    // If we are processing renewal fees, add the delinquent fee to the formatted fees by duration
    if (isRawRenewalFees(data) && formatted[period] && formatted[duration]) {
      const delinquentFee = formatFeeVariation(data.delinquentFeeAmount)

      formatted[period].delinquentFee = delinquentFee
      formatted[duration].delinquentFee = delinquentFee

      // Handle presence of the OATA fee
      if (data.oata_fee) {
        const oataFee = formatFeeVariation(data.oata_fee)
        formatted[period].oataFee = oataFee
        formatted[duration].oataFee = oataFee
      }
    }

    // If we are processing plate fees, add a text multiplier that the fee
    // breakdown can use when displaying 2 year fees
    if (isRawPlateFees(data)) {
      formatted[duration].multiplier = duration === '24' ? ' x 2' : ''
    }
  }

  return formatted as FormattedPlateFees | FormattedRenewalFees
}

export const formatRExHubCustomParameters = ({ fees, plate_change: plateChange, extra_items: extraItems, extra_items_error_fallback: extraItemsErrorFallback }: RawRExHubCustomParameters): FormattedRExHubCustomParameters => {
  const formatted: FormattedRExHubCustomParameters = {}

  if (fees && (isRawRenewalFees(fees) || isRawPlateFees(fees))) {
    formatted.fees = formatAnnualFeeData(fees)
  }
  if (plateChange) {
    const { fees, ...rest } = plateChange
    formatted.plateChange = {
      fees: formatAnnualFeeData(fees) as FormattedPlateFees,
      ...formatOtherKeys(rest) as FormattedPlateDescription,
    }
  }

  formatted.extraItems = extraItems
  formatted.extraItemsErrorFallback = extraItemsErrorFallback?.warning

  return formatted
}
