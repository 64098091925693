/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'voter-registration': {
    width: 21.5,
    height: 21.5,
    viewBox: '0 0 21.5 21.5',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path pid="0" data-name="Path 7176" d="M5.75 8.75l4 4 10-11"/><path pid="1" data-name="Path 7177" d="M20.75 8.75v10.261a1.738 1.738 0 01-1.739 1.739H2.489A1.738 1.738 0 01.75 19.011V2.489A1.738 1.738 0 012.489.75h11.357"/></g>'
  }
})
