<template>
  <div>
    <h4 class="mb-2">{{ $t('contact.feedback_header') }}</h4>

    <div class="d-flex align-items-center">
      <svgicon
        icon="messaging-illustration"
        height="3.75rem"
        width="3.75rem"
        fill
        original
        class="mr-3"
      />

      <div>
        {{ $t('contact.feedback_description') }}
        <b-link
          class="font-weight-bold"
          @click="showSurvey = true"
        >
          {{ $t('contact.feedback_link') }}
        </b-link>
      </div>
    </div>

    <PayHubSurveyModal
      v-if="showSurvey"
      :survey-urls="{
        prod: {
          en: 'https://grantstreet.typeform.com/to/hWY59exK',
          es: 'https://grantstreet.typeform.com/to/KKbgkn1I',
        },
        nonProd: {
          en: 'https://grantstreet.typeform.com/to/MStDI1HS',
          es: 'https://grantstreet.typeform.com/to/SCITYVg5',
        },
      }"
      @hide="showSurvey = false"
    />
  </div>
</template>

<script>
// We can't list @grantstreet/govhub-vue as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line node/no-extraneous-import, import/no-extraneous-dependencies
import PayHubSurveyModal from '@grantstreet/govhub-vue/src/components/PayHubSurveyModal.vue'

export default {
  components: {
    PayHubSurveyModal,
  },

  data () {
    return {
      showSurvey: false,
    }
  },
}
</script>
