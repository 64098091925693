import luhn from 'fast-luhn'

const longNumber = /\d{12,}/

/**
 * Determines whether the value is (possibly) a credit card number.
 * Returns a boolean indicating whether it is.
 */
export function isCardNumber (value) {
  if (!value) {
    return false
  }

  // Strip spaces and hyphens from the string
  const strippedValue = value.replace(/\s|-/g, '')

  if (!longNumber.test(strippedValue)) {
    return false
  }

  return luhn(strippedValue)
}

/**
 * Reports whether value, stripped of whitespace, contains an unseparated number
 * long enough to be a CCN (>=12 digits), even one with a typo or missing a
 * final digit or two.
 *
 * (We don't remove hyphens or other non-word characters from this more
 * aggressive check, since we don't want to forbid deliberately formatted
 * numbers. For instance, some E-Wallet end users place an invoice or account
 * number in the billing name field formatted as "xx-xxxx-xxx-xxxx". Every
 * almost-CCN in Exasol as of Feb 2021 appears to have no separators. We can
 * revisit if this isn't strict enough.)
 *
 * Note: this will definitely match non-credit card numbers, and should only be
 * used for fields that should never contain long unseparated numbers at all.
 */
export function containsCcnLengthNumber (value) {
  if (!value) return false

  const strippedValue = value.replace(/\s/g, '')

  return longNumber.test(strippedValue)
}

// These hard coded string values are specced to be the same as
// https://support.grantstreet.com/wiki/display/PEX/DataVault+Spec#DataVaultSpec-5.2.2CreditCardBrand
export const friendlyToUglyBrands = {
  'American Express': 'amex',
  'VISA': 'visa',
  'Discover Card': 'discover',
  'MasterCard': 'mastercard',
}
