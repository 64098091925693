/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cash-single': {
    width: 16,
    height: 16,
    viewBox: '1544 1274.199 24 15.601',
    data: '<g transform="translate(1544.5 1274.6)" _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M21.727 13.465c-7.023-1.962-14.046 3.062-21.07.68A.994.994 0 010 13.2V2.289a1 1 0 011.273-.954C8.3 3.3 15.319-1.727 22.342.655A.994.994 0 0123 1.6v10.911a1 1 0 01-1.273.954z"/><circle pid="1" cx="3" cy="3" r="3" transform="translate(9 4.9)" stroke-width=".75"/></g>'
  }
})
