/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'signal': {
    width: 16,
    height: 16,
    viewBox: '1494 1320 24 24',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1494.5 1332.5h5v11h-5zM1503.5 1327h5v16.5h-5zM1512.5 1320.5h5v23h-5z"/></g>'
  }
})
