/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile-pic-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" _fill="#0077B3" d="M8 0a8 8 0 108 8 8.009 8.009 0 00-8-8M5.846 5.52A2.339 2.339 0 017.6 3.3a2.155 2.155 0 012.555 2.117v.862A2.155 2.155 0 017.6 8.394a2.339 2.339 0 01-1.754-2.222zM8 14.154a6.145 6.145 0 01-5.146-2.791 8.1 8.1 0 0110.292 0A6.145 6.145 0 018 14.154"/>'
  }
})
