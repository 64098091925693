<template>
  <b-modal
    v-model="active"
    hide-footer
    :title="''"
    size="md"
    class="text-body text-left"
    hide-header
    body-class="redirectWarning"
    no-close-on-esc
  >

    <div class="px-35 pt-3 pb-4">
      <LoadingBars
        :include-text="false"
      />
      <b-container class="text-center">
        <b-row>
          <b-col
            v-dompurify-html="$t('redirect_external.modal.text', {url: modalUrl})"
          />
        </b-row>
      </b-container>
    </div>

  </b-modal>
</template>

<script>

import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'

export default {
  emits: ['input'],
  components: {
    LoadingBars,
  },

  props: {
    value: {
      type: Boolean,
      default: null,
    },

    modalUrl: {
      type: String,
      default: null,
    },

  },

  computed: {
    active: {
      get () {
        return Boolean(this.value)
      },
      set (value) {
        this.$emit('input', value)
      },
    },

  },

}
</script>

  <style lang="scss" scoped>

  </style>
