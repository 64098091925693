/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filter': {
    width: 16,
    height: 16,
    viewBox: '1544 1220.5 24 23',
    data: '<g _fill="none" _stroke="#666" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M1567.5 1224h-14M1548.5 1224h-4M1567.5 1232h-3M1559.5 1232h-15M1567.5 1240h-17M1545.5 1240h-1M1550 1227h0a1.5 1.5 0 01-1.5-1.5v-3a1.5 1.5 0 011.5-1.5h0a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5zM1561 1235h0a1.5 1.5 0 01-1.5-1.5v-3a1.5 1.5 0 011.5-1.5h0a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5zM1547 1243h0a1.5 1.5 0 01-1.5-1.5v-3a1.5 1.5 0 011.5-1.5h0a1.5 1.5 0 011.5 1.5v3a1.5 1.5 0 01-1.5 1.5z"/></g>'
  }
})
