<template>
  <iframe
    :key="componentKey"
    :class="{ loaded, open, raised: openCartIconPopper }"
    :allow="componentKey === 'allow-microphone' ? 'microphone' : ''"
    class="chatbot"
    title="ChatBot Widget"
    data-test="chatbot-widget"
    :src="chatBotUrl"
  />
</template>

<script>
import { mapConfigGetters } from '@grantstreet/psc-config'
import { debounce } from 'debounce'

export default {
  props: {
    openCartIconPopper: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // Dimensions are set by the iframe-whisperer resizeChatBot action.
    // Default to ChatBot's dimensions, 352x652, if not given.
    chatBotDimensions: { width: 352, height: 652 },
    loaded: false,
    open: false,
    componentKey: 'disallow-microphone',
  }),

  computed: {
    ...mapConfigGetters(['chatBotUrl']),
  },

  methods: {
    onOpenChat () {
      this.open = true
      // Desktop window is bigger than ChatBot's width and 20px margin.
      // Consider changing this to be greater than Nav Bar break points- 992px?
      if (window.innerWidth >= this.chatBotDimensions.width + 20 * 2) {
        this.$el.style.height = `${this.chatBotDimensions.height}px`
        // In Firefox, height is overridden by JS after onOpenChat.
        // Set minimum height so height is set here and not by ChatBot scripts.
        const govHubNavHeight = document.getElementById('govhub-nav').clientHeight ?? 160
        if (this.chatBotDimensions.height < window.innerHeight -
          govHubNavHeight - (this.openCartIconPopper ? 100 : 0)) {
          this.$el.style.minHeight = `${this.chatBotDimensions.height}px`
        }
        // Do not set a minimum height bigger than the maximum height
        else {
          this.$el.style.minHeight =
            `calc(100vh - 10rem - 40px ${this.openCartIconPopper ? '- 100px' : ''})`
        }
        this.$el.style.width = `${this.chatBotDimensions.width}px`
      }
    },

    // ChatBot iframe button. onCloseChat fires on initial load too.
    onCloseChat () {
      this.open = false

      // Remove JavaScript set inline styles
      this.$el.style.height = null
      this.$el.style.minHeight = null
      this.$el.style.width = null
    },

    resizeChatBot (chatBotDimensions) {
      this.chatBotDimensions = chatBotDimensions
    },

    // Originally, in PSC-11069 we used an InceptionFrame with the below actions
    // This caused performance issues with another InceptionFrame- /sunshine/demo/taxsys
    // It would be preferred that we use the InceptionFrame, but for now we use
    // window's postMessage in @grantstreet/widget-bundles/public/js/livechat/chatbot.js.
    onMessage ({ data: { action, payload } }) {
      if (action === 'chatBot.loaded') {
        this.loaded = true
      }
      else if (action === 'chatBot.openChat') {
        this.onOpenChat()
      }
      else if (action === 'chatBot.resizeChatBot') {
        this.resizeChatBot(payload)
      }
      else if (action === 'chatBot.closeChat') {
        this.onCloseChat()
      }
      else if (action === 'chatBot.allowMicrophone') {
        // force rerender to allow microphone use
        // https://michaelnthiessen.com/key-changing-technique/
        this.componentKey = 'allow-microphone'
      }
    },

    onResizeWindow: debounce(function () {
      if (this.open) {
        this.onOpenChat()
      }
    }, 200),
  },

  watch: {
    openCartIconPopper (val) {
      // If ChatBot is open on a page without Cart Icon (e.g., Checkout)
      // and you navigate to a page with Cart Icon, height is too big.
      // Reset open chat dimensions so height is correct.
      if (this.open && val) {
        this.onOpenChat()
      }
    },
  },

  mounted () {
    window.addEventListener('message', this.onMessage)
    window.addEventListener('resize', this.onResizeWindow)
  },

  unmounted () {
    window.removeEventListener('message', this.onMessage)
    window.removeEventListener('resize', this.onResizeWindow)
  },
}
</script>

<style lang="scss" scoped>
.chatbot {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 0px;
  z-index: 200;
  overflow: hidden;
  min-height: 100px;
  transition: .3s;
}

.chatbot ::v-deep iframe {
  width: 100%;
  // ChatBot JS sets height. Set this to !important until we are cross-origin.
  height: 100% !important;
}

.loaded {
  height: 100px;
  width: 100px;
}

.raised {
  bottom: 100px;
}

.open {
  // Mobile dimensions. Overridden by inline styling by onOpenChat if on desktop.
  height: calc(100vh - 3.5rem - 40px);
  min-height: calc(100vh - 3.5rem - 40px);
  width: 100%;
  // Higher z-index than NavSteps
  z-index: $zindex-sticky + 1;
  // Make sure ChatBot is not too tall and fits below navigation bar.
  // Nav bar height is 10rem.
  // ChatBot has 20px vertical margin.
  max-height: calc(100vh - 10rem - 40px);
}

.open.raised {
  bottom: 120px;
  // Cart Icon is 100px
  max-height: calc(100vh - 10rem - 140px);
  // Mobile height. Overridden by inline styling by onOpenChat if on desktop.
  height: calc(100vh - 3.5rem - 140px);
  min-height: calc(100vh - 3.5rem - 140px);
}
</style>
