import { sentryFactory } from '@grantstreet/sentry'

export const {
  sentryException,
  vueErrorHandler,
  chainClient,
} = sentryFactory({
  dsns: {
    // cart-vue-prod & -nonprod projects
    prod: 'https://27ec38bbb13743fb8529d6e55e7ce9c9@o168195.ingest.sentry.io/4504600086511616',
    nonprod: 'https://da9b0ab970284e239789d43788c9274a@o168195.ingest.sentry.io/4504600110366720',
  },

  // Only send 20% of cart-vue events to Sentry, to protect our Sentry quotas.
  // At the time of implementation (2023-11-01), this should take the rate down
  // from 750/hr to 150/hr.
  clientOptions: {
    sampleRate: 0.2,
  },
})
