/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'post-it': {
    width: 16,
    height: 16,
    viewBox: '0 0 23.98 23.95',
    data: '<g _fill="none" _stroke="#163567" stroke-linecap="round" stroke-linejoin="round" data-name="Layer 2"><path pid="0" d="M9.07 14.78l-5 .53 6.61 5.33-.53-5a1 1 0 00-1.08-.86z"/><path pid="1" d="M3.89 13.33l-1-9.45a1.49 1.49 0 011.3-1.65L20.11.51a1.49 1.49 0 011.65 1.33l1.72 15.91a1.5 1.5 0 01-1.33 1.65l-9.45 1"/><path pid="2" d="M19.5 22.47V22a1.5 1.5 0 01-1.5 1.45l-16-.13A1.51 1.51 0 01.5 21.8l.14-16"/></g>'
  }
})
